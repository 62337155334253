import { useState, useRef, ChangeEvent } from "react";
import axios from "axios";
import styled from "styled-components";
import { TYPE_AIRTEL_IMG } from "./TabMenu";

const BoxContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 290px;
    height: 105px;
    align-items: center;
    justify-content: stretch;
    margin: 10px 50px 10px 0;
    // background-color: #eeeeee;
`;

const SImg = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover; // 비율 조정
    border: 1px solid #123437;
    border-radius: 5px;
    margin-bottom: 5px;
`;

const SImgInput = styled.input`
    display: none;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 100px;
    padding-left: 10px;
    align-items: start;
    justify-content: space-between;
    // background-color: #2d40ff;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 40px;
    padding: 5px 5px 5px 5px;
    align-items: start;
    justify-content: start;
    // background-color: #fff000;

    button {
        height: 30px;
        padding: 5px 5px 5px 5px;
        margin-right: 10px;
        cursor: pointer;
    }
`;

const TextBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 160px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 0px 5px 2px 5px;
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 25px;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 5px 5px 5px 5px;
        font-size: 14px;
        outline: none;
    }
`;

const ItemContainer = styled.div``;

interface props {
    type: string;
    seq: number;
    fileURL: string;
    ratio: string;
    order: number;
    uploadImg: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    removeBox: (seq: number) => void;
    ratiohandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    orderhandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
}

const DetailImgBox = (props: props) => {
    const { type, seq, fileURL, ratio, order, uploadImg, removeBox, ratiohandle, orderhandle } = props; // props로 함수 받기
    const imgUploadInput = useRef<HTMLInputElement | null>(null);

    const ratioHandle = (e: ChangeEvent<HTMLInputElement>) => {
        ratiohandle(seq, e);
    };
    const orderHandle = (e: ChangeEvent<HTMLInputElement>) => {
        orderhandle(seq, e);
    };
    const imgHandle = (e: ChangeEvent<HTMLInputElement>) => {
        uploadImg(seq, e);
    };

    return (
        <BoxContainer>
            <SImg
                src={fileURL}
                alt=""
            ></SImg>
            <SImgInput
                type="file"
                id="img"
                accept="image/*"
                required
                ref={imgUploadInput}
                onChange={imgHandle}
            />
            <RightContainer>
                {type !== TYPE_AIRTEL_IMG && (
                    <TextBox set="space-between">
                        <InputBox width="200px">
                            <input
                                type="text"
                                value={ratio}
                                placeholder="비율 소수점 4자리까지"
                                onChange={ratioHandle}
                            />
                        </InputBox>
                    </TextBox>
                )}
                <TextBox set="space-between">
                    <InputBox width="200px">
                        <input
                            type="number"
                            value={order === 0 ? "" : order}
                            placeholder="표현 순서"
                            onChange={orderHandle}
                        />
                    </InputBox>
                </TextBox>
                <BtnContainer>
                    <button
                        type="button"
                        onClick={(event) => {
                            event.preventDefault();
                            if (imgUploadInput.current) {
                                imgUploadInput.current.click();
                            }
                        }}
                    >
                        이미지 첨부
                    </button>
                    <button
                        type="button"
                        onClick={() => removeBox(seq)}
                    >
                        박스 제거
                    </button>
                </BtnContainer>
            </RightContainer>
        </BoxContainer>
    );
};

export default DetailImgBox;
