import { useAtom, useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { createBrowserHistory } from "history";
import { useEffect } from "react";

const Container = styled.div``;

export function HotelReservation() {
    const history = createBrowserHistory();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    setSelectMenu(3);
    setTitle("예약 판매 내역");
    setSubTitle("호텔 예약 내역");

    useEffect(() => {
        alert("준비중 입니다.");
        history.back();
    }, []);

    return <BaseContainer>호텔 예약 내역</BaseContainer>;
}
