import axios from "axios";
import { format } from "date-fns";
import { LinkHTMLAttributes, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { LinkProps, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { dateParse } from "../../../product/view/CounsellingListView";
import { hotdealStateParse, HpDateParse, paymentTypeParse, productSortParse, stateParse } from "../common/ReservUtil";

const Container = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: end;
    // background-color: #fff111;
    margin: 5px 0px 10px 0px;

    .excel {
        display: flex;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 500;
        border: none;
        border-radius: 5px;
        background-color: #1e7145;
        color: #ffffff;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        gap: 5px;
        margin-right: 25px;
    }
`;

interface props {
    cnt: any;
    txt: string;
    str: string;
    end: string;
}

export const HotdealExcelDownLoad = (props: props) => {
    const { cnt, txt, str, end } = props;
    const csvRef = useRef<any>();
    const navigate = useNavigate();
    const [isShow, setShow] = useState(false);
    const [csvOn, setCsvOn] = useState(false);
    const [datalist, setData] = useState<any>([]);

    const getList = async () => {
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/event/product/reservation/admin`, {
                params: {
                    state: "ALL",
                    value: txt,
                    start_day: str,
                    end_day: end,
                    size: cnt,
                    page: 1,
                },
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("reservation: ", res.data);
            if (res.data.code === 0) {
                // 리스트 저장
                csvDataParse(res.data.data.list);
            } else {
                setShow(false);
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const csvDataParse = (list: any) => {
        let arr = [
            [
                "구분",
                "구매경로",
                "상품명",
                "결제상태",
                "결제금액",
                "구매번호",
                "구매자",
                "연락처",
                "이메일",
                "구매일시",
            ],
        ];
        for (let i = 0; i < list.length; i++) {
            const ar = [
                i.toString(),
                list[i].partner_name,
                list[i].product_name,
                hotdealStateParse(list[i]),
                priceParseComma(list[i].cumulative_amount.toString()),
                list[i].reservation_id,
                list[i].user_name,
                list[i].user_phone,
                list[i].user_email,
                HpDateParse(list[i].create_date),
            ];
            arr.push(ar);
        }
        setData(arr);
        setCsvOn(true);
    };

    useEffect(() => {
        if (csvOn) {
            // console.log("!!@@ ", datalist);
            setShow(false);
            csvRef.current.link.click();
        }
    }, [csvOn]);

    return (
        <>
            <Container>
                {isShow && <LoadingSpinner />}
                <button
                    className="excel"
                    onClick={getList}
                >
                    <img
                        className="icon"
                        color="#ffffff"
                        sizes="26"
                        src="/img/excel_icon.svg"
                        alt="excel"
                    />
                    엑셀다운
                </button>
                <CSVLink
                    data={datalist}
                    ref={csvRef}
                    filename={"예약내역_" + format(new Date(), "yyyy_MM_dd") + ".csv"}
                    target="_blank"
                />
            </Container>
        </>
    );
};
