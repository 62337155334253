import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../../common/NavTopBar";
import { SelectMenu } from "../../../common/NavVar";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../../common/PagingBox";
import { createBrowserHistory } from "history";
import axios from "axios";
import { format } from "date-fns";
import { SheetSortation } from "../../../common/SheetSortation";
import { RsvListSearchBox } from "../../../common/ReservationListSearchBox";
import { SheetContainer } from "../../../common/SheetContainer";
import { dateParse } from "../../../product/view/CounsellingListView";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { paymentTypeParse, productSortParse, stateParse, statetxtParse } from "../common/ReservUtil";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { ProductExcelDownLoad } from "./ProductExceldownload";
import NoneBackSpinner from "../../../common/NoneBackSpinner";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 530px;
    align-items: center;
    // justify-content: center;
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 13px;

    .green {
        color: #20a656;
    }
    .blue {
        color: #2d40ff;
    }
    .yellow {
        color: #e7a11a;
    }
    .red {
        color: #ee4c4c;
    }
    .gray {
        color: #838c97;
    }
`;

const Listbase = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        // background-color: #fcfcfc;
        font-weight: 700;
        font-size: 20px;
    }
`;

interface props {
    getType: string;
}

export function ProductReservation(props: props) {
    const { getType } = props;
    const history = createBrowserHistory();
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isShow, setShow] = useState(false);
    const [allcnt, setAll] = useState<number>();
    const [idata, setIdata] = useState({
        txt: "",
        str: "",
        end: "",
    });
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    const moveDetail = (item: any) => {
        navigate(`/adminpage/productreserv/reservdetail`, {
            state: {
                seq: `${item.seq}`,
                reservation_id: `${item.reservation_id}`,
                product_id: `${item.product_id}`,
                product_sort: `${item.product_sort}`,
                product_name: `${item.product_name}`,
                product_price: `${item.product_price}`,
                last_payment_day: `${item.last_payment_day}`,
                consumer_name: `${item.consumer_name}`,
                consumer_phone: `${item.consumer_phone}`,
                consumer_email: `${item.consumer_email}`,
                payment_state: `${item.payment_state}`,
                counseling_state: `${item.counseling_state}`,
                cumulative_amount: `${item.cumulative_amount}`,
                add_datetime: `${item.add_datetime}`,
                partner_name: `${item.partner_name}`,
                payment_type: `${item.payment_type}`,
                with_persons: `${item.with_persons}`,
                persons_info: `${item.persons_info}`,
                client_id: `${item.client_id}`,
                bemily_id: `${item.bemily_id}`,
            },
        });
    };

    const getlist = async (str: string, end: string, txt: string) => {
        setList([]);
        setShow(true);
        setIdata({ str: str, end: end, txt: txt });
        const accesstoken = sessionStorage.getItem("accesstoken");
        try {
            const res = await axios.get(`/v1/reservations`, {
                params: {
                    sort: getType,
                    status: "",
                    value: txt,
                    start_day: str,
                    end_day: end,
                    size: 10,
                    page: nowpage,
                },
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("reservation: ", res.data);
            setShow(false);
            if (res.data.code === 0) {
                // 총 페이지 수 계산
                setAll(res.data.data.total_count);
                setaTotalPage(Math.ceil(res.data.data.total_count / 10));
                // 리스트 저장
                setList(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        getlist("2023-12-18", format(new Date(), "yyyy-MM-dd"), "");
    }, [nowpage, getType]);

    return (
        <Container>
            <RsvListSearchBox
                title="구매내역 검색"
                searching={getlist}
            />
            <ProductExcelDownLoad
                cnt={allcnt}
                txt={idata.txt}
                str={idata.str}
                end={idata.end}
            />
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"50px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"구매경로"}
                    />
                    <SheetSortation
                        width={"90px"}
                        text={"상품구분"}
                    />
                    <SheetSortation
                        width={"250px"}
                        text={"상품명"}
                    />
                    <SheetSortation
                        width={"120px"}
                        text={"판매금액"}
                    />
                    <SheetSortation
                        width={"100px"}
                        text={"구매자명"}
                    />
                    <SheetSortation
                        width={"120px"}
                        text={"구매자 연락처"}
                    />
                    <SheetSortation
                        width={"170px"}
                        text={"구매자 이메일"}
                    />
                    <SheetSortation
                        width={"130px"}
                        text={"구매번호"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"결제구분"}
                    />
                    <SheetSortation
                        width={"130px"}
                        text={"구매요청일"}
                    />
                    <SheetSortation
                        width={"90px"}
                        text={"진행상태"}
                    />
                </SheetContainer>
                {isShow ? (
                    <NoneBackSpinner />
                ) : (
                    list.map((item: any, i: number) => {
                        return (
                            <Listbase
                                key={item.seq * i}
                                onClick={() => moveDetail(item)}
                            >
                                <Content width="50px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                                <Content width="110px">{item.partner_name}</Content>
                                <Content width="90px">{productSortParse(item)}</Content>
                                <Content width="250px">{item.product_name}</Content>
                                <Content width="120px">{priceParseComma(item.product_price.toString())}원</Content>
                                <Content width="100px">{item.consumer_name}</Content>
                                <Content width="120px">{item.consumer_phone}</Content>
                                <Content width="170px">{item.consumer_email}</Content>
                                <Content width="130px">{item.reservation_id}</Content>
                                <Content width="110px">{paymentTypeParse(item)}</Content>
                                <Content width="130px">{dateParse(item.add_datetime)}</Content>
                                <Content width="90px">
                                    <text className={statetxtParse(item)}>{stateParse(item)}</text>
                                </Content>
                            </Listbase>
                        );
                    })
                )}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </Container>
    );
}
