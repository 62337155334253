import { useAtom, useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import axios from "axios";
import { createBrowserHistory } from "history";
import {
    addrinputAtom,
    callinputAtom,
    emailinputAtom,
    idinputAtom,
    inchargeinputAtom,
    nameinputAtom,
    phoneinputAtom,
} from "./RegisterInputBox";
import { levelinputAtom } from "./CustomSelectBox";
import { useNavigate } from "react-router-dom";
import { ReactElement, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";

const BtnContainer = styled.div`
    display: flex;
    width: 170px;
    height: 40px;
    margin-top: 50px;
    justify-content: space-between;
`;

const Btn = styled.button<{ type: boolean }>`
    display: flex;
    width: 80px;
    height: 40px;
    cursor: pointer;
    background-color: ${(props) => (props.type ? "#dcdeeb" : "#2d40ff")};
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: ${(props) => (props.type ? "#707288" : "#ffffff")};
    font-weight: 500;
`;

interface props {
    sort: boolean;
}

export const RegiBtnBox = (props: props): ReactElement => {
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    const history = createBrowserHistory();
    const id = useAtomValue(idinputAtom);
    const level = useAtomValue(levelinputAtom);
    const name = useAtomValue(nameinputAtom);
    const addr = useAtomValue(addrinputAtom);
    const call = useAtomValue(callinputAtom);
    const email = useAtomValue(emailinputAtom);
    const incharge = useAtomValue(inchargeinputAtom);
    const phone = useAtomValue(phoneinputAtom);

    const regiBtnHandle = async () => {
        if (id === "" || name === "" || addr === "" || call === "" || email === "" || incharge === "" || phone === "") {
            alert("입력칸을 확인해 주세요");
            return;
        }
        setShow(true);

        let lv = 0;
        if (level === "마케팅팀") {
            lv = 2;
        } else if (level == "지원팀") {
            lv = 1;
        } else if (level == "관리자") {
            lv = 3;
        }

        let accesstoken = sessionStorage.getItem("accesstoken");

        let body = {
            partner_id: id,
            partner_pw_origin: id + "123",
            partner_name: name,
            partner_address: addr,
            partner_contact: call,
            partner_email: email,
            incharge: incharge,
            incharge_contact: phone,
            partner_lv: lv,
        };

        try {
            const res = await axios.post(`/v1/partners`, body, { headers: { Authorization: `Bearer ${accesstoken}` } });
            // console.log(res.data);
            // 로딩 종료
            setShow(false);
            if (res.data.code === 0) {
                alert("등록이 완료 되었습니다.");
                history.back();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const updateBtnHandle = async () => {
        if (name === "" || addr === "" || call === "" || email === "" || incharge === "" || phone === "") {
            alert("입력칸을 확인해 주세요");
            return;
        }
        setShow(true);

        let lv = 0;
        if (level === "마케팅팀") {
            lv = 2;
        } else if (level == "지원팀") {
            lv = 1;
        } else if (level == "관리자") {
            lv = 3;
        }

        let accesstoken = sessionStorage.getItem("accesstoken");

        let body = {
            partner_id: id,
            partner_name: name,
            partner_address: addr,
            partner_contact: call,
            partner_email: email,
            incharge: incharge,
            incharge_contact: phone,
            partner_lv: lv,
        };

        console.log(body);

        try {
            const res = await axios.put(`/v1/partners`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log(res.data);
            // 로딩 종료
            setShow(false);
            if (res.data.code === 0) {
                alert("정보 수정이 완료 되었습니다.");
                history.back();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const cancelBtnHandle = () => {
        history.back();
    };

    return (
        <BtnContainer>
            {isShow && <LoadingSpinner />}
            <Btn
                type={true}
                onClick={cancelBtnHandle}
            >
                취소
            </Btn>
            <Btn
                type={false}
                onClick={props.sort ? regiBtnHandle : updateBtnHandle}
            >
                등록
            </Btn>
        </BtnContainer>
    );
};
