import styled from "styled-components";
import { ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import axios from "axios";
import LoadingSpinner from "../../../common/LoadingSpinner";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 400px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

interface props {
    open: boolean;
    close: (v: boolean) => void;
    move: (v: boolean) => void;
    sort: string;
    id: number;
}

export const ProductDeleteModal = (props: props): ReactElement => {
    const [isShow, setShow] = useState(false);
    const { open, close, move, sort, id } = props; // props로 함수 받기

    const deleteProduct = async () => {
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            product_sort: sort,
            product_id: id,
        };
        try {
            const res = await axios.delete(`/v1/products`, {
                data: body,
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("delete: ", res.data);
            setShow(false);
            if (res.data.code === 0) {
                // 로딩 종료
                // 계정 삭제 완료 처리
                alert("상품 삭제가 완료되었습니다.");
                close(false);
            } else {
                alert(res.data.message);
                close(false);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                move(false);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    계정 삭제
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>{"정말 삭제 하시겠습니까?\n삭제시 복구 할 수 없습니다."}</ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        onClick={() => close(false)}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        color="#f47878"
                        onClick={deleteProduct}
                    >
                        삭제
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
