import { ChangeEvent, ChangeEventHandler, KeyboardEvent, TextareaHTMLAttributes, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // background-color: #eeeeee;

    .rowBox {
        display: flex;
        width: 100%;
        max-width: 700px;
        align-items: center;
        justify-content: space-between;
    }
    .updateBtn {
        width: 140px;
        height: 40px;
        background-color: #20a656;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        margin-right: 80px;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            color: #ffffff;
        }
    }
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
    // background-color: #fff111;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .radio {
        width: 100%;
        max-width: 40px;
    }

    label {
        margin-right: 20px;
    }
`;

const CounselInputBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 350px;
    align-items: start;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    .inputbox {
        height: 320px;
        width: 100%;
        max-width: 500px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        background-color: #ffffff;
    }

    textarea {
        background-color: #ffffff;
        height: 310px;
        resize: none;
        border: 0;
        font-size: 15px;
        outline: none;
        line-height: 15px;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
    }
`;

export const priceParseComma = (value: string) => {
    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

interface props {
    item: any;
    openM: (n: number) => void;
}

export const CsReservReg = (props: props) => {
    const { item, openM } = props;
    const [csTxt, setCsTxt] = useState<string>(item.counseling_text || "");

    // console.log("@@?? ", item.counseling_text);
    const counsellingInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setCsTxt(e.target.value);
    };

    return (
        <Container>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>결제마감일</h3>
                </div>
                <input
                    type="text"
                    value={item.last_payment_day}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>총 결제금액</h3>
                </div>
                <input
                    type="text"
                    value={priceParseComma(item.product_price.toString())}
                    readOnly
                />
                <h3 className="won">원</h3>
            </BoxContainer>
            <div className="rowBox">
                <BoxContainer set="stretch">
                    <div className="text">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>결제 방법</h3>
                    </div>
                    <input
                        className="radio"
                        type="radio"
                        value={"1"}
                        checked={item.payment_type === "CREDIT"}
                        readOnly
                    />
                    <label>카드결제</label>
                    <input
                        className="radio"
                        type="radio"
                        value={"2"}
                        checked={item.payment_type === "TRANSFER"}
                        readOnly
                    />
                    <label>계좌이체</label>
                </BoxContainer>
                <button
                    className="updateBtn"
                    onClick={() => openM(1)}
                >
                    <p>결제정보 수정</p>
                </button>
            </div>
            <CounselInputBox set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>상담내용</h3>
                </div>
                <div className="inputbox">
                    <textarea
                        value={item.counseling_text}
                        readOnly
                    />
                </div>
            </CounselInputBox>
            <div className="rowBox">
                <BoxContainer set="stretch">
                    <div className="text">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>상담등록 일시</h3>
                    </div>
                    <input
                        type="text"
                        value={item.counseling_time}
                        readOnly
                    />
                </BoxContainer>
                <button
                    className="updateBtn"
                    onClick={() => openM(2)}
                >
                    <p>상담내용 수정</p>
                </button>
            </div>
        </Container>
    );
};
