import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./login/LoginView";
import { MainView } from "./login/MainView";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={<MainView />}
            />
            <Route
                path="/adminpage/login"
                element={<Login />}
            />
            <Route
                path="/adminpage/*"
                element={<App />}
            />
        </Routes>
    </BrowserRouter>
);
