import styled from "styled-components";
import { ChangeEvent, ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import axios from "axios";
import { BiSolidRightArrow } from "react-icons/bi";
import LoadingSpinner from "../../../common/LoadingSpinner";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 700px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    // display: block;
    // overflow: auto;
    // height: 500px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .extxt {
        font-size: 14px;
        font-weight: 500;
        color: #f47878;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    color: ${(props) => props.font};
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #000000;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .radio {
        width: 100%;
        max-width: 40px;
    }

    label {
        margin-right: 20px;
    }
`;

interface props {
    open: boolean;
    reservid: string;
    refresh: () => void;
    close: (v: boolean) => void;
}

export const PayTransferModal = (props: props): ReactElement => {
    const { open, reservid, refresh, close } = props; // props로 함수 받기
    const [isShow, setShow] = useState(false);
    const [tPrice, setTprice] = useState<string>("");
    const [tTime, setTtime] = useState<string>("2000-00-00 00:00:00.000");

    const transferTimeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setTtime(e.target.value);
    };
    const transferPriceInput = (e: ChangeEvent<HTMLInputElement>) => {
        setTprice(e.target.value);
    };

    const setCounselling = async () => {
        if (tTime === "" || tPrice === "") {
            alert("모든 데이터를 입력하셔야 합니다.");
            return;
        }
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            reservation_id: Number(reservid),
            payment_type: "transfer",
            price: Number(tPrice),
            transfer_date: tTime,
        };

        try {
            const res = await axios.patch(`/admin/account-transfer`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            // console.log("trans: ", res.data);
            if (res.data.code === 0) {
                alert("이체내역 추가에 성공했습니다.");
                refresh();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                close(false);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    이체 내역 추가
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>이체일시</h3>
                        </div>
                        <input
                            type="text"
                            value={tTime}
                            placeholder="이체받은 일시를 입력해주세요"
                            onChange={transferTimeInput}
                        />
                    </BoxContainer>
                    <h3 className="extxt">{"이체일시 ex) yyyy-MM-dd HH:mm:ss.000 형식으로 입력해주세요 "}</h3>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>이체금액</h3>
                        </div>
                        <input
                            type="text"
                            value={tPrice}
                            placeholder="이체받은 금액을 입력해주세요"
                            onChange={transferPriceInput}
                        />
                        <h3 className="won">원</h3>
                    </BoxContainer>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        font="#000000"
                        onClick={() => close(false)}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        color="#2d40ff"
                        font="#ffffff"
                        onClick={setCounselling}
                    >
                        등록
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
