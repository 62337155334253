import styled from "styled-components";
import { ChangeEvent, ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import axios from "axios";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { BiSolidRightArrow } from "react-icons/bi";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 700px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    // display: block;
    // overflow: auto;
    // height: 500px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    color: ${(props) => props.font};
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #000000;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .radio {
        width: 100%;
        max-width: 40px;
    }

    label {
        margin-right: 20px;
    }
`;

const CounselInputBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 350px;
    align-items: start;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    .inputbox {
        height: 320px;
        width: 100%;
        max-width: 500px;
        border: 2px solid #000000;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        background-color: #fcfcfc;
    }

    textarea {
        background-color: #fcfcfc;
        height: 310px;
        resize: none;
        border: 0;
        font-size: 15px;
        outline: none;
        line-height: 15px;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
    }
`;

interface props {
    open: boolean;
    id: number;
    bemily: boolean;
    data: any;
    mode: string;
    close: (v: boolean) => void;
}

export const CounselRegisterModal = (props: props): ReactElement => {
    const { open, id, bemily, data, mode, close } = props; // props로 함수 받기
    const [isShow, setShow] = useState(false);
    const [aCode, setAcode] = useState<string>("");
    const [csTxt, setCsTxt] = useState<string>("");
    const [csPrice, setCsPrice] = useState<string>("");
    const [cslpd, setCsLpd] = useState<string>("");
    const [csptype, setCsPtype] = useState(true);

    const authorizeCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setAcode(e.target.value);
    };
    const counsellingInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setCsTxt(e.target.value);
    };
    const lastpaydayInput = (e: ChangeEvent<HTMLInputElement>) => {
        setCsLpd(e.target.value);
    };
    const priceInput = (e: ChangeEvent<HTMLInputElement>) => {
        setCsPrice(e.target.value);
    };
    const paymenttypeInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "1") {
            setCsPtype(true);
        } else {
            setCsPtype(false);
        }
    };

    const checkData = () => {
        if (bemily && aCode === "") {
            alert("인증코드 값이 필요합니다.");
            return;
        }
        if (cslpd === "" || csPrice === "" || csTxt === "") {
            alert("모든 데이터를 입력하셔야 합니다.");
            return;
        }
        setShow(true);
        if (bemily) {
            getBemilyAccess();
        } else {
            setCounselling("");
        }
    };

    const getBemilyAccess = async () => {
        const body = {
            authorizeCode: aCode,
        };

        try {
            const res = await axios.post(`/openapi/auth`, body);
            // console.log("be auth: ", res.data);
            if (res.data.result) {
                setCounselling(res.data.resultData.accessToken);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            alert(e.response.data.message);
        }
    };

    const setCounselling = async (atk: string) => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            reservation_id: Number(id),
            product_price: Number(csPrice),
            payment_type: csptype ? "credit" : "transfer",
            last_payment_day: cslpd,
            counseling_text: csTxt,
            accesstoken: atk,
        };

        try {
            const res = await axios.post(`/v1/counsels`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            // console.log("counsel: ", res.data);
            if (res.data.code === 0) {
                alert("상담 등록에 성공했습니다.");
                close(true);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                close(false);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    상담 내역 등록
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    {bemily && (
                        <BoxContainer set="stretch">
                            <div className="text">
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>인증코드</h3>
                            </div>
                            <input
                                type="text"
                                value={aCode}
                                placeholder="인증코드를 입력해 주세요"
                                onChange={authorizeCodeInput}
                            />
                        </BoxContainer>
                    )}
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>결제마감일</h3>
                        </div>
                        <input
                            type="text"
                            value={cslpd}
                            placeholder="결제마감일을 입력해주세요"
                            onChange={lastpaydayInput}
                        />
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>총 결제금액</h3>
                        </div>
                        <input
                            type="text"
                            value={csPrice}
                            placeholder="총 결제금액을 입력해주세요"
                            onChange={priceInput}
                        />
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>결제 방법</h3>
                        </div>
                        <input
                            className="radio"
                            type="radio"
                            value={"1"}
                            checked={csptype}
                            onChange={paymenttypeInput}
                        />
                        <label>카드결제</label>
                        <input
                            className="radio"
                            type="radio"
                            value={"2"}
                            checked={csptype === false}
                            onChange={paymenttypeInput}
                        />
                        <label>계좌이체</label>
                    </BoxContainer>
                    <CounselInputBox set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상담 내용</h3>
                        </div>
                        <div className="inputbox">
                            <textarea
                                placeholder="상담내용을 입력해 주세요"
                                onChange={counsellingInput}
                            >
                                {csTxt}
                            </textarea>
                        </div>
                    </CounselInputBox>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        font="#000000"
                        onClick={() => close(false)}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        color="#2d40ff"
                        font="#ffffff"
                        onClick={checkData}
                    >
                        등록
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
