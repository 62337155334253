import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 45px;

    .cal {
        // width: 100%;
        border-radius: 20px;
        overflow: hidden;
    }

    .select {
        border: none;
        border-radius: 10px;
        padding: 5px 0;
        margin: 5px 5px;
        background-color: #2d40ff;
        color: #ffffff;
        cursor: pointer;
    }
`;

interface props {
    close: () => void;
    scroll: () => void;
    setValue: (v: any) => void;
    value: Date;
}

export const SearchCalendar = (props: props) => {
    const { close, scroll, setValue, value } = props;

    const onChangeData = (e: any) => {
        setValue(e);
    };

    const selectClick = () => {
        close();
    };

    return (
        <Container>
            <Calendar
                className="cal"
                locale="ko"
                // inputRef={scroll}
                onChange={(e) => onChangeData(e)}
                value={value}
                calendarType="gregory"
                maxDate={new Date()}
                minDate={new Date("2023-12-18")}
                formatDay={(locale, date) =>
                    //xx일 -> xx 으로 format 변경
                    new Date(date).toLocaleDateString("en-us", {
                        day: "2-digit",
                    })
                }
            />
            <button
                className="select"
                onClick={selectClick}
            >
                선택
            </button>
        </Container>
    );
};
