import styled from "styled-components";
import { ReactElement } from "react";
import { CgCloseR } from "react-icons/cg";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 700px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    // display: block;
    // overflow: auto;
    // height: 500px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .extxt {
        font-size: 14px;
        font-weight: 500;
        color: #f47878;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    color: ${(props) => props.font};
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

interface props {
    open: boolean;
    title: string;
    content: string;
    agree: () => void;
    close: (v: boolean) => void;
}

export const BasicModal = (props: props): ReactElement => {
    const { open, title, content, agree, close } = props; // props로 함수 받기

    return (
        <Container>
            <ModalContainer>
                <ModalHeader>
                    {title}
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>{content}</ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        font="#000000"
                        onClick={() => close(false)}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        color="#2d40ff"
                        font="#ffffff"
                        onClick={agree}
                    >
                        동의
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
