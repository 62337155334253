import axios from "axios";
import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../common/PagingBox";
import { SheetContainer } from "../../common/SheetContainer";
import { SheetSortation } from "../../common/SheetSortation";
import { RsvListSearchBox } from "../../common/ReservationListSearchBox";
import { createBrowserHistory } from "history";
import LoadingSpinner from "../../common/LoadingSpinner";
import NoneBackSpinner from "../../common/NoneBackSpinner";

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 530px;
    align-items: center;
    // justify-content: center;
    margin-top: 40px;
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    // font-weight: 400;
    // color: black;

    .green {
        color: #20a656;
        font-size: 14px;
        // font-weight: 400;
    }

    .blue {
        color: #2d40ff;
        font-size: 14px;
        // font-weight: 400;
    }
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        // background-color: #fcfcfc;
        font-weight: 700;
    }
`;

const Blank = styled.div`
    height: 40px;
`;

export const dateParse = (date: string) => {
    return format(new Date(date), "yyyy년 MM월 dd일");
};

export function CounsellingList() {
    const history = createBrowserHistory();
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isShow, setShow] = useState(false);
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));
    const msg = { ...location.state };
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상담내역");
    setThirdTitle("");

    // useEffect(() => {
    //     if (partnerlv < 1) {
    //         alert("접근 권한이 없습니다.");
    //         history.back();
    //     }
    // });

    const moveDetail = (item: any) => {
        navigate(`/adminpage/counsellinglist/counsellingdetail`, {
            state: {
                seq: `${item.seq}`,
                reservation_id: `${item.reservation_id}`,
                product_id: `${item.product_id}`,
                product_sort: `${item.product_sort}`,
                product_name: `${item.product_name}`,
                product_price: `${item.product_price}`,
                last_payment_day: `${item.last_payment_day}`,
                consumer_name: `${item.consumer_name}`,
                consumer_phone: `${item.consumer_phone}`,
                consumer_email: `${item.consumer_email}`,
                payment_state: `${item.payment_state}`,
                counseling_state: `${item.counseling_state}`,
                cumulative_amount: `${item.cumulative_amount}`,
                add_datetime: `${item.add_datetime}`,
                partner_name: `${item.partner_name}`,
                payment_type: `${item.payment_type}`,
                with_persons: `${item.with_persons}`,
                persons_info: `${item.persons_info}`,
                client_id: `${item.client_id}`,
                bemily_id: `${item.bemily_id}`,
            },
        });
    };

    const getlist = async (str: string, end: string, txt: string) => {
        setList([]);
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/v1/reservations`, {
                params: {
                    sort: "",
                    status: "",
                    value: txt,
                    start_day: str,
                    end_day: end,
                    size: 10,
                    page: nowpage,
                },
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("reservation: ", res.data);
            setShow(false);
            if (res.data.code === 0) {
                // 총 페이지 수 계산
                setaTotalPage(Math.ceil(res.data.data.total_count / 10));
                // 리스트 저장
                setList(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        getlist("2023-12-18", format(new Date(), "yyyy-MM-dd"), "");
    }, [nowpage]);

    // useEffect(() => {
    //     if (msg == "refresh") {
    //         getlist();
    //     }
    // }, []);
    return (
        <BaseContainer>
            <Blank />
            <RsvListSearchBox
                title="상담내역 검색"
                searching={getlist}
            />
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"70px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"200px"}
                        text={"구매경로"}
                    />
                    <SheetSortation
                        width={"280px"}
                        text={"상품명"}
                    />
                    <SheetSortation
                        width={"120px"}
                        text={"구매자명"}
                    />
                    <SheetSortation
                        width={"200px"}
                        text={"구매자 연락처"}
                    />
                    <SheetSortation
                        width={"250px"}
                        text={"구매자 이메일"}
                    />
                    <SheetSortation
                        width={"130px"}
                        text={"구매번호"}
                    />
                    <SheetSortation
                        width={"140px"}
                        text={"상담요청일"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"진행상태"}
                    />
                </SheetContainer>
                {isShow ? (
                    <NoneBackSpinner />
                ) : (
                    list.map((item: any, i: number) => {
                        const state = item.counseling_state === 0;
                        return (
                            <Container
                                key={item.seq * i}
                                onClick={() => moveDetail(item)}
                            >
                                <Content width="70px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                                <Content width="200px">{item.partner_name}</Content>
                                <Content width="280px">{item.product_name}</Content>
                                <Content width="120px">{item.consumer_name}</Content>
                                <Content width="200px">{item.consumer_phone}</Content>
                                <Content width="250px">{item.consumer_email}</Content>
                                <Content width="130px">{item.reservation_id}</Content>
                                <Content width="140px">{dateParse(item.add_datetime)}</Content>
                                <Content width="110px">
                                    <text className={state ? "blue" : "green"}>{state ? "문의요청" : "상담완료"}</text>
                                </Content>
                            </Container>
                        );
                    })
                )}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </BaseContainer>
    );
}
