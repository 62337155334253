import { BiSolidRightArrow } from "react-icons/bi";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: 50px 0px;

    .stick {
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
    }

    .name {
        display: flex;
        width: 100%;
        max-width: 110px;
        align-items: center;
        margin-right: 10px;
        padding: 5px 0px;
        font-size: 15px;
        font-weight: 500;
        // background-color: #eeeeee;

        .icon {
            margin-right: 10px;
        }
    }

    .imgBox {
        margin-left: 115px;

        .box {
            display: flex;

            .val {
                margin-left: 10px;
                margin-top: 10px;
                gap: 5px;

                .content {
                    width: 100%;
                    max-width: 100px;
                    height: 35px;
                    outline: none;
                    padding: 0px 10px;
                    font-weight: 500;
                    border-radius: 7px;
                    border: 2px solid #eeeeee;
                    font-size: 15px;
                }
            }
        }

        .img350 {
            width: 100%;
            max-width: 360px;
            margin: 2px 0px;
            padding: 3px 5px;
            border: 1px solid #eeeeee;
        }
    }
`;

interface props {
    list: any;
}

export const HdDetailImage = (props: props) => {
    const { list } = props;

    return (
        <Container>
            <div className="stick" />
            <p className="name">
                <BiSolidRightArrow
                    className="icon"
                    color="#6f7cff"
                    size={10}
                />
                상세 이미지
            </p>
            <div className="imgBox">
                {list.map((img: any, i: number) => {
                    return (
                        <div
                            className="box"
                            key={i + img.n}
                        >
                            <img
                                className="img350"
                                src={img.img}
                            />
                            <div className="val">
                                <input
                                    className="content"
                                    value={img.n}
                                    readOnly
                                />
                                <input
                                    className="content"
                                    value={img.h}
                                    readOnly
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </Container>
    );
};
