import { useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { FiUserPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { accountSearchAtom, AccountSearchBox } from "../components/AccountSearchBox";
import axios from "axios";
import { useEffect, useState } from "react";
import { AccountTypeAtom } from "../components/AccountListSelectBox";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../common/PagingBox";
import { createBrowserHistory } from "history";
import { AccountDetailModal } from "../components/AccountDetailModal";
import { AccountDeleteModal } from "../components/AccountDeleteModal";
import { SheetSortation } from "../../common/SheetSortation";
import { SheetContainer } from "../../common/SheetContainer";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 450px;
    align-items: center;
    justify-content: start;
`;

const SearchContainer = styled.div`
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    margin: 20px 0 20px 0;
`;

const BtnContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 40px;
    align-items: center;
    justify-content: end;
    margin: 50px 0px 20px 0px;
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: black;
    }
`;

const DetailAccount = styled.div`
    display: flex;
    width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #708fe2;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const DeleteAccount = styled.button`
    display: flex;
    width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #f47878;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const AddAccountBtn = styled.button`
    display: flex;
    width: 100px;
    height: 40px;
    cursor: pointer;
    background-color: #23c162;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;

    h3 {
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
        font-weight: 500;
    }
`;

export const levelparse = (level: number) => {
    let name = "B2B 파트너사";
    switch (level) {
        case 1:
            name = "지원팀";
            break;
        case 2:
            name = "마케팅팀";
            break;
        case 3:
            name = "관리자";
            break;
    }
    return name;
};

let page = 1;
export function Account() {
    const history = createBrowserHistory();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const accounttype = useAtomValue(AccountTypeAtom);
    const accounttext = useAtomValue(accountSearchAtom);
    const [listdata, setList] = useState([]);
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [isDetail, setDetail] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [detailItem, setItem] = useState();
    const [deleteItem, setDeleteItem] = useState(0);
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));
    setSelectMenu(4);
    setTitle("계정관리");
    setSubTitle("");
    setThirdTitle("");

    function openDelete(id: number) {
        setDeleteItem(id);
        setDelete(true);
    }

    function closeDelete() {
        setDelete(false);
    }

    function researchDelete() {
        setDelete(false);
        accountSearching();
    }

    function moveDelete() {
        setDelete(false);
        navigate(`/adminpage/login`);
    }

    function closeDetail() {
        setDetail(false);
    }

    const accountDetail = async (clientid: number) => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/admin/${clientid}`, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("modal: ", res.data);
            if (res.data.code === 0) {
                setItem(res.data.data);
                setDetail(true);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const accountSearching = async () => {
        const accesstoken = sessionStorage.getItem("accesstoken");
        let type = "ALL";
        let value = "";
        if (accounttype === "회사명") {
            type = "NAME";
            value = accounttext;
            page = 1;
        } else if (accounttype === "EMAIL") {
            type = "EMAIL";
            value = accounttext;
            page = 1;
        } else {
            page = nowpage;
        }

        // 로딩 시작

        try {
            const res = await axios.get(`/v1/partners?page=${page}&size=10&type=${type}&value=${value}`, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("account list: ", res.data);
            if (res.data.code === 0) {
                // 로딩 종료
                // 총 페이지 수 계산
                setaTotalPage(Math.ceil(res.data.data.total_count / 10));
                // 리스트 저장
                setList(res.data.data.list);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        // console.log(partnerlv);
        if (partnerlv < 1) {
            alert("해당 페이지를 사용 할 수 없는 계정입니다.");
            history.back();
        }
        accountSearching();
    }, [nowpage]);

    return (
        <BaseContainer>
            {isDetail && (
                <AccountDetailModal
                    open={isDetail}
                    close={closeDetail}
                    detail={detailItem}
                />
            )}
            {isDelete && (
                <AccountDeleteModal
                    open={isDelete}
                    close={closeDelete}
                    research={researchDelete}
                    move={moveDelete}
                    client_id={deleteItem}
                />
            )}
            <SearchContainer>
                <AccountSearchBox onClick={accountSearching} />
            </SearchContainer>
            <BtnContainer>
                {partnerlv === 3 && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/account/register`}
                    >
                        <AddAccountBtn>
                            <FiUserPlus
                                color="#ffffff"
                                size={20}
                            />
                            <h3>계정 추가</h3>
                        </AddAccountBtn>
                    </Link>
                )}
            </BtnContainer>
            <SheetContainer>
                <SheetSortation
                    width={"90px"}
                    text={"구분"}
                />
                <SheetSortation
                    width={"180px"}
                    text={"고유번호"}
                />
                <SheetSortation
                    width={"200px"}
                    text={"계정구분"}
                />
                <SheetSortation
                    width={"230px"}
                    text={"계정ID"}
                />
                <SheetSortation
                    width={"250px"}
                    text={"회사명"}
                />
                <SheetSortation
                    width={"110px"}
                    text={"상세"}
                />
                {partnerlv > 2 && (
                    <SheetSortation
                        width={"110px"}
                        text={"계정"}
                    />
                )}
            </SheetContainer>
            <Container>
                {listdata.map((item: any, i: number) => {
                    return (
                        <SheetContainer key={item.partner_seq}>
                            <Content width="90px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                            <Content width="180px">{item.client_id}</Content>
                            <Content width="200px">{levelparse(item.partner_lv)}</Content>
                            <Content width="230px">{item.partner_id}</Content>
                            <Content width="250px">{item.partner_name}</Content>
                            <Content width="110px">
                                <DetailAccount onClick={() => accountDetail(item.client_id)}>
                                    <h3>상세정보</h3>
                                </DetailAccount>
                            </Content>
                            {partnerlv > 2 && (
                                <Content width="110px">
                                    <DeleteAccount onClick={() => openDelete(item.client_id)}>
                                        <h3>계정삭제</h3>
                                    </DeleteAccount>
                                </Content>
                            )}
                        </SheetContainer>
                    );
                })}
            </Container>
            {listdata.length > 0 && <PagingBox />}
        </BaseContainer>
    );
}
