import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import { styled } from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { updateBox } from "../common/ProductDetail_Image";
import UpdateDetailImgBox from "../common/UpdateDetailImg";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../common/updateimgInterface";

const Container = styled.div`
    position: fixed;
    // background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 200px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-top: 200px;
    width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 550px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ color: string; font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => props.font};
`;

interface props {
    open: number;
    banner_seq: number;
    main_img: string;
    list: Array<any>;
    close: () => void;
    update: () => void;
    backlogin: () => void;
}

export const AirtelAllPhoto = (props: props) => {
    const { open, banner_seq, main_img, list, close, update, backlogin } = props;
    const [isShow, setShow] = useState(false);
    const [file, setFile] = useState<FileList | null>();
    const [detail, setDetail] = useState<Array<updateBox>>(list);
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const [s3flag, setS3flag] = useState(false);

    const outHandle = (v: boolean) => {
        let arr: any[] = [];
        detail!.forEach((e) => {
            arr.push(e.image_url);
        });
        removeBloburl(arr);
        if (v) {
            backlogin();
        } else {
            close();
        }
    };

    const onImageChange = (seq: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (event.target.files!.length > 1) {
                alert("이미지는 한장만 선택 할 수 있습니다.");
                return;
            }

            if (detail[seq].image_url !== "") {
                setFile(null);
            }

            // console.log(event.target.files);
            // setFile(event.target.files);
            const arr = detail.splice(seq, 1);
            URL.revokeObjectURL(arr[0].image_url);
            const boxitem = {
                seq: arr[0].seq,
                image_url: URL.createObjectURL(event.target.files![0]),
                file: event.target.files![0],
                ratio: arr[0].ratio,
                order_by: arr[0].order_by,
            };
            detail.splice(seq, 0, boxitem);
            setDetail([...detail]);
        }
    };
    const ratioInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: e.target.value,
            order_by: arr[0].order_by,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };
    const orderbyInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: arr[0].ratio,
            order_by: e.target.valueAsNumber,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };

    useEffect(() => {
        if (s3flag) {
            setImageList();
        }
    }, [s3flag]);

    const setImgS3 = async () => {
        setShow(true);
        setS3flag(false);

        getS3imgContent(detail.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < detail.length; i++) {
                if (detail[i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, detail[i].file);
                    URL.revokeObjectURL(detail[i].image_url);
                    const boxitem = {
                        seq: detail[i].seq,
                        image_url: list[i].result_url,
                        ratio: detail[i].ratio,
                        order_by: detail[i].order_by,
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(true);
        });
    };

    const setImageList = async () => {
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            airtel_banner_seq: banner_seq,
            image_list: imgArr,
        };

        try {
            const res = await axios.put(`/v1/airtels/images`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            if (res.data.code === 0) {
                alert("이미지 리스트 수정이 완료되었습니다.");
                update();
                outHandle(false);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                outHandle(true);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    {open === 1 ? "이미지 리스트" : "이미지 리스트 수정"}
                    <ModalClosebutton onClick={() => outHandle(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    {open === 1 && (
                        <div className="item">
                            <img
                                src={main_img}
                                width={"700px"}
                                alt={""}
                            />
                        </div>
                    )}
                    {open === 2
                        ? detail.map((item: any, i: number) => (
                              <UpdateDetailImgBox
                                  key={item.seq + i}
                                  fileURL={item.image_url}
                                  ratio={""}
                                  order={item.order_by}
                                  seq={i}
                                  flag={true}
                                  uploadImg={onImageChange}
                                  ratiohandle={ratioInput}
                                  orderhandle={orderbyInput}
                              />
                          ))
                        : detail.map((item: any, i: number) => (
                              <div
                                  className="item"
                                  key={item.seq + i}
                              >
                                  <img
                                      src={item.image_url}
                                      width={"700px"}
                                      alt={""}
                                      onClick={() => {}}
                                  />
                              </div>
                          ))}
                </ModalContent>
                {open === 2 && (
                    <Footer>
                        <BtnContainer
                            font="#000000"
                            color="#eeeeee"
                            onClick={() => outHandle(false)}
                        >
                            취소
                        </BtnContainer>
                        <BtnContainer
                            font="#ffffff"
                            color="#2d40ff"
                            onClick={setImgS3}
                        >
                            수정
                        </BtnContainer>
                    </Footer>
                )}
            </ModalContainer>
        </Container>
    );
};
