import { useEffect, useState } from "react";
import styled from "styled-components";
import { AirtelList } from "../airtel/AirtelList";
import { PlanList } from "../plan/PlanList";
import { createBrowserHistory } from "history";
import { EventList } from "../EventList";
import { useSetAtom } from "jotai";
import { atotalpageAtom, nowpageAtom } from "../../../common/PagingBox";
import { HotDealList } from "../hotdeal/HotdealList";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TabBox = styled.div`
    display: flex;
    flex-direction: row;
    // align-items: start;
    justify-content: center;
    // background-color: #2d40ff;
    font-weight: bold;
    list-style: none;
    color: #bdbecc;
    margin-top: 20px;

    .submenu {
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        width: 100%;
        max-width: 140px;
        height: 20px;
        padding: 12px 10px 10px 10px;
        font-size: 15px;
        cursor: pointer;
        // transition: 0.1s;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 2px solid #2d40ff;
    }

    .none {
        display: flex;
        background-color: #ffffff;
        width: 100%;
        max-width: 460px;
        height: 20px;
        padding: 12px 10px 10px 10px;
        border-bottom: 2px solid #2d40ff;
    }

    .focused {
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        width: 100%;
        max-width: 140px;
        height: 20px;
        padding: 10px 10px 12px 10px;
        font-size: 15px;
        // transition: 0.1s;
        color: #2d40ff;
        border-top: 2px solid #2d40ff;
        border-left: 2px solid #2d40ff;
        border-right: 2px solid #2d40ff;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 20px;
    }

    & div.desc {
        text-align: center;
    }
`;

const Desc = styled.div`
    height: 70vh;
`;

export const priceparse = (price: number) => {
    return price / 10000 + "만원 ~";
};

export const TYPE_PRODUCT_DETAIL = "product_detail";
export const TYPE_AIRTEL_IMG = "airtel_img";

interface props {
    toPage: string;
}

export const TabMenu = (props: props) => {
    const { toPage } = props;
    const [currentTab, clickTab] = useState(0);
    const setNowPage = useSetAtom(nowpageAtom);
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const history = createBrowserHistory();
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    const menuArr = [
        { name: "핫딜 상품", view: 0 },
        { name: "기획 상품", view: 2 },
        { name: "에어텔 상품", view: 3 },
        { name: "이벤트 상품", view: 1 },
        { name: "", view: 99 },
        { name: "", view: 99 },
    ];

    const selectHandler = (index: number) => {
        if (index === 3) {
            alert("준비중 입니다.");
        } else {
            setaTotalPage(0);
            setNowPage(1);
            if (index < 4) {
                clickTab(index);
            }
        }
    };

    const pageSetting = () => {
        let page = 0;
        switch (toPage) {
            case "hotdeal":
                page = 0;
                break;
            case "plan":
                page = 1;
                break;
            case "airtel":
                page = 2;
                break;
        }
        clickTab(page);
    };

    useEffect(() => {
        pageSetting();
    }, []);

    useEffect(() => {
        if (partnerlv < 1) {
            alert("해당 페이지를 사용 할 수 없는 계정입니다.");
            history.back();
        }
    }, [currentTab]);

    return (
        <Container>
            <TabBox>
                {menuArr.map((e, index) => (
                    <li
                        key={index}
                        className={index === currentTab ? "focused" : index < 4 ? "submenu" : "none"}
                        onClick={() => selectHandler(index)}
                    >
                        {e.name}
                    </li>
                ))}
            </TabBox>
            <Desc>
                {menuArr[currentTab].view === 0 ? (
                    <HotDealList />
                ) : menuArr[currentTab].view === 1 ? (
                    <EventList />
                ) : menuArr[currentTab].view === 2 ? (
                    <PlanList />
                ) : (
                    <AirtelList />
                )}
            </Desc>
        </Container>
    );
};
