import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../../common/PagingBox";
import { SheetContainer } from "../../../common/SheetContainer";
import { SheetSortation } from "../../../common/SheetSortation";
import { useEffect, useState } from "react";
import { MdOutlineAddTask } from "react-icons/md";
import { VisibleSelectBox } from "../../../common/VisibleSelectBox";
import { priceparse } from "../common/TabMenu";
import { ProductDeleteModal } from "../common/ProductDeleteModal";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    align-items: center;
    // justify-content: start;
`;

const BtnContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: end;
    margin: 30px 40px 20px 0px;
`;

const AddPackageBtn = styled.button`
    display: flex;
    width: 120px;
    height: 40px;
    cursor: pointer;
    background-color: #23c162;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;

    h3 {
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
        font-weight: 500;
    }
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: black;
    }
`;

const DetailProduct = styled.div`
    display: flex;
    width: 100%;
    max-width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #708fe2;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const DeleteProduct = styled.button`
    display: flex;
    width: 100%;
    max-width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #f47878;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

export const PlanList = () => {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isDelete, setDelete] = useState(false);
    const [productid, setPid] = useState(0);
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    function moveloginDelete() {
        setDelete(false);
        navigate(`/adminpage/login`);
    }
    function closeDelete() {
        getProduct();
        setDelete(false);
    }
    function openDelete(id: number) {
        setPid(id);
        setDelete(true);
        getProduct();
    }
    const moveDetail = (item: any) => {
        navigate(`/adminpage/package/detail`, {
            state: {
                seq: `${item.package_seq}`,
                title: `${item.title}`,
                thumbnail: `${item.main_img}`,
                price: `${item.price}`,
                visible: `${item.visible}`,
                orderby: `${item.order_by}`,
            },
        });
    };

    const getProduct = async () => {
        try {
            const res = await axios.get(`/product/list?client_id=99&product_sort=PACKAGE&size=10&page=${nowpage}`);
            // console.log("package list: ", res.data);
            if (res.data.code === "WFL000") {
                // 총 페이지 수 계산
                setaTotalPage(Math.ceil(res.data.data.total_cnt / 10));
                // 리스트 저장
                setList(res.data.data.package_list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const setVisible = async (v: number, sort: string, id: number) => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        let body = {
            product_sort: sort,
            product_id: id,
            visible: v,
        };
        try {
            const res = await axios.patch(`/v1/products/visible`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("setVisible api: ", res.data);
            if (res.data.code === 0) {
                getProduct();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        getProduct();
    }, [nowpage]);

    return (
        <Container>
            {isDelete && (
                <ProductDeleteModal
                    open={isDelete}
                    close={closeDelete}
                    move={moveloginDelete}
                    sort={"package"}
                    id={productid}
                />
            )}
            <BtnContainer>
                {partnerlv > 1 && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/package/register`}
                    >
                        <AddPackageBtn>
                            <MdOutlineAddTask
                                color="#ffffff"
                                size={20}
                            />
                            <h3>상품 등록</h3>
                        </AddPackageBtn>
                    </Link>
                )}
            </BtnContainer>
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"70px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"800px"}
                        text={"상품 명"}
                    />
                    <SheetSortation
                        width={"210px"}
                        text={"판매 금액"}
                    />
                    <SheetSortation
                        width={"70px"}
                        text={"표현 순서"}
                    />
                    <SheetSortation
                        width={"130px"}
                        text={"상태"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"상세"}
                    />
                    {partnerlv > 1 && (
                        <SheetSortation
                            width={"110px"}
                            text={"상품"}
                        />
                    )}
                </SheetContainer>
                {list.map((item: any, i: number) => {
                    return (
                        <SheetContainer key={item.package_seq}>
                            <Content width="70px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                            <Content width="800px">{item.title}</Content>
                            <Content width="210px">{priceparse(item.price)}</Content>
                            <Content width="70px">{item.order_by}</Content>
                            <Content width="130px">
                                <VisibleSelectBox
                                    visible={item.visible}
                                    sort={"package"}
                                    id={item.package_seq}
                                    setState={setVisible}
                                    disable={false}
                                />
                            </Content>
                            <Content width="110px">
                                <DetailProduct onClick={() => moveDetail(item)}>
                                    <h3>미리보기</h3>
                                </DetailProduct>
                            </Content>
                            {partnerlv > 1 && (
                                <Content width="110px">
                                    <DeleteProduct onClick={() => openDelete(item.package_seq)}>
                                        <h3>상품삭제</h3>
                                    </DeleteProduct>
                                </Content>
                            )}
                        </SheetContainer>
                    );
                })}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </Container>
    );
};
