import { BiImages } from "react-icons/bi";
import styled from "styled-components";
import { PriceDatePicker } from "./PriceDatePicker";

const Container = styled.div`
    width: 100%;

    .rowBox {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 50px 0;

        .column {
            display: flex;
            flex-direction: column;
            margin-right: 50px;

            .imgBox {
                width: 550px;
                height: 300px;
                border-radius: 10px;
                background-color: #eeeeee;
            }
            .row {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
            }
            .mimgBox {
                width: 270px;
                height: 170px;
                border-radius: 10px;
                background-color: #eeeeee;
            }
            .allphoto {
                display: flex;
                height: 40px;
                font-size: 13px;
                font-weight: 500;
                color: #161a3f;
                background-color: #eeeeee;
                border-radius: 30px;
                border: 1px solid #161a3f;
                cursor: pointer;
                padding: 3px 10px 3px 10px;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }
            .modify {
                height: 40px;
                font-size: 13px;
                font-weight: 500;
                color: #ffffff;
                background-color: #2d40ff;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                padding: 3px 10px 3px 10px;
            }
        }
    }
`;

const Calendar = styled.div`
    width: 100%;
    max-width: 472px;

    .cover {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .modify {
            height: 40px;
            font-size: 13px;
            font-weight: 500;
            color: #ffffff;
            background-color: #2d40ff;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            padding: 3px 10px 3px 10px;
        }
        .status {
            display: flex;
            justify-content: flex-end;
            gap: 12px;
            padding-top: 10px;

            div {
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .box {
                margin: 0;
                width: 16px;
                height: 16px;
                border-radius: 2px;
            }
            span {
                font-size: 12px;
            }
            .today {
                border: 1px solid #2d40ff;
            }
            .selected {
                background: #2d40ff;
            }
            .low_price {
                border: 1px solid #e20000;
            }
            .closed {
                background: #e3e4ed;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .status {
            padding: 16px 16px;
        }
    }
`;

interface props {
    main_img: string;
    calendar: any;
    img_list: Array<any>;
    hflag: boolean;
    photoModal: (v: number) => void;
    calModal: (v: boolean) => void;
    scroll: (v: number) => void;
}

export const AirtelCalendarImgItem = (props: props) => {
    const { main_img, calendar, img_list, hflag, photoModal, calModal, scroll } = props;
    const list = img_list.sort((a: any, b: any) => a.order_by - b.order_by);
    const partner_lv = Number(sessionStorage.getItem("partnerlv"));

    return (
        <Container>
            <div className="rowBox">
                <div className="column">
                    <img
                        className="imgBox"
                        src={main_img}
                    />
                    <div className="row">
                        <img
                            className="mimgBox"
                            src={list[0].image_url}
                        />
                        {list.length > 1 && (
                            <img
                                className="mimgBox"
                                src={list[1].image_url}
                            />
                        )}
                    </div>
                    <div className="row">
                        <button
                            ref={() => scroll(350)}
                            className="allphoto"
                            onClick={() => photoModal(1)}
                        >
                            <BiImages
                                color="#161a3f"
                                size={20}
                            />
                            이미지 전체 보기
                        </button>
                        {partner_lv > 1 && (
                            <button
                                className="modify"
                                onClick={() => photoModal(2)}
                            >
                                이미지 리스트 수정
                            </button>
                        )}
                    </div>
                </div>
                <Calendar>
                    <PriceDatePicker
                        calendar={calendar}
                        hflag={hflag}
                        scroll={scroll}
                    />
                    <div className="cover">
                        {partner_lv > 1 && (
                            <button
                                className="modify"
                                onClick={() => calModal(true)}
                            >
                                캘린더 수정
                            </button>
                        )}
                        <div className="status">
                            <div>
                                <p className="box today" />
                                <span>오늘</span>
                            </div>
                            <div>
                                <p className="box selected" />
                                <span>선택한 날짜</span>
                            </div>
                            <div>
                                <p className="box low_price" />
                                <span>최저가</span>
                            </div>
                            <div>
                                <p className="box closed" />
                                <span>마감</span>
                            </div>
                        </div>
                    </div>
                </Calendar>
            </div>
        </Container>
    );
};
