import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { PiUserCircleGear } from "react-icons/pi";
import { BsHouseLock } from "react-icons/bs";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

const BtnContainer = styled.div`
    display: flex;
    height: 400px;
`;

const BtnBox = styled.button`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 400px;
    margin: 0px 60px 0px 60px;
    border: 4px solid ${(props) => props.color};
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;

    .icon {
        margin-top: 50px;
    }

    h3 {
        margin-top: 60px;
        font-weigth: 500;
        font-size: 40px;
        color: ${(props) => props.color};
    }
`;

export function Myinfo() {
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    setSelectMenu(5);
    setTitle("MY INFO");
    setSubTitle("");
    setThirdTitle("");

    // useEffect(() => {
    //     navigate(`/adminpage/myinfo/info`);
    // });
    return (
        <BaseContainer>
            <Container>
                <BtnContainer>
                    <BtnBox
                        color="#d1e0f5"
                        onClick={() => navigate(`/adminpage/myinfo/info`)}
                    >
                        <PiUserCircleGear
                            className="icon"
                            size={150}
                            color={"#d1e0f5"}
                        />
                        <h3>계정정보 변경</h3>
                    </BtnBox>
                    <BtnBox
                        color="#d1e0f5"
                        onClick={() => navigate(`/adminpage/myinfo/password`)}
                    >
                        <BsHouseLock
                            className="icon"
                            size={150}
                            color={"#d1e0f5"}
                        />
                        <h3>비밀번호 변경</h3>
                    </BtnBox>
                </BtnContainer>
            </Container>
        </BaseContainer>
    );
}
