import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import NavBar from "./common/NavVar";
import { Dashboard } from "./dashboard/view/DashboardView";
import { createBrowserHistory } from "history";
import NavTopBar from "./common/NavTopBar";
import { Account } from "./account/view/AccountView";
import { Myinfo } from "./account/view/MyInfoView";
import { ProductList } from "./product/view/ProductListView";
import { CounsellingList } from "./product/view/CounsellingListView";
import { ProductReservation } from "./reservation/components/product/ProductReservList";
import { HotelReservation } from "./reservation/view/HotelReservView";
import Footer from "./common/Footer";
import { AccountRegister } from "./account/view/AccountRegisterView";
import { InfoUpdate } from "./account/view/InfoUpdateView";
import { ChangePwd } from "./account/view/PasswordChangeView";
import { PackageRegister } from "./product/view/PackageRegisterView";
import { AirtelRegister } from "./product/view/AirtelRegisterView";
import { PlanDetail } from "./product/view/PlanDetailView";
import { AirtelDetail } from "./product/view/AirtelDetailView";
import { CounsellingDetail } from "./product/view/CounsellingDetailView";
import { ReservDetail } from "./reservation/components/product/ReservDetail";
import { ProductNoti } from "./product/view/ProdcutNotificationView";
import { HotdealRegister } from "./product/view/HotdealRegisterView";
import { ReservList } from "./reservation/view/ReservListView";
import { HotdealReservDetail } from "./reservation/components/hotdeal/HotdealReservDetail";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #eeeeee;
`;
const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #eeeeee;
`;

function App() {
    const history = createBrowserHistory();
    const cid = sessionStorage.getItem("clientid") || "none";
    const plevel = sessionStorage.getItem("partnerlv") || "none";
    const accesstoken = sessionStorage.getItem("accesstoken") || "none";
    const refreshtoken = sessionStorage.getItem("refreshtoken") || "none";
    // console.log(cid, plevel, accesstoken, refreshtoken);

    if (cid === "none" || plevel === "none" || accesstoken === "none" || refreshtoken === "none") {
        alert("재로그인이 필요합니다.");
        history.back();
    }

    return (
        <>
            <Container>
                <NavBar />
                <SubContainer>
                    <NavTopBar />
                    <Routes>
                        <Route
                            path="/dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            path="/productlist"
                            element={<ProductList />}
                        />
                        <Route
                            path="/package/register"
                            element={<PackageRegister />}
                        />
                        <Route
                            path="/hotdeal/register"
                            element={<HotdealRegister />}
                        />
                        <Route
                            path="/airtel/register"
                            element={<AirtelRegister />}
                        />
                        <Route
                            path="/package/detail"
                            element={<PlanDetail />}
                        />
                        <Route
                            path="/airtel/detail"
                            element={<AirtelDetail />}
                        />
                        <Route
                            path="/counsellinglist"
                            element={<CounsellingList />}
                        />
                        <Route
                            path="/counsellinglist/counsellingdetail"
                            element={<CounsellingDetail />}
                        />
                        <Route
                            path="/productnoti"
                            element={<ProductNoti />}
                        />
                        <Route
                            path="/productreserv"
                            element={<ReservList />}
                        />
                        <Route
                            path="/productreserv/reservdetail"
                            element={<ReservDetail />}
                        />
                        <Route
                            path="/productreserv/hotdealdetail"
                            element={<HotdealReservDetail />}
                        />
                        <Route
                            path="/hotelreserv"
                            element={<HotelReservation />}
                        />
                        <Route
                            path="/account"
                            element={<Account />}
                        />
                        <Route
                            path="/account/register"
                            element={<AccountRegister />}
                        />
                        <Route
                            path="/myinfo"
                            element={<Myinfo />}
                        />
                        <Route
                            path="/myinfo/info"
                            element={<InfoUpdate />}
                        />
                        <Route
                            path="/myinfo/password"
                            element={<ChangePwd />}
                        />
                    </Routes>
                    <Footer />
                </SubContainer>
            </Container>
        </>
    );
}

export default App;
