import axios from "axios";
import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NoneBackSpinner from "../../../common/NoneBackSpinner";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../../common/PagingBox";
import { RsvListSearchBox } from "../../../common/ReservationListSearchBox";
import { SheetContainer } from "../../../common/SheetContainer";
import { SheetSortation } from "../../../common/SheetSortation";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { dateParse } from "../../../product/view/CounsellingListView";
import { hotdealPathParse, hotdealStateColorParse, hotdealStateParse, HpDateParse } from "../common/ReservUtil";
import { HotdealExcelDownLoad } from "./HotdealExcelDownload";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 530px;
    align-items: center;
    // justify-content: center;
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 13px;

    .green {
        color: #20a656;
    }
    .blue {
        color: #2d40ff;
    }
    .yellow {
        color: #e7a11a;
    }
    .red {
        color: #ee4c4c;
    }
    .gray {
        color: #838c97;
    }
`;

const Listbase = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        // background-color: #fcfcfc;
        font-weight: 700;
        font-size: 20px;
    }
`;

export const HotdealReservList = () => {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isShow, setShow] = useState(false);
    const [allcnt, setAll] = useState<number>();
    const [idata, setIdata] = useState({
        txt: "",
        str: "",
        end: "",
    });
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    const moveDetail = (item: any) => {
        navigate(`/adminpage/productreserv/hotdealdetail`, {
            state: {
                item: JSON.stringify(item),
            },
        });
    };

    const getlist = async (str: string, end: string, txt: string) => {
        setList([]);
        setShow(true);
        setIdata({ str: str, end: end, txt: txt });
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/event/product/reservation/admin`, {
                params: {
                    state: "ALL",
                    value: txt,
                    start_day: str,
                    end_day: end,
                    size: 10,
                    page: nowpage,
                },
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("reservation: ", res.data);
            setShow(false);
            if (res.data.code === 0) {
                // 총 페이지 수 계산
                setAll(res.data.data.total_cnt);
                setaTotalPage(Math.ceil(res.data.data.total_cnt / 10));
                // 리스트 저장
                setList(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        getlist("2023-12-18", format(new Date(), "yyyy-MM-dd"), "");
    }, [nowpage]);

    return (
        <Container>
            <RsvListSearchBox
                title="구매내역 검색"
                searching={getlist}
            />
            <HotdealExcelDownLoad
                cnt={allcnt}
                txt={idata.txt}
                str={idata.str}
                end={idata.end}
            />
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"50px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"구매경로"}
                    />
                    <SheetSortation
                        width={"250px"}
                        text={"상품명"}
                    />
                    <SheetSortation
                        width={"150px"}
                        text={"결제상태"}
                    />
                    <SheetSortation
                        width={"150px"}
                        text={"결제금액"}
                    />
                    <SheetSortation
                        width={"130px"}
                        text={"구매번호"}
                    />
                    <SheetSortation
                        width={"120px"}
                        text={"구매자"}
                    />
                    <SheetSortation
                        width={"140px"}
                        text={"연락처"}
                    />
                    <SheetSortation
                        width={"170px"}
                        text={"이메일"}
                    />
                    <SheetSortation
                        width={"200px"}
                        text={"구매일시"}
                    />
                </SheetContainer>
                {isShow ? (
                    <NoneBackSpinner />
                ) : (
                    list.map((item: any, i: number) => {
                        return (
                            <Listbase
                                key={item.event_product_reservation_seq * i}
                                onClick={() => moveDetail(item)}
                            >
                                <Content width="50px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                                <Content width="110px">{hotdealPathParse(item)}</Content>
                                <Content width="250px">{item.product_name}</Content>
                                <Content width="150px">
                                    <text className={hotdealStateColorParse(item)}>{hotdealStateParse(item)}</text>
                                </Content>
                                <Content width="150px">{priceParseComma(item.cumulative_amount.toString())}원</Content>
                                <Content width="130px">{item.reservation_id}</Content>
                                <Content width="120px">{item.user_name}</Content>
                                <Content width="140px">{item.user_phone}</Content>
                                <Content width="170px">{item.user_email}</Content>
                                <Content width="200px">{HpDateParse(item.create_date)}</Content>
                            </Listbase>
                        );
                    })
                )}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </Container>
    );
};
