import axios from "axios";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { levelinputAtom } from "../components/CustomSelectBox";
import { RegiBtnBox } from "../components/RegisterBtnBox";
import {
    addrinputAtom,
    callinputAtom,
    emailinputAtom,
    idinputAtom,
    inchargeinputAtom,
    nameinputAtom,
    phoneinputAtom,
    RegiInputBox,
} from "../components/RegisterInputBox";
import { levelparse } from "./AccountView";

export function InfoUpdate() {
    const navigate = useNavigate();
    const setId = useSetAtom(idinputAtom);
    const setName = useSetAtom(nameinputAtom);
    const setAddr = useSetAtom(addrinputAtom);
    const setCall = useSetAtom(callinputAtom);
    const setEmail = useSetAtom(emailinputAtom);
    const setIncharge = useSetAtom(inchargeinputAtom);
    const setPhone = useSetAtom(phoneinputAtom);
    const setLevel = useSetAtom(levelinputAtom);
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    setSelectMenu(5);
    setTitle("MY INFO");
    setSubTitle("계정 정보 수정");
    setThirdTitle("");

    const getInfo = async () => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/v1/partners/detail`, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("get info: ", res.data);
            if (res.data.code === 0) {
                // 정보 저장
                setId(res.data.data.partner_id);
                setName(res.data.data.partner_name);
                setAddr(res.data.data.partner_address);
                setCall(res.data.data.partner_contact);
                setEmail(res.data.data.partner_email);
                setIncharge(res.data.data.incharge);
                setPhone(res.data.data.incharge_contact);
                setLevel(levelparse(res.data.data.partner_lv));
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        getInfo();
    });

    return (
        <BaseContainer>
            <RegiInputBox sort={false} />
            <RegiBtnBox sort={false} />
        </BaseContainer>
    );
}
