import { useAtom, useSetAtom } from "jotai";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const BasicContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1720px;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1650px;
    height: 80vh;
    align-items: center;
    background-color: #ffffff;
`;

export function BaseContainer({ children }: PropsWithChildren) {
    return (
        <BasicContainer>
            <Container>{children}</Container>
        </BasicContainer>
    );
}
