import axios from "axios";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { BsDatabaseUp } from "react-icons/bs";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../../common/BaseContainer";
import { BasicModal } from "../../../common/BasicModal";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../../common/NavTopBar";
import { SelectMenu } from "../../../common/NavVar";
import { dateParse } from "../../../product/view/CounsellingListView";
import { PayHistory } from "./PayHistory";
import { PayTransferModal } from "./PayTranferModal";
import { paymentTypeParse, productSortParse, RESERVSTATE, stateParse } from "../common/ReservUtil";
import { ReadOnlyIBox } from "../common/ReadOnlyIBox";
import { BemilyPCModal } from "../common/BemilyPayCancelModal";
import { BemilyMemoModal } from "../common/BemilyMemoModal";

const Top = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .menu {
        display: flex;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 5px;

        .backicon {
            cursor: pointer;
        }
        .title {
            color: #2d40ff;
            font-size: 20px;
            font-weight: 500;
            justify-content: center;
        }
        .space {
            width: 10px;
        }
        .rowbtn {
            display: flex;
            gap: 5px;
            margin-right: 10px;

            .btn {
                padding: 12px 20px;
                background-color: #2d40ff;
                border: none;
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
    .stick {
        width: 95%;
        height: 2px;
        background-color: #eeeeee;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: start;
    justify-content: center;
    padding: 10px;

    .btnsector {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        padding: 10px 0;
        // background-color: #eeeeee;

        .addtransfer {
            display: flex;
            padding: 10px;
            border: none;
            border-radius: 7px;
            background-color: #23c162;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
        }
    }

    .sector {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        // background-color: #eeeeee;

        .text {
            display: flex;
            width: 100%;
            max-width: 100px;
            height: 42px;
            align-items: center;

            h3 {
                margin-left: 5px;
                font-size: 15px;
                font-weight: 500;
                color: #122437;
            }
        }

        .rowbox {
            display: flex;
            gap: 5px;
            padding: 0px 10px;
            // background-color: #2d40ff;

            .cstick {
                width: 2px;
                height: 270px;
                background-color: #eeeeee;
                margin-top: 5px;
            }
        }

        .stick {
            width: 70%;
            height: 1px;
            background-color: #eeeeee;
            margin: 20px 0px;
        }

        .listbox {
            margin-top: 5px;
            display: block;
            overflow: auto;
            width: 75%;
            height: 270px;
            // backgroun-color: #fff111;
        }
    }
`;

export function ReservDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [isShow, setShow] = useState(false);
    const [isData, setData] = useState(false);
    const item = { ...location.state };
    const [reservItem, setReservItem] = useState<any>();
    const [tiModal, setTiModal] = useState(false);
    const [bModal, setBmodal] = useState<boolean>(false);
    const [pcModal, setPCmodal] = useState<boolean>(false);
    const [memoModal, setMemoModal] = useState<boolean>(false);
    setSelectMenu(3);
    setTitle("구매 판매 내역");
    setSubTitle("상품 구매 내역");
    setThirdTitle("구매 내역 상세");
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    // console.log("???@! ", item);
    const backHandle = () => {
        navigate(`/adminpage/productreserv`, { state: { to: item.product_sort } });
    };

    const btnState = () => {
        let result = false;
        if (partnerlv > 0) {
            if (stateParse(item) === RESERVSTATE.PAYPROGRESS || stateParse(item) === RESERVSTATE.PAYWAITING) {
                if (item.payment_type === "TRANSFER") {
                    result = true;
                }
            }
        }
        return result;
    };

    const refundBtn = () => {
        let result = false;
        if (isData) {
            if (reservItem.payment_history.length > 0) {
                if (partnerlv > 0 && item.client_id === "518") {
                    result = true;
                }
            }
        }
        return result;
    };

    const bemilyMemo = () => {
        let result = false;
        if (isData) {
            if (partnerlv > 0 && item.client_id === "518") {
                result = true;
            }
        }
        return result;
    };

    const refreshClose = () => {
        stateCheck();
        setTiModal(false);
    };

    useEffect(() => {
        stateCheck();
    }, []);

    const agreeMove = () => {
        navigate(`/adminpage/counsellinglist/counsellingdetail`, {
            state: {
                seq: `${item.seq}`,
                reservation_id: `${item.reservation_id}`,
                product_id: `${item.product_id}`,
                product_sort: `${item.product_sort}`,
                product_name: `${item.product_name}`,
                product_price: `${item.product_price}`,
                last_payment_day: `${item.last_payment_day}`,
                consumer_name: `${item.consumer_name}`,
                consumer_phone: `${item.consumer_phone}`,
                consumer_email: `${item.consumer_email}`,
                payment_state: `${item.payment_state}`,
                counseling_state: `${item.counseling_state}`,
                cumulative_amount: `${item.cumulative_amount}`,
                add_datetime: `${item.add_datetime}`,
                partner_name: `${item.partner_name}`,
                payment_type: `${item.payment_type}`,
                with_persons: `${item.with_persons}`,
                persons_info: `${item.persons_info}`,
                client_id: `${item.client_id}`,
                bemily_id: `${item.bemily_id}`,
            },
        });
    };

    const stateCheck = () => {
        // console.log("???? ", stateParse(item));
        if (stateParse(item) === RESERVSTATE.CSWAITING) {
            if (partnerlv > 0) {
                setBmodal(true);
            } else {
                alert("아직 상담이 진행되지 않은 구매건입니다.");
            }
        } else {
            getReservInfo();
        }
    };

    const closeback = () => {
        setPCmodal(false);
        backHandle();
    };

    const getReservInfo = async () => {
        setShow(true);

        try {
            const res = await axios.get(`/product/reservation`, {
                params: {
                    reservation_id: item.reservation_id,
                    phone: item.consumer_phone,
                },
            });
            setShow(false);
            // console.log("reserv search? ", res.data);
            if (res.data.code === "WFL000") {
                setReservItem(res.data.data);
                setData(true);
            }
        } catch (e: any) {
            // 에러 처리
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <BaseContainer>
            {tiModal && (
                <PayTransferModal
                    open={tiModal}
                    reservid={item.reservation_id}
                    refresh={refreshClose}
                    close={setTiModal}
                />
            )}
            {bModal && (
                <BasicModal
                    open={bModal}
                    title={"페이지 이동"}
                    content={"해당 구매내용은 상담이 이루어져있지 않아 결제내역이 없습니다. 상담을 진행하시겠습니까?"}
                    agree={agreeMove}
                    close={setBmodal}
                />
            )}
            {pcModal && (
                <BemilyPCModal
                    open={pcModal}
                    reservation_id={item.reservation_id}
                    type={item.product_sort}
                    cumulative={item.cumulative_amount}
                    close={setPCmodal}
                    back={closeback}
                />
            )}
            {memoModal && (
                <BemilyMemoModal
                    open={memoModal}
                    bemily_id={item.bemily_id}
                    close={setMemoModal}
                />
            )}
            <Top>
                <div className="menu">
                    <RiArrowGoBackLine
                        className="backicon"
                        size={30}
                        color={"#2d40ff"}
                        onClick={backHandle}
                    />
                    <h3 className="title">구매내역 상세페이지</h3>
                    {refundBtn() ? (
                        <div className="rowbtn">
                            <button
                                className="btn"
                                onClick={() => setMemoModal(true)}
                            >
                                메모 보내기
                            </button>
                            {btnState() === false && (
                                <button
                                    className="btn"
                                    onClick={() => setPCmodal(true)}
                                >
                                    결제 취소 요청
                                </button>
                            )}
                        </div>
                    ) : (
                        bemilyMemo() && (
                            <div className="rowbtn">
                                <button
                                    className="btn"
                                    onClick={() => setMemoModal(true)}
                                >
                                    메모 보내기
                                </button>
                            </div>
                        )
                    )}
                </div>
                <div className="stick" />
            </Top>
            <Body>
                <div className="sector">
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매번호"
                        value={item.reservation_id}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매경로"
                        value={item.partner_name}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="상품구분"
                        value={productSortParse(item)}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="상품 명"
                        value={item.product_name}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="진행상태"
                        value={stateParse(item)}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매요청일"
                        value={dateParse(item.add_datetime)}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="결제 만료일"
                        value={item.last_payment_day}
                    />
                    <div className="stick" />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매자 명"
                        value={item.consumer_name}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="연락처"
                        value={item.consumer_phone}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="이메일"
                        value={item.consumer_email}
                    />
                </div>
                {isData && (
                    <div className="sector">
                        <ReadOnlyIBox
                            type="txt"
                            mw={350}
                            title="결제 구분"
                            value={paymentTypeParse(item)}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="총 결제금액"
                            value={reservItem.product_price.toString()}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="잔여 금액"
                            value={reservItem.balance_amount.toString()}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="결제된 금액"
                            value={reservItem.cumulative_amount.toString()}
                        />
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>결제기록</h3>
                        </div>
                        <div className="rowbox">
                            <div className="cstick" />
                            <div className="listbox">
                                {reservItem.payment_history.length > 0 &&
                                    reservItem.payment_history.map((data: any, i: number) => {
                                        return (
                                            <PayHistory
                                                key={data.client_id + i}
                                                data={data}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                )}
                <div className="btnsector">
                    {btnState() && (
                        <button
                            className="addtransfer"
                            onClick={() => setTiModal(true)}
                        >
                            <BsDatabaseUp
                                color="#ffffff"
                                size={17}
                            />
                            이체내역 추가
                        </button>
                    )}
                </div>
            </Body>
        </BaseContainer>
    );
}
