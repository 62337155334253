import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { BiSolidRightArrow } from "react-icons/bi";
import { ChangeEvent, useEffect, useState } from "react";
import { RegisterVisibleBox } from "../../common/RegisterVisibleBox";
import { SelectMenu } from "../../common/NavVar";
import { useSetAtom } from "jotai";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import ThumbnailImgUploader from "../components/common/ThumbnailAdd";
import DetailImgUploader, { Box } from "../components/common/DetailimgAdd";
import { imgUrl } from "./ProductListView";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";
import { TYPE_PRODUCT_DETAIL } from "../components/common/TabMenu";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../components/common/updateimgInterface";

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    height: 70vh;
    // background-color: #2d40ff;
`;

const Container = styled.div<{ width: string; val: string }>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.val};
    width: ${(props) => props.width};
    // background-color: #fff000;

    .title {
        margin-bottom: 50px;
    }

    .detail {
        margin-top: 100px;
    }
`;

const StickBar = styled.div`
    width: 2px;
    height: 60vh;
    margin-top: 70px;
    background-color: #eeeeee;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const Box2Container = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 500px;
    height: 150px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
`;

const ImgAddContainer = styled.div`
    display: flex;
    width: 300px;
    height: 150px;
    align-items: center;
    // justify-content: start;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

const BtnContainer = styled.div`
    display: flex;
    width: 65%;
    height: 40px;
    justify-content: end;
    // background-color: #000000;
`;

const Btn = styled.button<{ type: boolean }>`
    display: flex;
    width: 80px;
    height: 40px;
    cursor: pointer;
    background-color: ${(props) => (props.type ? "#dcdeeb" : "#2d40ff")};
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: ${(props) => (props.type ? "#707288" : "#ffffff")};
    font-weight: 500;
    margin-left: 10px;
`;

export function PackageRegister() {
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [order, setOrder] = useState(1);
    const [visible, setVisible] = useState(1);
    const [thumbnail, setThumbnail] = useState<string>(imgUrl);
    const [file, setFile] = useState<FileList | null>();
    const [detail, setDetail] = useState<Array<Box>>([]);
    const [isShow, setShow] = useState(false);
    const [mainImg, setMainimg] = useState("");
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const [s3flag, setS3flag] = useState(0);
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("패키지등록");

    if (Number(sessionStorage.getItem("partnerlv")) < 2) {
        alert("권한이 맞지 않습니다.");
        navigate(`/adminpage/productlist`, { state: { to: `plan` } });
    }

    const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (thumbnail !== "") {
                onImageRemove();
            }
            setFile(event.target.files);
            setThumbnail(URL.createObjectURL(event.target.files![0]));
        }
    };
    const onImageRemove = (): void => {
        if (thumbnail.includes("blob")) {
            URL.revokeObjectURL(thumbnail);
        }
        setThumbnail(imgUrl); // 렌더링 이미지 초기화
        setFile(null);
    };
    const regPackage = (list: Array<Box>) => {
        setDetail(list);
    };
    const visibleSelect = (v: number) => {
        setVisible(v);
    };
    const nameInput = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    const priceInput = (e: ChangeEvent<HTMLInputElement>) => {
        setPrice(Number(e.target.value));
    };
    const orderInput = (e: ChangeEvent<HTMLInputElement>) => {
        setOrder(Number(e.target.value));
    };
    const cancelBtnHandle = () => {
        let arr = [];
        arr.push(thumbnail);
        detail!.forEach((e) => {
            arr.push(e.image_url);
        });
        removeBloburl(arr);
        navigate(`/adminpage/productlist`, { state: { to: `plan` } });
    };

    useEffect(() => {
        if (s3flag == 1) {
            setImgS3list();
        } else if (s3flag == 2) {
            regPackageData();
        }
    }, [s3flag]);

    const setImgS3main = async () => {
        if (name === "") {
            alert("패키지 상품 명을 확인해 주세요");
            return;
        }
        if (price === 0) {
            alert("패키지 상품 가격을 확인해 주세요");
            return;
        }
        if (order === 0) {
            alert("패키지 상품 표현 순서를 확인해 주세요");
            return;
        }
        if (thumbnail === imgUrl) {
            alert("패키지 상품 썸네일 이미지를 확인해 주세요");
            return;
        }
        if (detail!.length > 0) {
            let imgflag = false;
            let ratioflag = false;
            let orderflag = false;
            for (let i = 0; i < detail!.length; i++) {
                if (detail![i].image_url === imgUrl) {
                    imgflag = true;
                    break;
                } else if (detail![i].ratio === "") {
                    ratioflag = true;
                    break;
                } else if (detail![i].order_by === 0) {
                    orderflag = true;
                    break;
                }
            }
            if (imgflag) {
                alert("상세 이미지가를 확인해 주세요");
                return;
            } else if (ratioflag) {
                alert("상세 이미지 비율값을 확인해 주세요");
                return;
            } else if (orderflag) {
                alert("상세 이미지 표현순서를 확인해 주세요");
                return;
            }
        }

        setShow(true);
        setS3flag(0);

        if (thumbnail.includes("blob")) {
            getS3imgContent(1)?.then(({ data }) => {
                const list = data.data.list;
                setS3imgContent(list[0].upload_url, list[0].result_url, file![0]);
                URL.revokeObjectURL(thumbnail);
                setMainimg(list[0].result_url);
            });
        } else {
            setMainimg(thumbnail);
        }
        setShow(false);
        setS3flag(1);
    };

    const setImgS3list = async () => {
        setShow(true);

        getS3imgContent(detail!.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < detail!.length; i++) {
                if (detail![i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, detail![i].file);
                    URL.revokeObjectURL(detail![i].image_url);
                    const boxitem = {
                        image_url: list[i].result_url,
                        ratio: detail![i].ratio,
                        order_by: detail![i].order_by,
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(2);
        });
    };

    const regPackageData = async () => {
        // 로딩 시작
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            title: name,
            main_img: mainImg,
            price: price,
            visible: visible,
            order_by: order,
            detail_img_list: imgArr,
        };

        try {
            const res = await axios.post(`/v1/packages`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("modal: ", res.data);
            // 로딩 종료
            setShow(false);
            setMainimg("");
            setImgArr([]);
            if (res.data.code === 0) {
                alert("패키지 등록이 완료되었습니다.");
                navigate(`/adminpage/productlist`, { state: { to: `plan` } });
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <RowContainer>
                <Container
                    width="35%"
                    val="100px"
                >
                    <h3 className="title">패키지 상품 등록</h3>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상품 명</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={name}
                                placeholder="상품 명을 입력해주세요"
                                onChange={nameInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>판매 금액</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="number"
                                value={price}
                                placeholder="금액을 입력해주세요 (숫자만)"
                                onChange={priceInput}
                            />
                        </InputBox>
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>표현 순서</h3>
                        </TextContainer>
                        <InputBox width="100px">
                            <input
                                type="number"
                                value={order}
                                placeholder=""
                                onChange={orderInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <Box2Container set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>썸네일 이미지</h3>
                        </TextContainer>
                        <ImgAddContainer>
                            <ThumbnailImgUploader
                                fileURL={thumbnail}
                                flag={true}
                                uploadImg={onImageChange}
                                removeImg={onImageRemove}
                            />
                        </ImgAddContainer>
                    </Box2Container>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상태</h3>
                        </TextContainer>
                        <RegisterVisibleBox
                            visible={1}
                            setState={visibleSelect}
                        />
                    </BoxContainer>
                </Container>
                <StickBar />
                <Container
                    width="60%"
                    val="20px"
                >
                    <div className="detail" />
                    <DetailImgUploader
                        detailData={regPackage}
                        type={TYPE_PRODUCT_DETAIL}
                    />
                </Container>
            </RowContainer>
            <BtnContainer>
                <Btn
                    type={true}
                    onClick={cancelBtnHandle}
                >
                    취소
                </Btn>
                <Btn
                    type={false}
                    onClick={setImgS3main}
                >
                    등록
                </Btn>
            </BtnContainer>
        </BaseContainer>
    );
}
