import { MdOutlinePayment } from "react-icons/md";
import styled from "styled-components";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 310px;
    gap: 5px;
    border: 1px solid #eeeeee;
    border-radius: 7px;
    padding: 5px;
    margin-bottom: 10px;
`;

const BoxContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 300px;
    height: 35px;
    align-items: center;
    justify-content: stretch;
    // margin: 5px 0 5px 0;
    // background-color: #fff111;

    .won {
        font-size: 13px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 35px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 13px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 200px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 5px 5px 5px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    data: any;
}

export const PayHistory = (props: props) => {
    const { data } = props;
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    return (
        <Container>
            {partnerlv > 0 && (
                <BoxContainer>
                    <div className="text">
                        <MdOutlinePayment
                            color="#6f7cff"
                            size={15}
                        />
                        <h3>PG사 ID</h3>
                    </div>
                    <input
                        type="text"
                        value={data.payment_id}
                        readOnly
                    />
                </BoxContainer>
            )}
            <BoxContainer>
                <div className="text">
                    <MdOutlinePayment
                        color="#6f7cff"
                        size={15}
                    />
                    <h3>결제일시</h3>
                </div>
                <input
                    type="text"
                    value={data.payment_date}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer>
                <div className="text">
                    <MdOutlinePayment
                        color="#6f7cff"
                        size={15}
                    />
                    <h3>결제금액</h3>
                </div>
                <input
                    type="text"
                    value={priceParseComma(data.payment_amount.toString())}
                    readOnly
                />
                <h3 className="won">원</h3>
            </BoxContainer>
        </Container>
    );
};
