import { useState, useRef, ChangeEvent } from "react";
import axios from "axios";
import styled from "styled-components";

const ImgContainer = styled.div<{ flag: boolean }>`
    width: 700px;
    display: flex;
    // background-color: #22ff11;
    border: ${(props) => (props.flag ? "1px" : "0px")} solid #000000;
    margin-bottom: 5px;
`;

const Img = styled.img<{ h: string; flag: boolean }>`
    width: 500px;
    height: ${(props) => props.h};
    object-fit: cover; // 비율 조정
    border-right: ${(props) => (props.flag ? "1px" : "0px")} solid #000000;
`;

const SImgInput = styled.input`
    display: none;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    padding-left: 10px;
    align-items: start;
    justify-content: space-between;
    margin-top: 10px;
    // background-color: #2d40ff;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 150px;
    height: 40px;
    padding: 5px 5px 5px 5px;
    align-items: start;
    justify-content: start;
    // background-color: #fff000;

    button {
        height: 30px;
        padding: 5px 5px 5px 5px;
        margin-right: 10px;
    }
`;

const TextBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 130px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 0px 5px 2px 5px;
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 25px;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 5px 5px 5px 5px;
        font-size: 14px;
        outline: none;
    }
`;

const ItemContainer = styled.div``;

interface props {
    seq: number;
    fileURL: string;
    ratio: string;
    order: number;
    flag: boolean;
    uploadImg: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    ratiohandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    orderhandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
}

const UpdateDetailImgBox = (props: props) => {
    const { seq, fileURL, ratio, order, flag, uploadImg, ratiohandle, orderhandle } = props; // props로 함수 받기
    const imgUploadInput = useRef<HTMLInputElement | null>(null);

    const ratioHandle = (e: ChangeEvent<HTMLInputElement>) => {
        ratiohandle(seq, e);
    };
    const orderHandle = (e: ChangeEvent<HTMLInputElement>) => {
        orderhandle(seq, e);
    };
    const imgHandle = (e: ChangeEvent<HTMLInputElement>) => {
        uploadImg(seq, e);
    };

    return (
        <ImgContainer flag={flag}>
            <Img
                key={seq}
                h={(500 / Number(ratio)).toString()}
                src={fileURL}
                flag={flag}
            />
            <SImgInput
                type="file"
                id="img"
                accept="image/*"
                required
                ref={imgUploadInput}
                onChange={imgHandle}
            />
            {flag && (
                <RightContainer>
                    {ratio !== "" && (
                        <TextBox set="space-between">
                            <InputBox width="130px">
                                <input
                                    type="text"
                                    value={ratio}
                                    placeholder="비율 소수점 4자리까지"
                                    onChange={ratioHandle}
                                />
                            </InputBox>
                        </TextBox>
                    )}
                    <TextBox set="space-between">
                        <InputBox width="130px">
                            <input
                                type="number"
                                value={order === 0 ? "" : order}
                                placeholder="표현 순서"
                                onChange={orderHandle}
                            />
                        </InputBox>
                    </TextBox>
                    <BtnContainer>
                        <button
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                if (imgUploadInput.current) {
                                    imgUploadInput.current.click();
                                }
                            }}
                        >
                            이미지 첨부
                        </button>
                    </BtnContainer>
                </RightContainer>
            )}
        </ImgContainer>
    );
};

export default UpdateDetailImgBox;
