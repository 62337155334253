import { styled } from "styled-components";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animationData from "../loading.json";
import { useEffect, useRef } from "react";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 102;
    background-color: rgba(0, 0, 0, 0.8);
`;

export const Spinner = styled(Lottie)`
    width: 160px;
    height: 160px;
    margin: auto;
`;

export default function LoadingSpinner() {
    const lottieRef = useRef<LottieRefCurrentProps>();

    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.setSpeed(1.1);
        }
    }, []);

    return (
        <Container>
            <Spinner
                animationData={animationData}
                lottieRef={lottieRef as any}
            />
        </Container>
    );
}
