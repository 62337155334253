import styled from "styled-components";
import axios from "axios";
import { BsPerson } from "react-icons/bs";
import { CiUnlock } from "react-icons/ci";
import { atom, useAtom } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sha256 from "sha256";
import LoadingSpinner from "../common/LoadingSpinner";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: #fbfbfc;
    justify-content: center;
    align-items: center;
`;

const LoginBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 430px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 1px 3px 8px 3px #dddddd;
    background: #ffffff;
    align-items: center;

    h3 {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 50px;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 48px;
    margin-top: 10px;
    align-items: end;
    border-bottom: 2px solid ${(props) => props.color};
    padding: 10px 5px 5px 5px;
    input {
        width: 90%;
        line-height: 28px;
        border: 0px;
        padding: 10px 0px 0px 10px;
        font-size: 18px;
        outline: none;
    }
`;

const LoginBtn = styled.button`
    display: flex;
    margin-top: 50px;
    width: 400px;
    height: 48px;
    border-radius: 5px;
    background: #2d40ff;
    padding: 5px 0px 0px 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const accountAtom = atom("");
const passwordAtom = atom("");
const accountFocusAtom = atom(false);
const passwordFocusAtom = atom(false);
export function Login() {
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    const [focusAccount, setAccountFocus] = useAtom(accountFocusAtom);
    const [focusPassword, setPasswordFocus] = useAtom(passwordFocusAtom);
    const [account, setAccount] = useAtom(accountAtom);
    const [password, setPassword] = useAtom(passwordAtom);

    useEffect(() => {
        const accesstoken = sessionStorage.getItem("accesstoken") || "";
        if (accesstoken !== "") {
            navigate(`/adminpage/dashboard`);
        }
    });

    const accountInput = (e: ChangeEvent<HTMLInputElement>) => {
        setAccount(e.target.value);
    };
    const accountFocus = () => {
        setAccountFocus(true);
        setPasswordFocus(false);
    };
    const accountBlur = () => {
        setAccountFocus(false);
    };
    const passwordInput = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const passwordFocus = () => {
        setPasswordFocus(true);
        setAccountFocus(false);
    };
    const passwordBlur = () => {
        setPasswordFocus(false);
    };

    let body = {
        b2b_user_id: account,
        b2b_user_pw: sha256(password),
    };

    const loginBtnListener = async () => {
        if (account.length < 1 || password.length < 1) {
            alert("계정 ID 또는 패스워드를 확인해 주세요");
            return;
        }
        setShow(true);

        try {
            const res = await axios.post(`/v1/auths/login`, body);
            // console.log(res.data);
            if (res.data.code === 0) {
                getInfo(
                    res.data.data.access_token,
                    res.data.data.refresh_token,
                    res.data.data.partner_lv,
                    res.data.data.client_id
                );
            }
        } catch (e) {
            setShow(false);
            alert("계정 ID 또는 패스워드를 확인해 주세요");
            return;
        }
    };

    const getInfo = async (access: string, refresh: string, lv: number, client: number) => {
        try {
            const res = await axios.get(`/v1/partners/detail`, {
                headers: { Authorization: `Bearer ${access}` },
            });
            // console.log("get info: ", res.data);
            setShow(false);
            if (res.data.code === 0) {
                // 정보 저장
                sessionStorage.setItem("partnerid", res.data.data.partner_id);
                sessionStorage.setItem("clientid", client.toString());
                sessionStorage.setItem("partnerlv", lv.toString());
                sessionStorage.setItem("accesstoken", access);
                sessionStorage.setItem("refreshtoken", refresh);
                setAccount("");
                setPassword("");
                navigate(`/adminpage/dashboard`);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 에러 처리
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <LoginBox>
                <h3>Login</h3>
                <InputBox color={focusAccount ? "#2d40ff" : "#dddddd"}>
                    <BsPerson
                        size={30}
                        color={focusAccount ? "#2d40ff" : "#dddddd"}
                    />
                    <input
                        type="text"
                        value={account}
                        placeholder="client account"
                        onChange={accountInput}
                        onFocus={accountFocus}
                        onBlur={accountBlur}
                    />
                </InputBox>
                <InputBox color={focusPassword ? "#2d40ff" : "#dddddd"}>
                    <CiUnlock
                        size={30}
                        color={focusPassword ? "#2d40ff" : "#dddddd"}
                    />
                    <input
                        type="password"
                        value={password}
                        placeholder="client password"
                        onChange={passwordInput}
                        onFocus={passwordFocus}
                        onBlur={passwordBlur}
                    />
                </InputBox>
                <LoginBtn onClick={loginBtnListener}>로그인</LoginBtn>
            </LoginBox>
        </Container>
    );
}
