import axios from "axios";
import { ChangeEvent, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import { styled } from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { RegisterVisibleBox } from "../../../common/RegisterVisibleBox";
import DetailImgUploader, { Box } from "../common/DetailimgAdd";
import { TYPE_PRODUCT_DETAIL } from "../common/TabMenu";

const Container = styled.div`
    position: fixed;
    // background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 200px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-top: 200px;
    width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: block;
    // overflow: auto;
    height: 400px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .rowBox {
        display: flex;
        width: 90%;
        // background-color: #eeeeee;
        justify-content: space-between;

        .extxt {
            font-size: 13px;
            font-weight: 400;
            margin-right: 80px;
            color: #f47878;
        }
    }
    .marginb {
        margin-bottom: 20px;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ color: string; font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => props.font};
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 420px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
    // background-color: #fff111;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 100px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface day_update {
    seq: number;
    airtel_banner_seq: number;
    title: string;
    airline: string;
    airtel_date: string;
    search_day: string;
    last_payment_day: string;
    start_text: string;
    end_text: string;
    origin_price: number;
    discount_price: number;
    visible: number;
    order_by: number;
}

interface props {
    open: boolean;
    basic_data: any;
    close: () => void;
    update: () => void;
    backlogin: () => void;
}

export const AirtelDayListUpdate = (props: props) => {
    const { open, basic_data, close, update, backlogin } = props;
    const [isShow, setShow] = useState(false);
    const [airtel, setAirtel] = useState<day_update>(basic_data);

    const visibleSelect = (v: number) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: v,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const titleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: e.target.value,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const airlineInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: e.target.value,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const dateInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: e.target.value,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const starttxtInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: e.target.value,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const endtxtInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: e.target.value,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const searchdayInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: e.target.value,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const lastpaydayInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: e.target.value,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const priceInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: e.target.valueAsNumber,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const discountInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel!.airtel_banner_seq,
            title: airtel!.title,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            search_day: airtel!.search_day,
            last_payment_day: airtel!.last_payment_day,
            start_text: airtel!.start_text,
            end_text: airtel!.end_text,
            origin_price: airtel!.origin_price,
            discount_price: e.target.valueAsNumber,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const orderInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: day_update = {
            seq: airtel.seq,
            airtel_banner_seq: airtel.airtel_banner_seq,
            title: airtel.title,
            airline: airtel.airline,
            airtel_date: airtel.airtel_date,
            search_day: airtel.search_day,
            last_payment_day: airtel.last_payment_day,
            start_text: airtel.start_text,
            end_text: airtel.end_text,
            origin_price: airtel.origin_price,
            discount_price: airtel.discount_price,
            visible: airtel.visible,
            order_by: e.target.valueAsNumber,
        };
        setAirtel(data);
    };

    const setAirtelDayReg = async () => {
        if (airtel.title === "") {
            alert("일별 상품명을 확인해 주세요");
            return;
        }
        if (airtel.airtel_date === "") {
            alert("여행 일정을 확인해 주세요");
            return;
        }
        if (airtel.airline === "") {
            alert("여행사를 확인해 주세요");
            return;
        }
        if (airtel.search_day === "") {
            alert("에어텔 등록일을 확인해 주세요");
            return;
        }
        // if (airtel.last_payment_day === "") {
        //     alert("결제만료일을 확인해 주세요");
        //     return;
        // }
        if (airtel.start_text === "") {
            alert("출발 일정을 확인해 주세요");
            return;
        }
        if (airtel.end_text === "") {
            alert("도착 일정을 확인해 주세요");
            return;
        }
        if (airtel.origin_price < 10000) {
            alert("원가를 확인해 주세요");
            return;
        }
        if (airtel.discount_price > airtel.origin_price || airtel.discount_price < 10000) {
            alert("할인가를 확인해 주세요");
            return;
        }
        if (airtel.order_by < 1) {
            alert("표현 순서를 확인해 주세요");
            return;
        }
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");

        // console.log("set dayitem? ", airtel);
        try {
            const res = await axios.put(`/v1/airtels/list`, airtel, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            if (res.data.code === 0) {
                alert("에어텔 일별 상품정보가 수정되었습니다.");
                update();
                close();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                backlogin();
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    에어텔 일별 상품 수정하기
                    <ModalClosebutton onClick={() => close()}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>일별 상품명</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.title}
                                placeholder="일별 상품 명을 입력해주세요"
                                onChange={titleInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <div className="rowBox">
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>항공사</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.airline}
                                    placeholder="항공사들을 입력해주세요"
                                    onChange={airlineInput}
                                />
                            </InputBox>
                        </BoxContainer>
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>여행 일정</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.airtel_date}
                                    placeholder="여행 일정을 입력해주세요"
                                    onChange={dateInput}
                                />
                            </InputBox>
                        </BoxContainer>
                    </div>
                    <div className="rowBox">
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>출발 일정</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.start_text}
                                    placeholder="여행 출발 일정을 입력해주세요"
                                    onChange={starttxtInput}
                                />
                            </InputBox>
                        </BoxContainer>
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>도착 일정</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.end_text}
                                    placeholder="여행 도착 일정을 입력해주세요"
                                    onChange={endtxtInput}
                                />
                            </InputBox>
                        </BoxContainer>
                    </div>
                    <div className="rowBox">
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>에어텔 등록일</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.search_day}
                                    placeholder="에어텔에 등록될 년월일"
                                    onChange={searchdayInput}
                                />
                            </InputBox>
                        </BoxContainer>
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>결제 만료일</h3>
                            </TextContainer>
                            <InputBox width="250px">
                                <input
                                    type="text"
                                    value={airtel!.last_payment_day}
                                    placeholder="결제 만료일 예정일"
                                    onChange={lastpaydayInput}
                                />
                            </InputBox>
                        </BoxContainer>
                    </div>
                    <div className="rowBox">
                        <h3 className="extxt">* 에어텔에 등록될 년월일을 입력해주세요 (ex. 2023-10-10)</h3>
                        <h3 className="extxt">* 결제 만료 예정일을 입력해주세요 (ex. 2023-10-10)</h3>
                    </div>
                    <div className="rowBox">
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>판매 원가</h3>
                            </TextContainer>
                            <InputBox width="150px">
                                <input
                                    type="number"
                                    value={airtel!.origin_price}
                                    placeholder="금액을 입력해주세요 (숫자만)"
                                    onChange={priceInput}
                                />
                            </InputBox>
                            <h3 className="won">원</h3>
                        </BoxContainer>
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>할인가</h3>
                            </TextContainer>
                            <InputBox width="150px">
                                <input
                                    type="number"
                                    value={airtel!.discount_price}
                                    placeholder="금액을 입력해주세요 (숫자만)"
                                    onChange={discountInput}
                                />
                            </InputBox>
                            <h3 className="won">원</h3>
                        </BoxContainer>
                    </div>
                    <div className="rowBox marginb">
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>표현 순서</h3>
                            </TextContainer>
                            <InputBox width="100px">
                                <input
                                    type="number"
                                    value={airtel!.order_by}
                                    placeholder=""
                                    onChange={orderInput}
                                />
                            </InputBox>
                        </BoxContainer>
                        <BoxContainer set="stretch">
                            <TextContainer>
                                <BiSolidRightArrow
                                    color="#6f7cff"
                                    size={10}
                                />
                                <h3>상태</h3>
                            </TextContainer>
                            <RegisterVisibleBox
                                visible={1}
                                setState={visibleSelect}
                            />
                        </BoxContainer>
                    </div>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        font="#000000"
                        color="#eeeeee"
                        onClick={() => close()}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        font="#ffffff"
                        color="#2d40ff"
                        onClick={setAirtelDayReg}
                    >
                        등록
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
