import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import { ButtonHTMLAttributes } from "react";

const SearchingBtn = styled.button`
    display: flex;
    width: 80px;
    height: 42px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #2d40ff;
    border: 0px;
    cursor: pointer;

    h3 {
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        margin-left: 5px;
    }
`;

export function SearchBtn({ onClick, title }: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <SearchingBtn onClick={onClick}>
            <FiSearch
                size={15}
                color="#ffffff"
            />
            <h3>{title}</h3>
        </SearchingBtn>
    );
}
