import styled from "styled-components";
import { BiSolidRightArrow } from "react-icons/bi";
import { AccountListSelectBox } from "./AccountListSelectBox";
import { atom, useAtom } from "jotai";
import { ButtonHTMLAttributes, ChangeEvent } from "react";
import { FiSearch } from "react-icons/fi";
import { SearchBtn } from "../../common/SearchBtn";

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 820px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 50px 0 0 0;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 80px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    height: 42px;
    input {
        width: 100%;
        line-height: 28px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 18px;
        outline: none;
    }
`;

export const accountSearchAtom = atom("");
export function AccountSearchBox({ onClick }: ButtonHTMLAttributes<HTMLButtonElement>) {
    const [accountsearch, setAccountSearch] = useAtom(accountSearchAtom);

    const accountSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setAccountSearch(e.target.value);
    };

    return (
        <BoxContainer set="space-between">
            <TextContainer>
                <BiSolidRightArrow
                    color="#6f7cff"
                    size={15}
                />
                <h3>계정 검색</h3>
            </TextContainer>
            <AccountListSelectBox />
            <InputBox>
                <input
                    type="text"
                    value={accountsearch}
                    placeholder="검색어를 입력해 주세요"
                    onChange={accountSearchText}
                />
            </InputBox>
            <SearchBtn
                onClick={onClick}
                title="조회"
            />
        </BoxContainer>
    );
}
