import { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
`;

export function SheetContainer({ children }: PropsWithChildren) {
    return <Container>{children}</Container>;
}
