import { useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { AirtelDayItem } from "../components/airtel/AirtelDayItem";
import { AirtelDetailItem } from "../components/airtel/AirtelDetailItem";
import { AirtelCalendarImgItem } from "../components/airtel/AirtelCalendarImgItem";
import { AirtelAllPhoto } from "../components/airtel/AirtelAllPhoto";
import { imgUrl } from "./ProductListView";
import { AirtelDetailInfo } from "../components/airtel/AirtelDetailInfo";
import { AirtelCalendar } from "../components/airtel/AirtelCalendar";
import { calendar } from "../components/airtel/CalendarInterface";
import { AirtelDayListRegister } from "../components/airtel/AirtelDayListRegister";
import { deadlineAtom, selectedAtom } from "../components/airtel/PriceDatePicker";
import { AirtelDayListUpdate } from "../components/airtel/AirtelDayListUpdate";
import { AirtelDayListDetail } from "../components/airtel/AirtelDayListDetail";

const Container = styled.div`
    display: block;
    width: 100%;
    height: 80vh;
    // background-color: #fff111;
    padding-bottom: 5px;
    margin-bottom: 5px;
    overflow: auto;

    .columnBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .menu {
            display: flex;
            width: 95%;
            align-items: center;
            justify-content: space-between;

            .backicon {
                cursor: pointer;
            }
            .title {
                color: #2d40ff;
                font-size: 20px;
                font-weight: 500;
                justify-content: center;
            }
            .space {
                width: 10px;
            }
        }
    }

    .rowBox {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 50px 0;

        .column {
            display: flex;
            flex-direction: column;
            margin-right: 50px;

            .imgBox {
                width: 550px;
                height: 300px;
                border-radius: 10px;
                background-color: #eeeeee;
            }
            .row {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
            }
            .mimgBox {
                width: 270px;
                height: 170px;
                border-radius: 10px;
                background-color: #eeeeee;
            }
            .allphoto {
                display: flex;
                height: 40px;
                font-size: 13px;
                font-weight: 500;
                color: #161a3f;
                background-color: #eeeeee;
                border-radius: 30px;
                border: 1px solid #161a3f;
                cursor: pointer;
                padding: 3px 10px 3px 10px;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }
            .modify {
                height: 40px;
                font-size: 13px;
                font-weight: 500;
                color: #ffffff;
                background-color: #2d40ff;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                padding: 3px 10px 3px 10px;
            }
        }
    }
`;

const StickRowBar = styled.div`
    width: 95%;
    height: 1px;
    background-color: #eeeeee;
    padding: 0px 10px 0px 10px;
    margin-bottom: 15px;
`;

const basic_cal = {
    jan_1: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    feb_2: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d"}',
    mar_3: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    apr_4: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    may_5: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    jun_6: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    jul_7: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    aug_8: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    sep_9: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    oct_10: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    nov_11: '{"1":"111-n","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    dec_12: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
};

const basic_data = {
    airtel_banner_seq: 0,
    main_img: imgUrl,
    title: "에어텔 상품명",
    subtitle: "에어텔 부제목",
    airline: "항공사",
    airtel_date: "3박4일",
    origin_price: 0,
    discount_price: 0,
    img_list: [
        {
            seq: 0,
            airtel_banner_seq: 0,
            image_url: imgUrl,
            order_by: 1,
        },
    ],
};

export function AirtelDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const selected = useAtomValue(selectedAtom);
    const deadline = useAtomValue(deadlineAtom);
    const [refreshSite, setRefreshSite] = useState(false);
    const [isShow, setShow] = useState(false);
    const [check, setCheck] = useState("all");
    const [dayList, setDayList] = useState<any>([]);
    const [detail, setDetail] = useState<any>(basic_data);
    const [calseq, setCalseq] = useState<number>(0);
    const [calbs, setCalbs] = useState<number>(0);
    const [calendar, setCalendar] = useState<calendar>(basic_cal);
    const [apUpdate, setApUpdate] = useState(0);
    const [aiUpdate, setAiUpdate] = useState(false);
    const [cdUpdate, setCdUpdate] = useState(false);
    const [dlUpdate, setDlUpdate] = useState(false);
    const [daylistReg, setDaylistReg] = useState(false);
    const [dlDetail, setDlDetail] = useState(false);
    const [hflag, setHflag] = useState(true);
    const [dlinfo, setDlinfo] = useState<any>({});
    const scrollRef = useRef<HTMLDivElement | null>(null);
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("상품상세");
    const product = { ...location.state };
    // console.log("visible?? ", product.visible);

    const onScrollClick = (v: number) => {
        scrollRef.current?.scrollTo(0, v);
    };

    const backloginview = () => {
        setHflag(true);
        setApUpdate(0);
        setAiUpdate(false);
        setCdUpdate(false);
        setDaylistReg(false);
        setDlUpdate(false);
        setDlDetail(false);
        navigate(`/adminpage/login`);
    };

    const backHandle = () => {
        navigate(`/adminpage/productlist`, { state: { to: `airtel` } });
    };

    const setPhotoModal = (v: number) => {
        setHflag(false);
        setApUpdate(v);
    };

    const setCalModal = (v: boolean) => {
        setHflag(false);
        setCdUpdate(v);
    };

    const setDaylistModal = (v: boolean) => {
        setHflag(false);
        setDaylistReg(v);
    };

    const dayListModal = (item: any, a: boolean) => {
        const data = {
            seq: item.airtel_seq,
            airtel_banner_seq: item.airtel_banner_seq,
            title: item.title,
            airline: item.airline,
            airtel_date: item.airtel_date,
            search_day: selected,
            last_payment_day: item.las_payment_day,
            start_text: item.start_text,
            end_text: item.end_text,
            origin_price: item.origin_price,
            discount_price: item.discount_price,
            visible: item.visible,
            order_by: item.order_by,
        };
        setDlinfo(data);
        setHflag(false);
        a ? setDlUpdate(true) : setDlDetail(true);
    };

    const getDetail = async () => {
        const month = format(new Date(), "M");
        const res = await axios.get(
            `/product/airtel/detail?client_id=99&airtel_banner_seq=${product.airtel_banner_seq}&month=${month}`
        );

        // console.log(res.data.data);
        setDetail(res.data.data);
    };

    const getCalendar = async () => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/v1/airtels/calendar?airtel_banner_seq=${product.airtel_banner_seq}`, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });

            // console.log("calendar? ", res.data.data);
            if (res.data.code === 0) {
                const val = {
                    jan_1: res.data.data.jan_1,
                    feb_2: res.data.data.feb_2,
                    mar_3: res.data.data.mar_3,
                    apr_4: res.data.data.apr_4,
                    may_5: res.data.data.may_5,
                    jun_6: res.data.data.jun_6,
                    jul_7: res.data.data.jul_7,
                    aug_8: res.data.data.aug_8,
                    sep_9: res.data.data.sep_9,
                    oct_10: res.data.data.oct_10,
                    nov_11: res.data.data.nov_11,
                    dec_12: res.data.data.dec_12,
                };
                setCalseq(res.data.data.seq);
                setCalbs(res.data.data.airtel_banner_seq);
                setCalendar(val);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const getList = async () => {
        const res = await axios.get(
            `/product/airtel/daylist?client_id=99&airtel_banner_seq=${product.airtel_banner_seq}&calendar_date=${selected}`
        );
        // console.log("checck???? ", check, res.data.data);

        const array = check === "all" ? res.data.data : res.data.data.filter((item: any) => item.airtel_date === check);
        setDayList(array.sort((a: any, b: any) => a.order_by - b.order_by));
    };

    useEffect(() => {
        scrollRef.current?.focus();
    }, [apUpdate]);

    useEffect(() => {
        getDetail();
        getCalendar();
        getList();
    }, [refreshSite]);

    useEffect(() => {
        if (new Date(selected) < new Date()) return;
        getList();
    }, [selected, check]);

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            {apUpdate !== 0 && (
                <AirtelAllPhoto
                    open={apUpdate}
                    banner_seq={product.airtel_banner_seq}
                    main_img={detail.main_img}
                    list={detail.img_list}
                    close={() => setApUpdate(0)}
                    update={() => setRefreshSite(!refreshSite)}
                    backlogin={backloginview}
                />
            )}
            {aiUpdate && (
                <AirtelDetailInfo
                    open={aiUpdate}
                    detail={detail}
                    order_by={product.order_by}
                    visible={Number(product.visible)}
                    close={() => setAiUpdate(false)}
                    update={() => setRefreshSite(!refreshSite)}
                    backlogin={backloginview}
                />
            )}
            {cdUpdate && (
                <AirtelCalendar
                    open={cdUpdate}
                    seq={calseq}
                    banner_seq={calbs}
                    calendar={calendar}
                    update={() => setRefreshSite(!refreshSite)}
                    close={() => setCdUpdate(false)}
                    backlogin={backloginview}
                />
            )}
            {daylistReg && (
                <AirtelDayListRegister
                    open={daylistReg}
                    banner_seq={product.airtel_banner_seq}
                    date={selected}
                    update={() => setRefreshSite(!refreshSite)}
                    close={() => setDaylistReg(false)}
                    backlogin={() => backloginview}
                />
            )}
            {dlUpdate && (
                <AirtelDayListUpdate
                    open={dlUpdate}
                    basic_data={dlinfo}
                    update={() => setRefreshSite(!refreshSite)}
                    close={() => setDlUpdate(false)}
                    backlogin={() => backloginview}
                />
            )}
            {dlDetail && (
                <AirtelDayListDetail
                    open={dlDetail}
                    basic_data={dlinfo}
                    close={() => setDlDetail(false)}
                    backlogin={() => backloginview}
                />
            )}
            <Container ref={scrollRef}>
                <div className="columnBox">
                    <div className="menu">
                        <RiArrowGoBackLine
                            className="backicon"
                            size={30}
                            color={"#2d40ff"}
                            onClick={backHandle}
                        />
                        <h3 className="title">상품 미리보기</h3>
                        <div className="space" />
                    </div>
                    <StickRowBar />
                    <AirtelDetailItem
                        product={detail}
                        order_by={product.order_by}
                        visible={Number(product.visible)}
                        openModal={() => setAiUpdate(true)}
                    />
                    <AirtelCalendarImgItem
                        photoModal={setPhotoModal}
                        calModal={setCalModal}
                        hflag={hflag}
                        main_img={detail.main_img}
                        calendar={calendar}
                        img_list={detail.img_list}
                        scroll={onScrollClick}
                    />
                    <AirtelDayItem
                        check={check}
                        airtel_date={detail.airtel_date || ""}
                        deadline={deadline}
                        dayList={dayList}
                        setCheck={setCheck}
                        openModal={setDaylistModal}
                        daylistModal={dayListModal}
                    />
                </div>
            </Container>
        </BaseContainer>
    );
}
