import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NoneBackSpinner from "../../../common/NoneBackSpinner";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../../common/PagingBox";
import { SheetContainer } from "../../../common/SheetContainer";
import { SheetSortation } from "../../../common/SheetSortation";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { dateParse } from "../../../product/view/CounsellingListView";
import { HpRsvListSearchBox } from "./HpReservSearchBox";
import { HpExcelDownLoad } from "./HpExceldownload";
import { HpDateParse } from "../common/ReservUtil";
import { HpReservDetailModal } from "./HpDetailModal";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 530px;
    align-items: center;
    // justify-content: center;
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 13px;

    .green {
        color: #20a656;
    }
    .blue {
        color: #2d40ff;
    }
    .yellow {
        color: #e7a11a;
    }
    .red {
        color: #ee4c4c;
    }
    .gray {
        color: #838c97;
    }
`;

const Listbase = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        // background-color: #fcfcfc;
        font-weight: 700;
        font-size: 20px;
    }
`;

export const HotelReservList = () => {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isShow, setShow] = useState(false);
    const [allcnt, setAll] = useState<number>();
    const [searchState, setSearchState] = useState<string>("RESUME");
    const [isDetail, setDetail] = useState(false);
    const [reservation, setReservation] = useState<any>();
    const [idata, setIdata] = useState({
        txt: "",
        str: "",
        end: "",
    });

    function closeDetailModal() {
        setDetail(false);
    }
    function refreshCloseModal() {
        setDetail(false);
        getlist("", "", searchState, "");
    }

    function openDetailModal(item: any) {
        setReservation(item);
        setDetail(true);
    }

    const getlist = async (str: string, end: string, state: string, value: string) => {
        setList([]);
        setShow(true);
        setIdata({ str: str, end: end, txt: value });

        try {
            const res = await axios.get(`/hp/bookings?`, {
                params: {
                    booking_state: state,
                    value: value,
                    checkin: str,
                    checkout: end,
                    size: 10,
                    page: nowpage,
                },
            });
            // console.log("hotel reserv? ", res.data);
            setShow(false);
            if (res.data.code === "WFL000") {
                // 총 페이지 수 계산
                setAll(res.data.data.total_cnt);
                setaTotalPage(Math.ceil(res.data.data.total_cnt / 10));
                // 리스트 저장
                setList(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 에러 처리
            // console.log(e);
            setShow(false);
            alert(e.response.data.message);
        }
    };

    useEffect(() => {
        getlist("", "", searchState, "");
    }, [nowpage]);

    return (
        <Container>
            {isDetail && (
                <HpReservDetailModal
                    open={isDetail}
                    item={reservation}
                    close={closeDetailModal}
                    refresh={refreshCloseModal}
                />
            )}
            <HpRsvListSearchBox
                title="예약내역 검색"
                sState={searchState}
                setSstate={setSearchState}
                searching={getlist}
            />
            <HpExcelDownLoad
                cnt={allcnt}
                txt={idata.txt}
                state={searchState}
            />
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"50px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"210px"}
                        text={"호텔"}
                    />
                    <SheetSortation
                        width={"250px"}
                        text={"호텔 룸"}
                    />
                    <SheetSortation
                        width={"100px"}
                        text={"예약번호"}
                    />
                    <SheetSortation
                        width={"170px"}
                        text={"생성일"}
                    />
                    <SheetSortation
                        width={"100px"}
                        text={"예약상태"}
                    />
                    <SheetSortation
                        width={"140px"}
                        text={"호텔가격"}
                    />
                    <SheetSortation
                        width={"110px"}
                        text={"취소기한"}
                    />
                    <SheetSortation
                        width={"100px"}
                        text={"예약자"}
                    />
                    <SheetSortation
                        width={"120px"}
                        text={"연락처"}
                    />
                    <SheetSortation
                        width={"170px"}
                        text={"이메일"}
                    />
                </SheetContainer>
                {isShow ? (
                    <NoneBackSpinner />
                ) : (
                    list.map((item: any, i: number) => {
                        return (
                            <Listbase
                                key={i}
                                onClick={() => openDetailModal(item)}
                            >
                                <Content width="50px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                                <Content width="210px">{item.hotel_name}</Content>
                                <Content width="250px">{item.room_name}</Content>
                                <Content width="100px">{item.booking_id}</Content>
                                <Content width="170px">{HpDateParse(item.insert_date)}</Content>
                                <Content width="100px">{item.booking_state}</Content>
                                <Content width="140px">{priceParseComma(item.total_amount.toString())}원</Content>
                                <Content width="110px">{dateParse(item.refundable_date)}</Content>
                                <Content width="100px">{item.booker_name}</Content>
                                <Content width="120px">{item.booker_phone}</Content>
                                <Content width="170px">{item.booker_email}</Content>
                            </Listbase>
                        );
                    })
                )}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </Container>
    );
};
