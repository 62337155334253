import { BiSolidRightArrow } from "react-icons/bi";
import styled from "styled-components";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";

const BoxContainer = styled.div<{ set: string; mw: string; imw: string }>`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.mw};
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
    // background-color: #fff111;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: ${(props) => props.imw}px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    type: string;
    title: string;
    value: string;
    mw: number;
}

export const ReadOnlyIBox = (props: props) => {
    const { type, title, value, mw } = props;

    return (
        <BoxContainer
            set="stretch"
            mw={mw + "px"}
            imw={mw - 100 + "px"}
        >
            <div className="text">
                <BiSolidRightArrow
                    color="#6f7cff"
                    size={10}
                />
                <h3>{title}</h3>
            </div>
            <input
                type="text"
                value={type === "price" ? priceParseComma(value) : value}
                readOnly
            />
            {type === "price" && <h3 className="won">원</h3>}
        </BoxContainer>
    );
};
