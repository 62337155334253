import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import { styled } from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { VisibleSelectBox } from "../../../common/VisibleSelectBox";
import { imgUrl } from "../../view/ProductListView";
import ThumbnailImgUploader from "../common/ThumbnailAdd";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../common/updateimgInterface";

const Container = styled.div`
    position: fixed;
    // background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 200px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-top: 200px;
    width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 550px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ color: string; font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => props.font};
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const Box2Container = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 500px;
    height: 150px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
`;

const ImgAddContainer = styled.div`
    display: flex;
    width: 300px;
    height: 150px;
    align-items: center;
    // justify-content: start;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface airtel_info {
    title: string;
    main_img: string;
    subtitle: string;
    airline: string;
    airtel_date: string;
    origin_price: number;
    discount_price: number;
    visible: number;
    order_by: number;
}

interface props {
    open: boolean;
    detail: any;
    order_by: number;
    visible: number;
    close: () => void;
    update: () => void;
    backlogin: () => void;
}

export const AirtelDetailInfo = (props: props) => {
    const { open, detail, order_by, visible, close, update, backlogin } = props;
    const [isShow, setShow] = useState(false);
    const [file, setFile] = useState<FileList | null>();
    const [imgArr, setImgArr] = useState("");
    const [s3flag, setS3flag] = useState(false);

    let basic_info: airtel_info = {
        title: detail.title,
        main_img: detail.main_img,
        subtitle: detail.subtitle,
        airline: detail.airline,
        airtel_date: detail.airtel_date,
        origin_price: detail.origin_price,
        discount_price: detail.discount_price,
        visible: visible,
        order_by: order_by,
    };
    const [airtel, setAirtel] = useState<airtel_info>(basic_info);

    const outHandle = (v: boolean) => {
        let arr = [];
        arr.push(imgArr);
        removeBloburl(arr);
        if (v) {
            backlogin();
        } else {
            close();
        }
    };

    const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (airtel!.main_img !== "") {
                onImageRemove();
            }
            setFile(event.target.files);
            airtel!.main_img = URL.createObjectURL(event.target.files![0]);
            setAirtel(airtel);
        }
    };
    const onImageRemove = (): void => {
        if (airtel!.main_img.includes("blob")) {
            URL.revokeObjectURL(airtel!.main_img);
        }
        const data: airtel_info = {
            title: airtel!.title,
            main_img: imgUrl,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data); // 렌더링 이미지 초기화
        setFile(null);
    };
    const visibleSelect = (v: number) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: v,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const titleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: e.target.value,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const subtitleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: e.target.value,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const airlineInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: e.target.value,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const dateInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: e.target.value,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const priceInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: e.target.valueAsNumber,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const discountInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: e.target.valueAsNumber,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };
        setAirtel(data);
    };
    const orderInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: e.target.valueAsNumber,
        };
        setAirtel(data);
    };

    useEffect(() => {
        if (s3flag) {
            setAirtelInfo();
        }
    }, [s3flag]);

    const setImgS3 = async () => {
        setShow(true);
        setS3flag(false);
        if (airtel.main_img.includes("blob")) {
            getS3imgContent(1)?.then(({ data }) => {
                const list = data.data.list;
                setS3imgContent(list[0].upload_url, list[0].result_url, file![0]);
                URL.revokeObjectURL(airtel.main_img);
                setImgArr(list[0].result_url);
                setShow(false);
                setS3flag(true);
            });
        } else {
            setImgArr(airtel.main_img);
            setShow(false);
            setS3flag(true);
        }
    };

    const setAirtelInfo = async () => {
        setShow(true);
        setS3flag(false);
        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            airtel_banner_seq: detail.airtel_banner_seq,
            title: airtel!.title,
            main_img: imgArr,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
        };

        try {
            const res = await axios.put(`/v1/airtels`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            if (res.data.code === 0) {
                alert("에어텔 정보 수정이 완료되었습니다.");
                setImgArr("");
                update();
                outHandle(false);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                outHandle(true);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    에어텔 정보 수정
                    <ModalClosebutton onClick={() => outHandle(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상품 명</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.title}
                                placeholder="상품 명을 입력해주세요"
                                onChange={titleInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>태그 문구</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.subtitle}
                                placeholder="태그 문구를 입력해주세요"
                                onChange={subtitleInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>항공사</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.airline}
                                placeholder="항공사들을 입력해주세요"
                                onChange={airlineInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>여행 일정</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.airtel_date}
                                placeholder="여행 일정을 입력해주세요"
                                onChange={dateInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>판매 원가</h3>
                        </TextContainer>
                        <InputBox width="150px">
                            <input
                                type="number"
                                value={airtel!.origin_price}
                                placeholder="금액을 입력해주세요 (숫자만)"
                                onChange={priceInput}
                            />
                        </InputBox>
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>할인가</h3>
                        </TextContainer>
                        <InputBox width="150px">
                            <input
                                type="number"
                                value={airtel!.discount_price}
                                placeholder="금액을 입력해주세요 (숫자만)"
                                onChange={discountInput}
                            />
                        </InputBox>
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>표현 순서</h3>
                        </TextContainer>
                        <InputBox width="100px">
                            <input
                                type="number"
                                value={airtel!.order_by}
                                placeholder=""
                                onChange={orderInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <Box2Container set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>썸네일 이미지</h3>
                        </TextContainer>
                        <ImgAddContainer>
                            <ThumbnailImgUploader
                                fileURL={airtel!.main_img}
                                flag={true}
                                uploadImg={onImageChange}
                                removeImg={onImageRemove}
                            />
                        </ImgAddContainer>
                    </Box2Container>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상태</h3>
                        </TextContainer>
                        <VisibleSelectBox
                            visible={visible}
                            sort={"airtel"}
                            id={detail.airtel_banner_seq}
                            setState={visibleSelect}
                            disable={false}
                        />
                    </BoxContainer>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        font="#000000"
                        color="#eeeeee"
                        onClick={() => outHandle(false)}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        font="#ffffff"
                        color="#2d40ff"
                        onClick={setImgS3}
                    >
                        수정
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
