import { useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";

const SImg = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover; // 비율 조정
    border: 1px solid #123437;
    border-radius: 5px;
    margin-bottom: 5px;
`;
const SImgInput = styled.input`
    display: none;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 150px;
    padding-left: 10px;
    align-items: start;
    justify-content: start;

    button {
        padding: 10px 10px 10px 10px;
        margin-bottom: 10px;
        font-size: 13px;
        cursor: pointer;
    }
`;

interface props {
    fileURL: string;
    flag: boolean;
    uploadImg: (e: React.ChangeEvent<HTMLInputElement>) => void;
    removeImg: () => void;
}

const ThumbnailImgUploader = (props: props) => {
    const { fileURL, flag, uploadImg, removeImg } = props; // props로 함수 받기
    const imgUploadInput = useRef<HTMLInputElement | null>(null);

    return (
        <>
            <SImg
                src={fileURL}
                alt=""
            ></SImg>
            <SImgInput
                type="file"
                id="img"
                accept="image/*"
                required
                ref={imgUploadInput}
                onChange={uploadImg}
            />
            {flag && (
                <BtnContainer>
                    <button
                        type="button"
                        onClick={(event) => {
                            event.preventDefault();
                            if (imgUploadInput.current) {
                                imgUploadInput.current.click();
                            }
                        }}
                    >
                        이미지 첨부
                    </button>

                    <button
                        type="button"
                        onClick={removeImg}
                    >
                        이미지 제거
                    </button>
                </BtnContainer>
            )}
        </>
    );
};

export default ThumbnailImgUploader;
