import styled from "styled-components";
import { GrTextAlignLeft } from "react-icons/gr";
import { AiOutlineDropbox } from "react-icons/ai";

const RadioBox = styled.div`
    width: 100%;
    max-width: 1100px;
    border-bottom: 2px solid #ffffff;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #eeeeee;
        // background: #fff111;
    }

    .addItem {
        height: 40px;
        border: 0px;
        border-radius: 5px;
        font-size: 13px;
        padding: 3px 10px;
        font-weight: 500px;
        margin-right: 30px;
        color: #ffffff;
        background-color: #2d40ff;
        cursor: pointer;
    }

    .radioContainer {
        display: flex;
        // background: #fcfcfc;
        margin: 5px 0;
        padding: 24px 14px;
    }
    h3 {
        margin: 0;
        padding: 0 16px;
        color: #161a3f;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    label {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }
    input {
        margin: 0;
        width: 18px;
        height: 18px;
    }
    .select {
        display: flex;
        gap: 16px;

        position: relative;
        padding: 0 16px;

        span {
            color: #161a3f;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.56px;
        }
        .stick {
            width: 2px;
            height: 18px;
            background-color: #fcfcfc;
        }
    }
    // .select::after {
    //     content: "";
    //     position: relative;
    //     top: 5px;
    //     left: 0;
    //     width: 1px;
    //     height: 16px;
    //     background: #e4e5e9;
    // }

    @media screen and (max-width: 480px) {
        padding: 0 16px;
        .container {
            padding: 20px 16px;
        }
        h3 {
            font-size: 14px;
            padding: 0 10px;
        }

        input {
            width: 16px;
            height: 16px;
        }
        .select {
            font-size: 13px;
            padding: 0 10px;
            gap: 12px;
        }
    }
`;

const NoProduct = styled.div`
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    p {
        color: #2d40ff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.56px;
    }

    @media screen and (max-width: 768px) {
        padding: 0 16px;
    }
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 10px;
    width: 100%;
    max-width: 1100px;

    .rowContainer {
        display: flex;
        height: 110px;
        overflow: hidden;

        .columnBtn {
            display: flex;
            flex-direction: column;
            width: 140px;
            background-color: #eeeeee;
            justify-content: center;

            .btn {
                height: 50px;
                border-radius: 5px;
                border: none;
                margin-bottom: 3px;
                font-size: 15px;
                font-weight: 700;
                color: #eeeeee;
                cursor: pointer;
            }
            .update {
                background-color: #6bc3de;
            }
            .detail {
                background-color: #b0cd92;
            }
        }
    }

    a {
        text-decoration: none;
    }

    @media screen and (max-width: 480px) {
        padding: 0 16px;
    }
`;

const Card = styled.div`
    display: flex;
    padding: 20px 30px;
    border: 1px solid #eeeff3;
    line-height: 25px;
    // background-color: #fff111;
    width: 100%;
    max-width: 900px;

    .info {
        // background-color: #eeeeee;
        width: 100%;
        max-width: 700px;
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            padding: 0;
            margin: -5px 0;
        }
        li {
            list-style-position: inside;
            color: #5c5f79;
            font-size: 12px;
            letter-spacing: -0.48px;
            span:first-of-type {
                margin-left: -6px;
                margin-right: 4px;
                color: #161a3f;
            }
        }
        li::marker {
            color: #161a3f;
            font-size: 12px;
        }
    }
    .title {
        margin: 0;
        color: #161a3f;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
        margin-bottom: 10px;

        span:first-of-type {
            border-radius: 3px;
            background: #00c3d1;
            padding: 4px 10px;
            margin-right: 8px;

            color: #fff;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.56px;
        }
    }
    .price {
        text-align: center;
        p {
            margin: 0;
            color: #161a3f;
            font-size: 15px;
            letter-spacing: -0.64px;
            text-decoration: line-through;
        }
        span {
            color: #e20000;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.56px;
        }
        span: first-of-type {
            font-size: 14px;
            padding-right: 10px;
        }
        .no_dis {
            visibility: hidden;
        }
        .no_discount {
            color: #161a3f;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 20px 16px;
        flex-direction: column;
        align-items: end;
        gap: 14px;
        .info {
            ul {
                gap: 8px 10px;
            }
        }
    }
`;

interface props {
    check: string;
    airtel_date: string;
    deadline: boolean;
    dayList: Array<any>;
    setCheck: (val: string) => void;
    openModal: (v: boolean) => void;
    daylistModal: (v: any, a: boolean) => void;
}

export const AirtelDayItem = (props: props) => {
    const { check, airtel_date, deadline, dayList, setCheck, openModal, daylistModal } = props;
    const partner_lv = Number(sessionStorage.getItem("partnerlv"));

    return (
        <>
            <RadioBox>
                <div className="container">
                    <div className="radioContainer">
                        <h3>여행일정 선택</h3>
                        <div className="select">
                            <div className="stick" />
                            <label>
                                <input
                                    type="radio"
                                    name="nights"
                                    value="all"
                                    checked={check === "all"}
                                    onChange={(e: any) => {
                                        setCheck(e.target.value);
                                    }}
                                />
                                <span>전체선택</span>
                            </label>
                            {airtel_date.split(",").map((item: any, i: number) => (
                                <label key={i + item}>
                                    <input
                                        type="radio"
                                        name="nights"
                                        value={item}
                                        checked={check === item}
                                        onChange={(e: any) => {
                                            setCheck(e.target.value);
                                        }}
                                    />
                                    <span>{item}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    {!deadline && partner_lv > 1 && (
                        <button
                            className="addItem"
                            onClick={() => openModal(true)}
                        >
                            일별 상품 등록
                        </button>
                    )}
                </div>
            </RadioBox>

            {deadline ? (
                <NoProduct>
                    <AiOutlineDropbox
                        size={50}
                        color="#2d40ff"
                    />
                    <p>해당날짜는 마감설정 되어 있습니다.</p>
                    <p>캘린더를 설정해 주세요.</p>
                </NoProduct>
            ) : (
                <CardContainer>
                    {dayList.length > 0 ? (
                        dayList.map((item: any, i: number) => (
                            <div className="rowContainer">
                                <Card>
                                    <div className="info">
                                        <h3 className="title">
                                            <span>인기상품</span>
                                            <span>{item.title}</span>
                                        </h3>
                                        <ul>
                                            <li>
                                                <span>이용항공</span>
                                                <span>{item.airline}</span>
                                            </li>
                                            <li>
                                                <span>여행일정</span>
                                                <span>{item.airtel_date}</span>
                                            </li>
                                            <li>
                                                <span>판매기간</span>
                                                <span>출발 10일전</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <span>인천출발</span>
                                                <span>{item.start_text}</span>
                                            </li>
                                            <li>
                                                <span>인천도착</span>
                                                <span>{item.end_text}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {item.discount_price > 0 ? (
                                        <div className="price">
                                            <p>{item.origin_price.toLocaleString()}원 ~</p>
                                            <div>
                                                <span>할인가</span>
                                                <span>{item.discount_price.toLocaleString()}원 ~</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="price">
                                            <p className="no_dis">{item.origin_price} ~</p>
                                            <div>
                                                <span className="no_discount">정상가</span>
                                                <span className="no_discount">
                                                    {item.origin_price.toLocaleString()}원 ~
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Card>
                                <div className="columnBtn">
                                    {partner_lv > 1 && (
                                        <button
                                            className="btn update"
                                            onClick={() => daylistModal(item, true)}
                                        >
                                            정보 수정
                                        </button>
                                    )}
                                    <button
                                        className="btn detail"
                                        onClick={() => daylistModal(item, false)}
                                    >
                                        상세이미지
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <NoProduct>
                            <GrTextAlignLeft
                                size={50}
                                color="#2d40ff"
                            />
                            <p>선택하신 날짜에 이용 가능한 상품이 없습니다.</p>
                            <p>상품을 등록해 주세요.</p>
                        </NoProduct>
                    )}
                </CardContainer>
            )}
        </>
    );
};
