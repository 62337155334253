import axios from "axios";
import { useSetAtom } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import LoadingSpinner from "../../common/LoadingSpinner";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { VisibleSelectBox } from "../../common/VisibleSelectBox";
import DetailImgUploader, { Box } from "../components/common/DetailimgAdd";
import { DetailImage } from "../components/common/ProductDetail_Image";
import { TYPE_PRODUCT_DETAIL } from "../components/common/TabMenu";
import ThumbnailImgUploader from "../components/common/ThumbnailAdd";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../components/common/updateimgInterface";
import { HotdealDetailImage } from "../components/hotdeal/Hodtdealupdateimg";
import { HotdealPolicy } from "../components/hotdeal/HotdealPolicyList";
import { imgUrl } from "./ProductListView";

const BlockArea = styled.div`
    display: flex;
    width: 90%;
    height: 73vh;

    .stick {
        width: 2px;
        height: 71vh;
        background-color: #eeeeee;
        margin-top: 20px;
        margin-right: 10px;
    }
`;

const Container = styled.div<{ wp: string }>`
    display: block;
    overflow: auto;
    width: ${(props) => props.wp};
    height: 68vh;
    // background-color: #ff22e1;
    padding: 10px 0px;
    margin-top: 20px;

    .rowBox {
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;

        .name {
            width: 100%;
            max-width: 90px;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
        }

        .content {
            height: 35px;
            outline: none;
            padding: 0px 10px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid #eeeeee;
            font-size: 15px;
        }

        .size350 {
            width: 100%;
            max-width: 350px;
        }

        .size90 {
            width: 100%;
            max-width: 90px;
            margin-right: 20px;
        }

        .margin50 {
            margin-right: 75px;
        }
    }

    .imgrowBox {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 20px 0;
        // background-color: #eeeeee;

        .name {
            width: 100%;
            max-width: 90px;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
        }

        .tacontent {
            height: 200px;
            outline: none;
            padding: 10px 10px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid #eeeeee;
            font-size: 15px;
        }

        .size350 {
            width: 100%;
            max-width: 350px;
        }
    }

    .rowStick {
        width: 100%;
        height: 2px;
        margin: 10px 0px;
        background-color: #eeeeee;
    }
`;

const ImgAddContainer = styled.div`
    display: flex;
    width: 300px;
    height: 150px;
    align-items: center;
    // justify-content: start;
`;

const BtnContainer = styled.div`
    display: flex;
    width: 90%;
    height: 40px;
    justify-content: end;
    margin-top: 10px;
    // background-color: #fff111;
`;

const Btn = styled.button<{ type: boolean }>`
    display: flex;
    width: 80px;
    height: 40px;
    cursor: pointer;
    background-color: ${(props) => (props.type ? "#dcdeeb" : "#2d40ff")};
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: ${(props) => (props.type ? "#707288" : "#ffffff")};
    font-weight: 500;
    margin-left: 10px;
`;

interface image_list {
    n: string;
    w: string;
    h: string;
    img: string;
    file: any;
}

interface policy {
    txt: string;
    link: string;
}

export const totalPrice = (item: any) => {
    if (item.payment_cnt === 1) {
        return item.balance_amount;
    } else if (item.payment_cnt === 2) {
        return item.cnt1_price + item.balance_amount;
    } else if (item.payment_cnt === 3) {
        return item.cnt1_price + item.cnt2_price + item.balance_amount;
    } else {
        return 0;
    }
};

export function HotdealRegister() {
    const navigate = useNavigate();
    const location = useLocation();
    const page = { ...location.state };
    const [isShow, setShow] = useState(false);
    const [item, setItem] = useState(
        page.type === "reg"
            ? {
                  max_person: 0,
                  product_name: "",
                  product_banner_image: imgUrl,
                  product_image_list: JSON.stringify([]),
                  product_policy_list: JSON.stringify([{ txt: "", link: "https://" }]),
                  product_etc: "",
                  product_order: 0,
                  product_payment_yn: "Y",
                  is_exclude: "N",
                  is_vat: 0,
                  product_d_day: "2000-00-00 00:00:00",
                  product_sales_day: "2000-00-00 14:00:00",
                  middle_etc: "",
                  last_etc: "",
                  payment_cnt: 0,
                  cnt1_price: 0,
                  cnt2_price: 0,
                  balance_amount: 0,
                  product_price: 0,
              }
            : JSON.parse(page.item)
    );
    const [file, setFile] = useState<FileList | null>();
    const [thumbnail, setThumbnail] = useState<string>(item.product_banner_image);
    const [detailImg, setDetailImg] = useState<Array<Box>>([]);
    const [policy, setPolicy] = useState<Array<policy>>([]);
    const [s3flag, setS3flag] = useState(0);
    const [mainImg, setMainimg] = useState("");
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("상품등록");
    useEffect(() => {
        const data = JSON.parse(item.product_image_list);
        // console.log(data);
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            arr.push({ image_url: data[i].img, file: null, ratio: data[i].h, order_by: data[i].n });
        }
        setDetailImg(arr);
        setPolicy(JSON.parse(item.product_policy_list));
    }, []);

    if (Number(sessionStorage.getItem("partnerlv")) < 2) {
        alert("권한이 맞지 않습니다.");
        navigate(`/adminpage/productlist`, { state: { to: `hotdeal` } });
    }

    let product = {
        max_person: item.max_person,
        product_name: item.product_name,
        product_etc: item.product_etc,
        product_order: item.product_order,
        product_payment_yn: item.product_payment_yn,
        is_exclude: item.is_exclude,
        is_vat: item.is_vat,
        product_d_day: item.product_d_day,
        product_sales_day: item.product_sales_day,
        middle_etc: item.middle_etc,
        last_etc: item.last_etc,
        payment_cnt: item.payment_cnt,
        cnt1_price: item.cnt1_price,
        cnt2_price: item.cnt2_price,
        balance_amount: item.balance_amount,
        product_price: item.product_price,
    };

    const regHotdealImg = (list: Array<Box>) => {
        setDetailImg(list);
    };

    const setPolicyList = (list: Array<policy>) => {
        setPolicy(list);
    };

    const setPayCnt = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.valueAsNumber;
        if (val > 3) {
            alert("전체 결제 횟수는 최대 3회까지만 가능합니다.");
            return;
        }
        product.payment_cnt = val;
        setItem(product);
    };
    const setPname = (e: ChangeEvent<HTMLInputElement>) => {
        product.product_name = e.target.value;
        setItem(product);
    };
    const setMaxperson = (e: ChangeEvent<HTMLInputElement>) => {
        product.max_person = e.target.valueAsNumber;
        setItem(product);
    };
    const setCnt1 = (e: ChangeEvent<HTMLInputElement>) => {
        product.cnt1_price = e.target.valueAsNumber || 0;
        setItem(product);
    };
    const setCnt2 = (e: ChangeEvent<HTMLInputElement>) => {
        product.cnt2_price = e.target.valueAsNumber || 0;
        setItem(product);
    };
    const setBamount = (e: ChangeEvent<HTMLInputElement>) => {
        product.balance_amount = e.target.valueAsNumber || 0;
        setItem(product);
    };
    const setDday = (e: ChangeEvent<HTMLInputElement>) => {
        product.product_d_day = e.target.value;
        setItem(product);
    };
    const setSales = (e: ChangeEvent<HTMLInputElement>) => {
        product.product_sales_day = e.target.value;
        setItem(product);
    };
    const setOrder = (e: ChangeEvent<HTMLInputElement>) => {
        product.product_order = e.target.valueAsNumber;
        setItem(product);
    };
    const setPE = (e: ChangeEvent<HTMLTextAreaElement>) => {
        product.product_etc = e.target.value;
        setItem(product);
    };
    const setME = (e: ChangeEvent<HTMLTextAreaElement>) => {
        product.middle_etc = e.target.value;
        setItem(product);
    };
    const setLE = (e: ChangeEvent<HTMLTextAreaElement>) => {
        product.last_etc = e.target.value;
        setItem(product);
    };
    const setVisibleItem = (v: number) => {
        product.is_exclude = v === 0 ? "Y" : "N";
        setItem(product);
    };
    const setVisiblePayBtn = (v: number) => {
        product.product_payment_yn = v === 1 ? "Y" : "N";
        setItem(product);
    };
    const setVat = (e: ChangeEvent<HTMLInputElement>) => {
        product.is_vat = e.target.valueAsNumber;
        setItem(product);
    };
    const onImageUpdate = (seq: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (event.target.files!.length > 1) {
                alert("이미지는 한장만 선택 할 수 있습니다.");
                return;
            }

            if (detailImg[seq].image_url !== "") {
                setFile(null);
            }

            // console.log(event.target.files);
            // setFile(event.target.files);
            const arr = detailImg.splice(seq, 1);
            if (arr[0].image_url.includes("blob")) {
                URL.revokeObjectURL(arr[0].image_url);
            }
            const boxitem = {
                image_url: URL.createObjectURL(event.target.files![0]),
                file: event.target.files![0],
                ratio: arr[0].ratio,
                order_by: arr[0].order_by,
            };
            detailImg.splice(seq, 0, boxitem);
        }
    };
    const ratioUpdate = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detailImg.splice(seq, 1);
        const boxitem = {
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: e.target.value,
            order_by: arr[0].order_by,
        };
        detailImg.splice(seq, 0, boxitem);
    };
    const orderbyUpdate = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detailImg.splice(seq, 1);
        const boxitem = {
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: arr[0].ratio,
            order_by: e.target.valueAsNumber,
        };
        detailImg.splice(seq, 0, boxitem);
    };

    const cancelBtnHandle = () => {
        let arr = [];
        arr.push(thumbnail);
        detailImg!.forEach((e) => {
            arr.push(e.image_url);
        });
        removeBloburl(arr);
        navigate(`/adminpage/productlist`, { state: { to: `hotdeal` } });
    };

    const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (thumbnail !== "") {
                onImageRemove();
            }
            setFile(event.target.files);
            setThumbnail(URL.createObjectURL(event.target.files![0]));
        }
    };

    const onImageRemove = (): void => {
        if (thumbnail.includes("blob")) {
            URL.revokeObjectURL(thumbnail);
        }
        setThumbnail(imgUrl); // 렌더링 이미지 초기화
        setFile(null);
    };

    useEffect(() => {
        if (s3flag == 1) {
            setImgS3list();
        } else if (s3flag == 2) {
            if (page.type === "reg") {
                regHotdealData();
            } else {
                updateHotdealData();
            }
        }
    }, [s3flag]);

    const setImgS3main = async () => {
        setShow(true);
        setS3flag(0);

        if (thumbnail.includes("blob")) {
            getS3imgContent(1)?.then(({ data }) => {
                const list = data.data.list;
                setS3imgContent(list[0].upload_url, list[0].result_url, file![0]);
                URL.revokeObjectURL(thumbnail);
                setMainimg(list[0].result_url);
            });
        } else {
            setMainimg(thumbnail);
        }
        setShow(false);
        setS3flag(1);
    };

    const setImgS3list = async () => {
        setShow(true);

        getS3imgContent(detailImg!.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < detailImg!.length; i++) {
                if (detailImg![i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, detailImg![i].file);
                    URL.revokeObjectURL(detailImg![i].image_url);
                    const boxitem = {
                        img: list[i].result_url,
                        w: "1000",
                        h: detailImg![i].ratio.toString(),
                        n: detailImg![i].order_by.toString(),
                    };
                    ia.push(boxitem);
                } else {
                    const boxitem = {
                        img: detailImg![i].image_url,
                        w: "1000",
                        h: detailImg![i].ratio.toString(),
                        n: detailImg![i].order_by.toString(),
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(2);
        });
    };

    const updateHotdealData = async () => {
        // 로딩 시작
        setShow(true);

        let petc = "";
        let pp = 0;
        if (item.payment_cnt === 1) {
            petc = item.last_etc.replace("{totalAmount}", totalPrice(item));
            pp = item.balance_amount;
        } else {
            petc = item.product_etc;
            pp = item.cnt1_price;
        }

        const body = {
            create_data: page.create_date,
            max_person: item.max_person,
            product_etc: petc,
            middle_etc: item.middle_etc,
            last_etc: item.last_etc,
            product_name: item.product_name,
            product_order: item.product_order,
            product_payment_yn: item.product_payment_yn,
            is_exclude: item.is_exclude,
            is_vat: item.is_vat.toString(),
            product_d_day: item.product_d_day,
            product_sales_day: item.product_sales_day,
            product_banner_image: mainImg,
            product_image_list: JSON.stringify(imgArr),
            product_policy_list: JSON.stringify(policy),
            payment_cnt: item.payment_cnt,
            product_price: pp,
            cnt_1_price: item.cnt1_price || 0,
            cnt_2_price: item.cnt2_price || 0,
            balance_amount: item.balance_amount,
        };

        // console.log("ubody? ", page.seq, body);

        try {
            const res = await axios.patch(`/event/product/edit/${page.seq}`, body);
            console.log("hd update: ", res.data);
            // 로딩 종료
            setShow(false);
            setMainimg("");
            setImgArr([]);
            if (res.data.code === 0) {
                alert("핫딜상품 수정이 완료되었습니다.");
                navigate(`/adminpage/productlist`, { state: { to: `hotdeal` } });
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const regHotdealData = async () => {
        // 로딩 시작
        setShow(true);

        let petc = "";
        let pp = 0;
        if (item.payment_cnt === 1) {
            petc = item.last_etc.replace("{totalAmount}", totalPrice(item));
            pp = item.balance_amount;
        } else {
            petc = item.product_etc;
            pp = item.cnt1_price;
        }

        const body = {
            max_person: item.max_person,
            product_etc: petc,
            middle_etc: item.middle_etc,
            last_etc: item.last_etc,
            product_name: "[진행중]" + item.product_name,
            product_order: item.product_order,
            product_payment_yn: item.product_payment_yn,
            is_exclude: item.is_exclude,
            is_vat: item.is_vat,
            product_d_day: item.product_d_day,
            product_sales_day: item.product_sales_day,
            product_banner_image: mainImg,
            product_image_list: JSON.stringify(imgArr),
            product_policy_list: JSON.stringify(policy),
            payment_cnt: item.payment_cnt,
            product_price: pp.toString(),
            cnt1_price: item.cnt1_price || 0,
            cnt2_price: item.cnt2_price || 0,
            balance_amount: item.balance_amount,
        };

        // console.log("cbody? ", body);

        try {
            const res = await axios.post(`/event/product`, body);
            // console.log("hd reg: ", res.data);
            // 로딩 종료
            setShow(false);
            setMainimg("");
            setImgArr([]);
            if (res.data.code === 0) {
                alert("핫딜상품 등록이 완료되었습니다.");
                navigate(`/adminpage/productlist`, { state: { to: `hotdeal` } });
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const checkItem = () => {
        if (item.product_name === "") {
            alert("핫딜 상품 명을 확인해 주세요");
            return;
        }
        if (item.is_vat < 1 && item.is_vat > 2) {
            alert("VAT 표시 설정 여부를 확인해 주세요");
            return;
        }
        if (item.max_person === 0) {
            alert("최대 구매 수량을 확인해 주세요");
            return;
        }
        if (item.product_order === 0) {
            alert("핫딜 상품 표현 순서를 확인해 주세요");
            return;
        }
        if (item.payment_cnt < 1) {
            alert("결제 횟수를 확인해 주세요");
            return;
        } else if (item.payment_cnt === 2) {
            if (item.product_etc === "") {
                alert("예약금 결제 알림톡 문구를 확인해 주세요");
                return;
            }
        } else if (item.payment_cnt === 3) {
            if (item.middle_etc === "") {
                alert("중도금 결제 알림톡 문구를 확인해 주세요");
                return;
            }
        }
        if (item.last_etc === "") {
            alert("결제 완료 알림톡 문구를 확인해 주세요");
            return;
        }
        if (thumbnail === imgUrl) {
            alert("패키지 상품 썸네일 이미지를 확인해 주세요");
            return;
        }
        if (detailImg!.length < 1) {
            alert("상세 이미지 리스트를 확인해 주세요");
            return;
        }
        if (policy!.length < 1) {
            alert("정책 리스트를 확인해 주세요");
            return;
        }

        setImgS3main();
    };

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <BlockArea>
                <Container wp="40%">
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">상품명 :</p>
                        <input
                            className="content size350"
                            value={item.product_name}
                            placeholder="상품명을 입력해 주세요"
                            onChange={setPname}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">판매기간 :</p>
                        <input
                            className="content size350"
                            value={item.product_sales_day}
                            placeholder="판매기간을 입력해 주세요"
                            onChange={setSales}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">행사일 :</p>
                        <input
                            className="content size350"
                            value={item.product_d_day}
                            placeholder="행사일시를 입력해주세요"
                            onChange={setDday}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">구매가능수량 :</p>
                        <input
                            className="content size90"
                            type={"number"}
                            value={item.max_person}
                            placeholder="구매가능 수량"
                            onChange={setMaxperson}
                        />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">표현순서 :</p>
                        <input
                            className="content size90"
                            type={"number"}
                            value={item.product_order}
                            placeholder="표현순서"
                            onChange={setOrder}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">상품노출 :</p>
                        <VisibleSelectBox
                            visible={item.is_exclude === "N" ? 1 : 0}
                            sort={"hotdeal_exclude"}
                            id={item.event_product_seq}
                            setState={setVisibleItem}
                            disable={false}
                        />
                        <div className="margin50" />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">결제버튼 :</p>
                        <VisibleSelectBox
                            visible={item.product_payment_yn === "Y" ? 1 : 0}
                            sort={"hotdeal_payment"}
                            id={item.event_product_seq}
                            setState={setVisiblePayBtn}
                            disable={false}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">결제 횟수 :</p>
                        <input
                            className="content size90"
                            type={"number"}
                            value={item.payment_cnt}
                            placeholder="총 결제 횟수"
                            onChange={setPayCnt}
                        />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">vat포함여부 :</p>
                        <input
                            className="content size90"
                            type={"number"}
                            value={item.is_vat === 0 ? "" : item.is_vat}
                            placeholder={"1:O / 2:X"}
                            onChange={setVat}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#ffffff"
                            size={10}
                        />
                        <p className="name">총 가격 :</p>
                        <p className="name">{totalPrice(item).toString()}</p>
                    </div>
                    {item.payment_cnt > 1 && (
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">예약금 :</p>
                            <input
                                className="content size350"
                                type={"number"}
                                value={item.cnt1_price}
                                placeholder={"결제 예약금 값을 입력해 주세요"}
                                onChange={setCnt1}
                            />
                        </div>
                    )}
                    {item.payment_cnt > 2 && (
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">중도금 :</p>
                            <input
                                className="content size350"
                                type={"number"}
                                value={item.cnt2_price}
                                placeholder={"결제 중도금 값을 입력해 주세요"}
                                onChange={setCnt2}
                            />
                        </div>
                    )}
                    {item.payment_cnt > 0 && (
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">{item.payment_cnt === 1 ? "결제금" : "잔금 :"}</p>
                            <input
                                className="content size350"
                                type={"number"}
                                value={item.balance_amount}
                                placeholder={"결제 잔금 값을 입력해 주세요"}
                                onChange={setBamount}
                            />
                        </div>
                    )}
                    <div className="imgrowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">썸네일 이미지</p>
                        <ImgAddContainer>
                            <ThumbnailImgUploader
                                fileURL={thumbnail}
                                flag={true}
                                uploadImg={onImageChange}
                                removeImg={onImageRemove}
                            />
                        </ImgAddContainer>
                    </div>
                    {item.payment_cnt > 1 && (
                        <div className="imgrowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">예약금 문구</p>
                            <textarea
                                className="tacontent size350"
                                value={item.product_etc}
                                placeholder={"예약금 결제 완료시 전송될 알림톡 문구를 입력해주세요."}
                                onChange={setPE}
                            />
                        </div>
                    )}
                    {item.payment_cnt > 2 && (
                        <div className="imgrowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">중도금 문구</p>
                            <textarea
                                className="tacontent size350"
                                value={item.middle_etc}
                                placeholder={"중도금 결제 완료시 전송될 알림톡 문구를 입력해주세요."}
                                onChange={setME}
                            />
                        </div>
                    )}
                    <div className="imgrowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">결제완료 문구</p>
                        <textarea
                            className="tacontent size350"
                            value={item.last_etc}
                            placeholder={"결제 완료시 전송될 알림톡 문구를 입력해주세요."}
                            onChange={setLE}
                        />
                    </div>
                </Container>
                <div className="stick" />
                <Container wp="55%">
                    {page.type === "update" ? (
                        <HotdealDetailImage
                            item={detailImg}
                            flag={true}
                            uploadImg={onImageUpdate}
                            ratiohandle={ratioUpdate}
                            orderhandle={orderbyUpdate}
                        />
                    ) : (
                        <DetailImgUploader
                            detailData={regHotdealImg}
                            type={TYPE_PRODUCT_DETAIL}
                        />
                    )}
                    <div className="rowStick" />
                    <HotdealPolicy
                        boxlist={policy}
                        setBox={setPolicyList}
                        type={"reg"}
                    />
                </Container>
            </BlockArea>
            <BtnContainer>
                <Btn
                    type={true}
                    onClick={cancelBtnHandle}
                >
                    취소
                </Btn>
                <Btn
                    type={false}
                    onClick={checkItem}
                >
                    등록
                </Btn>
            </BtnContainer>
        </BaseContainer>
    );
}
