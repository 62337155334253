export interface calendar {
    jan_1: string;
    feb_2: string;
    mar_3: string;
    apr_4: string;
    may_5: string;
    jun_6: string;
    jul_7: string;
    aug_8: string;
    sep_9: string;
    oct_10: string;
    nov_11: string;
    dec_12: string;
}

export const arr5 = [0, 0, 0, 0, 0];
export const arr6 = [0, 0, 0, 0, 0, 0];
export const arr12 = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
