import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.th<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #f7f8fe;

    h3 {
        font-size: 13px;
        font-weight: 700;
        color: black;
    }
`;

interface props {
    width: string;
    text: string;
}

export const SheetSortation = (props: props): ReactElement => {
    return (
        <Container width={props.width}>
            <h3>{props.text}</h3>
        </Container>
    );
};
