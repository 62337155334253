import { format } from "date-fns";
import { ChangeEvent, useRef, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { MdCalendarMonth } from "react-icons/md";
import styled from "styled-components";
import { SearchCalendar } from "./SearchCalendar";

const Container = styled.div`
    display: flex;
    width: 100%;
    // margin: 0px 0px 0px 0px;
    // background-color: #eeeeee;
    align-items: center;
    justify-content: center;

    .rowBox {
        display: flex;
        width: 100%;
        gap: 10px;
        align-items: center;
        justify-content: center;
        // background-color: #ff0022;
    }

    .calbox {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 170px;

        input {
            width: 100%;
            height: 20px;
            line-height: 18px;
            border: 1px solid #e7e7ee;
            border-radius: 4px;
            padding: 10px 20px 10px 40px;
            outline: none;
            font-size: 14px;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            top: 10px;
            left: 10px;
            // color: #5d646d;
        }
    }

    .search {
        height: 20px;
        width: 100%;
        max-width: 350px;
        line-height: 5px;
        border: 1px solid #e7e7ee;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        outline: none;
    }

    .searchBtn {
        width: 100%;
        max-width: 80px;
        height: 42px;
        border-radius: 7px;
        padding: 10px;
        line-height: 15px;
        background-color: #2d40ff;
        border: none;
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
    }
`;

const TextContainer = styled.div`
    display: flex;
    height: 42px;
    gap: 5px;
    align-items: center;

    h3 {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

interface props {
    title: string;
    searching: (str: string, end: string, txt: string) => void;
}

export const RsvListSearchBox = (props: props) => {
    const { title, searching } = props;
    const [searchTxt, setSearchtxt] = useState<string>("");
    const [strCal, setStrCal] = useState(new Date("2023-12-18"));
    const [endCal, setEndCal] = useState(new Date());
    const [scShow, setScShow] = useState(false);
    const [ecShow, setEcShow] = useState(false);
    const strfocus = useRef<HTMLDivElement>(null);
    const endfocus = useRef<HTMLDivElement>(null);

    const searchTxtInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchtxt(e.target.value);
    };

    const onStrRef = () => {
        strfocus.current?.scrollTo(0, 0);
    };
    const onEdnRef = () => {
        endfocus.current?.scrollTo(0, 0);
    };

    const onStrCalSelect = () => {
        if (scShow) {
            setScShow(false);
        } else {
            setScShow(true);
            setEcShow(false);
        }
    };

    const onEndCalSelect = () => {
        if (ecShow) {
            setEcShow(false);
        } else {
            setEcShow(true);
            setScShow(false);
        }
    };

    const setStrdate = (value: any) => {
        setStrCal(value);
    };

    const setEnddate = (value: any) => {
        setEndCal(value);
    };

    const searchOnClick = () => {
        searching(format(new Date(strCal), "yyyy-MM-dd"), format(new Date(endCal), "yyyy-MM-dd"), searchTxt);
    };

    return (
        <Container>
            <div className="rowBox">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={15}
                    />
                    <h3>{title}</h3>
                </TextContainer>
                <div
                    className="calbox"
                    ref={strfocus}
                >
                    <MdCalendarMonth
                        className="icon"
                        color="#2d40ff"
                        size={20}
                    />
                    <input
                        onClick={onStrCalSelect}
                        type="text"
                        placeholder="검색시작일"
                        value={format(new Date(strCal), "yyyy년 MM월 dd일")}
                        readOnly
                    />
                    {scShow && (
                        <SearchCalendar
                            close={() => setScShow(false)}
                            scroll={onStrRef}
                            setValue={setStrdate}
                            value={strCal}
                        />
                    )}
                </div>
                ~
                <div
                    ref={endfocus}
                    className="calbox"
                >
                    <MdCalendarMonth
                        className="icon"
                        color="#2d40ff"
                        size={20}
                    />
                    <input
                        type="text"
                        onClick={onEndCalSelect}
                        placeholder="검색종료일"
                        value={format(new Date(endCal), "yyyy년 MM월 dd일")}
                        readOnly
                    />
                    {ecShow && (
                        <SearchCalendar
                            close={() => setEcShow(false)}
                            scroll={onEdnRef}
                            setValue={setEnddate}
                            value={endCal}
                        />
                    )}
                </div>
                <input
                    className="search"
                    type="text"
                    value={searchTxt}
                    placeholder="상품명 / 예약자명 / 예약자 연락처 검색가능"
                    onChange={searchTxtInput}
                />
                <button
                    className="searchBtn"
                    onClick={searchOnClick}
                >
                    검색
                </button>
            </div>
        </Container>
    );
};
