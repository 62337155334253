import { useAtom, useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { RegiBtnBox } from "../components/RegisterBtnBox";
import { RegiAtomClear, RegiInputBox } from "../components/RegisterInputBox";

export function AccountRegister() {
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    setSelectMenu(4);
    setTitle("계정관리");
    setSubTitle("계정등록");
    setThirdTitle("");
    RegiAtomClear();

    return (
        <BaseContainer>
            <RegiInputBox sort={true} />
            <RegiBtnBox sort={true} />
        </BaseContainer>
    );
}
