export const head_txt = "\n[와플스테이]\n안녕하세요 와플스테이 입니다.\n여행 상품을 구매해 주셔서 감사합니다.\n\n";
export const bottom_txt = "\n\n□고객센터: 1533-7901\n□문의 메일 주소: waffle@wafflestay.kr\n\n";
export const bottom2_txt = "고객센터 운영시간 평일/공휴일 제외\n10:00 ~ 17:00\n\n";
export const calltime = "\n\n고객센터 운영시간\n□월~목 : 10:00~17:00\n□금 : 10:00~16:30\n□주말 및 공휴일은 제외";

export const DocuContentTxt = [
    {
        color: "#22aaff",
        title: "다낭 크리스마스특가 예약시",
        text: "크리스마스 특가 상품 예약이 정상적으로 완료되었습니다.\n\n와플스테이와 함께하는 크리스마스는 더욱 특별할 것을 약속드립니다.\n\n여행을 떠나기 전까지 도움이 필요하시면 언제든지 연락 주세요.\n\n즐거운 여행을 기대하며, 앞으로도 와플스테이와 함께 특별한 순간을 만들어 나가길 기대하며 다시 한 번 감사드립니다.\n\n□예약번호:\n□예약일자:\n□예약자명:\n□숙박자명:\n□호텔명:\n□룸 타입:\n□체크인 일자:\n□체크아웃 일자:\n\n문의사항발생시 아래 연락처 또는 이메일로 문의 부탁드립니다.\n감사합니다.",
    },
    {
        color: "#22aaff",
        title: "다낭 크리스마스특가 예약실패시 ",
        text: "크리스마스 시즌 특가 상품 예약에 대한 열렬한 관심과 사랑, 그리고 예약주신 모든 고객님께 진심으로 감사의 말씀을 전합니다.\n\n안타깝게도 예약주신 호텔의 객실은 현재 매진 상태입니다. 이로 인해 발생된 불편함을 깊이 양해해 주시기를 부탁드립니다.다만, 고객님의 편의와 만족을 최우선으로 고려하여 예약금은 전액 환불 처리되었습니다.\n\n환불 절차는 이미 완료된 상태이며, 해당 내용을 다시 한 번 안내 드립니다.\n\n와플스테이는 언제나 여러분을 위해 노력하고 있으며, 앞으로도 다양한 특가와 이벤트로 여러분을 기쁘게 해드릴 것을 약속드립니다.\n\n다시 한 번 감사드리며 더 나은 상품과 서비스로 찾아뵙도록 하겠습니다.\n늘 편안한 여행이 되시길 바랍니다.",
    },
    { color: "#fa22ff", title: "상품별 상담 후 안내", text: "요청하신 {상품명}에 대한 예약안내드립니다." },
    { color: "#22aaff", title: "상품별 예약안내", text: "예약하신 상품에 대한 예약정보를 전달드립니다.\n□예약번호" },
    { color: "#2c326b", title: "직접입력", text: "" },
];
