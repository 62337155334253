import { useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { RxUpdate } from "react-icons/rx";
import { DetailImage, updateBox } from "../components/common/ProductDetail_Image";
import { DetailInfo } from "../components/common/ProductDetail_Info";
import LoadingSpinner from "../../common/LoadingSpinner";
import { imgUrl } from "./ProductListView";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../components/common/updateimgInterface";

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    height: 73vh;
    align-items: start;
    justify-content: center;
`;

const MenuContainer = styled.div`
    display: flex;
    width: 95%;
    height: 6vh;
    align-items: center;
    justify-content: space-between;
    // background-color: #fff111;

    .backicon {
        cursor: pointer;
    }

    .title {
        color: #2d40ff;
        font-size: 20px;
        font-weight: 500;
    }
`;

const UpdateBtn = styled.div`
    display: flex;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #2d40ff;

    .btntext {
        color: #ffffff;
        margin-left: 10px;
        font-size: 15px;
        font-weight: 500;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 47%;
    height: 69vh;
    align-items: start;
    justify-content: start;
    // background-color: #2d40ff;
    padding: 10px 10px 10px 0px;
`;

const StickRowBar = styled.div`
    width: 95%;
    height: 1px;
    background-color: #eeeeee;
    padding: 0px 10px 0px 10px;
    margin-bottom: 5px;
`;

const StickBar = styled.div`
    width: 2px;
    height: 70vh;
    background-color: #eeeeee;
    padding: 5px 0px 5px 0px;
`;

const DetailContainer = styled.div`
    display: block;
    width: 47%;
    height: 69vh;
    align-items: start;
    justify-content: start;
    // background-color: #000fff;
    overflow: auto;
    padding: 10px 0px 10px 10px;
`;

export function PlanDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [detail, setDetail] = useState<Array<updateBox>>([]);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [file, setFile] = useState<FileList | null>();
    const [isShow, setShow] = useState(false);
    const [file2, setFile2] = useState<FileList | null>();
    const [mainImg, setMainimg] = useState("");
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const [s3flag, setS3flag] = useState(0);
    const partner_lv = Number(sessionStorage.getItem("partnerlv"));
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("상품상세");
    const product = { ...location.state };
    const [updatePd, setUpdatePd] = useState(product);

    const backHandle = () => {
        if (updateFlag) {
            let arr = [];
            arr.push(updatePd.thumbnail);
            detail!.forEach((e) => {
                arr.push(e.image_url);
            });
            removeBloburl(arr);
        }
        navigate(`/adminpage/productlist`, { state: { to: `plan` } });
    };
    const ptitleInputText = (e: ChangeEvent<HTMLInputElement>) => {
        const data = {
            seq: updatePd.seq,
            title: e.target.value,
            thumbnail: updatePd.thumbnail,
            price: updatePd.price,
            visible: updatePd.visible,
            orderby: updatePd.orderby,
        };
        setUpdatePd(data);
    };
    const ppriceInputText = (e: ChangeEvent<HTMLInputElement>) => {
        const data = {
            seq: updatePd.seq,
            title: updatePd.title,
            thumbnail: updatePd.thumbnail,
            price: e.target.value,
            visible: updatePd.visible,
            orderby: updatePd.orderby,
        };
        setUpdatePd(data);
    };
    const porderInputText = (e: ChangeEvent<HTMLInputElement>) => {
        const data = {
            seq: updatePd.seq,
            title: updatePd.title,
            thumbnail: updatePd.thumbnail,
            price: updatePd.price,
            visible: updatePd.visible,
            orderby: e.target.value,
        };
        setUpdatePd(data);
    };
    const setPVisibleBox = async (v: number, sort: string, id: number) => {
        const data = {
            seq: updatePd.seq,
            title: updatePd.title,
            thumbnail: updatePd.thumbnail,
            price: updatePd.price,
            visible: v.toString(),
            orderby: updatePd.orderby,
        };
        setUpdatePd(data);
    };
    const onThumbnailChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (updatePd.thumbnail !== "") {
                onThumbnailRemove();
            }
            setFile2(event.target.files);
            const data = {
                seq: updatePd.seq,
                title: updatePd.title,
                thumbnail: URL.createObjectURL(event.target.files![0]),
                price: updatePd.price,
                visible: updatePd.visible,
                orderby: updatePd.orderby,
            };
            setUpdatePd(data);
        }
    };
    const onThumbnailRemove = (): void => {
        if (updatePd.thumbnail.includes("blob")) {
            URL.revokeObjectURL(updatePd.thumbnail);
        }
        const data = {
            seq: updatePd.seq,
            title: updatePd.title,
            thumbnail: imgUrl,
            price: updatePd.price,
            visible: updatePd.visible,
            orderby: updatePd.orderby,
        };
        setUpdatePd(data);
        setFile2(null);
    };
    const updateBtn = () => {
        if (updateFlag) {
            // 저장하기
            setImgS3main();
        } else {
            // 정보수정
            // flag 변환 - false >> true
            setUpdateFlag(!updateFlag);
        }
    };
    const onImageChange = (seq: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (event.target.files!.length > 1) {
                alert("이미지는 한장만 선택 할 수 있습니다.");
                return;
            }

            if (detail[seq].image_url !== "") {
                setFile(null);
            }

            // console.log(event.target.files);
            // setFile(event.target.files);
            const arr = detail.splice(seq, 1);
            if (arr[0].image_url.includes("blob")) {
                URL.revokeObjectURL(arr[0].image_url);
            }
            const boxitem = {
                seq: arr[0].seq,
                image_url: URL.createObjectURL(event.target.files![0]),
                file: event.target.files![0],
                ratio: arr[0].ratio,
                order_by: arr[0].order_by,
            };
            detail.splice(seq, 0, boxitem);
            setDetail([...detail]);
        }
    };
    const ratioInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: e.target.value,
            order_by: arr[0].order_by,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };
    const orderbyInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: arr[0].ratio,
            order_by: e.target.valueAsNumber,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };

    useEffect(() => {
        if (s3flag == 1) {
            setImgS3list();
        } else if (s3flag == 2) {
            setUpdatePackage();
        }
    }, [s3flag]);

    const setImgS3main = async () => {
        setShow(true);
        setS3flag(0);

        if (updatePd.thumbnail.includes("blob")) {
            getS3imgContent(1)?.then(({ data }) => {
                const list = data.data.list;
                setS3imgContent(list[0].upload_url, list[0].result_url, file2![0]);
                URL.revokeObjectURL(updatePd.thumbnail);
                setMainimg(list[0].result_url);
            });
        } else {
            setMainimg(updatePd.thumbnail);
        }
        setShow(false);
        setS3flag(1);
    };

    const setImgS3list = async () => {
        setShow(true);
        console.log("de? ", detail.length);
        getS3imgContent(detail!.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < detail!.length; i++) {
                if (detail![i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, detail![i].file);
                    URL.revokeObjectURL(detail![i].image_url);
                    console.log("de?? ", list[i].result_url);
                    const boxitem = {
                        seq: detail[i].seq,
                        image_url: list[i].result_url,
                        ratio: detail![i].ratio,
                        order_by: detail![i].order_by,
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(2);
        });
    };

    const setUpdatePackage = async () => {
        // 로딩 시작
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");
        const body = {
            package_seq: updatePd.seq,
            title: updatePd.title,
            main_img: mainImg,
            price: updatePd.price,
            visible: updatePd.visible,
            order_by: updatePd.orderby,
        };

        try {
            const res = await axios.put(`/v1/packages`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("info update: ", res.data);
            setMainimg("");
            if (res.data.code === 0) {
                setDetailUpdate(accesstoken!.toString());
            } else {
                // 로딩 종료
                setShow(false);
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const setDetailUpdate = async (accesstoken: string) => {
        const body = {
            product_sort: "package",
            product_id: product.seq,
            image_list: imgArr,
        };
        console.log("update body: ", body);

        try {
            const res = await axios.put(`/v1/products/images`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("detail update: ", res.data);
            // 로딩 종료
            setShow(false);
            setImgArr([]);
            if (res.data.code === 0) {
                alert("패키지 수정이 완료되었습니다.");
                // flag 변환 - true >> false
                setUpdateFlag(!updateFlag);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    const getProductDetail = async () => {
        try {
            const res = await axios.get(
                `/product/detail/imglist?client_id=1&product_sort=PACKAGE&product_id=${product.seq}`
            );
            // console.log("package detail: ", res.data);
            if (res.data.code === "WFL000") {
                // 리스트 저장
                let list = res.data.data;
                list.sort((a: any, b: any) => a.order_by - b.order_by);
                setDetail(list);
            }
        } catch (e: any) {
            // 에러 처리
            alert(e.response.data.message);
        }
    };

    useEffect(() => {
        getProductDetail();
    }, []);

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <MenuContainer>
                <RiArrowGoBackLine
                    className="backicon"
                    size={30}
                    color={"#2d40ff"}
                    onClick={backHandle}
                />
                <h3 className="title">상품 미리보기</h3>
                {partner_lv > 1 ? (
                    <UpdateBtn onClick={updateBtn}>
                        <RxUpdate
                            size={25}
                            color={"#ffffff"}
                        />
                        <h3 className="btntext">{updateFlag ? "저장하기" : "정보수정"}</h3>
                    </UpdateBtn>
                ) : (
                    <div />
                )}
            </MenuContainer>
            <StickRowBar />
            <RowContainer>
                <InfoContainer>
                    <DetailInfo
                        flag={updateFlag}
                        item={updatePd}
                        titleInput={ptitleInputText}
                        priceInput={ppriceInputText}
                        orderInput={porderInputText}
                        visibleInput={setPVisibleBox}
                        thumbnailInput={onThumbnailChange}
                        thumbnailRemove={onThumbnailRemove}
                    />
                </InfoContainer>
                <StickBar />
                <DetailContainer>
                    {detail.length !== 0 && (
                        <DetailImage
                            item={detail}
                            flag={updateFlag}
                            type="plan"
                            uploadImg={onImageChange}
                            ratiohandle={ratioInput}
                            orderhandle={orderbyInput}
                        />
                    )}
                </DetailContainer>
            </RowContainer>
        </BaseContainer>
    );
}
