import { useState, useRef, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { BiSolidMinusCircle, BiSolidRightArrow } from "react-icons/bi";
import { LuCross } from "react-icons/lu";

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // background-color: #2d40ff;
`;

const DetailContainer = styled.div`
    display: flex;
    width: 70%;
    max-width: 600px
    height: 42px;
    align-items: center;
    justify-content: space-between;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

const AddBtn = styled.div`
    display: flex;
    width: 120px;
    height: 42px;
    cursor: pointer;
    align-items: center;
    // background-color: #eeeeee;
    padding: 0px 0px 0px 10px;
    margin-right: 50px;

    .addbox {
        font-size: 20px;
        color: #2d40ff;
        font-weight: 600;
        margin-left: 5px;
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding-left: 15px;
    // background-color: #fff000;
    gap: 10px;

    .rbox {
        width: 100%;
        max-width: 550px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        // border: 1px solid #2d40ff;
        background-color: #eeeeee;
        border-radius: 7px;

        .back {
            cursor: pointer;
        }

        .cbox {
            width: 100%;
            max-width: 500px;

            .row {
                display: flex;
                align-items: center;
                // background-color: #fff111;

                .text {
                    display: flex;
                    width: 100%;
                    max-width: 100px;
                    height: 42px;
                    align-items: center;

                    h3 {
                        margin-left: 5px;
                        font-size: 15px;
                        font-weight: 500;
                        color: #000000;
                    }
                }

                .content {
                    height: 35px;
                    outline: none;
                    padding: 0px 10px;
                    font-weight: 500;
                    border-radius: 7px;
                    border: 1px solid #000000;
                    font-size: 14px;
                }

                .size3 {
                    width: 100%;
                    max-width: 370px;
                }
            }
        }
    }
`;

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: stretch;
`;

interface props {
    boxlist: Array<policy>;
    setBox: (list: Array<policy>) => void;
    type: string;
}

export interface policy {
    txt: string;
    link: string;
}

export const HotdealPolicy = (props: props) => {
    const { boxlist, setBox, type } = props; // props로 함수 받기
    const boxitem = {
        txt: "",
        link: "https://",
    };

    const AddBox = () => {
        setBox([...boxlist, boxitem]);
    };

    const onBoxRemove = (seq: number): void => {
        if (boxlist.length == 1) {
            alert("정책리스트는 최소 1개 이상이 필요합니다.");
            return;
        }
        boxlist.splice(seq, 1);
        setBox([...boxlist]);
    };
    const boxtxtInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = boxlist.splice(seq, 1);
        const boxitem = {
            txt: e.target.value,
            link: arr[0].link,
        };
        boxlist.splice(seq, 0, boxitem);
        setBox([...boxlist]);
    };
    const boxlinkInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = boxlist.splice(seq, 1);
        const boxitem = {
            txt: arr[0].txt,
            link: e.target.value,
        };
        boxlist.splice(seq, 0, boxitem);
        setBox([...boxlist]);
    };

    return (
        <BoxContainer>
            <DetailContainer>
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>{"정책 리스트"}</h3>
                </TextContainer>
                <AddBtn onClick={AddBox}>
                    <LuCross
                        color="2d40ff"
                        size={20}
                    />
                    <h3 className="addbox">정책 추가</h3>
                </AddBtn>
            </DetailContainer>
            <ListContainer>
                {boxlist.map((item: any, i: number) => {
                    return (
                        <div
                            className="rbox"
                            key={i}
                        >
                            <div className="cbox">
                                <div className="row">
                                    <div className="text">
                                        <BiSolidRightArrow
                                            color="#6f7cff"
                                            size={10}
                                        />
                                        <h3>문서명</h3>
                                    </div>
                                    <input
                                        className="content size3"
                                        value={boxlist[i].txt}
                                        placeholder="정책 문서 명칭을 입력해주세요"
                                        onChange={(e) => boxtxtInput(i, e)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="text">
                                        <BiSolidRightArrow
                                            color="#6f7cff"
                                            size={10}
                                        />
                                        <h3>LINK URL</h3>
                                    </div>
                                    <input
                                        className="content size3"
                                        value={boxlist[i].link}
                                        placeholder="정책 문서 URL을 입력해 주세요"
                                        onChange={(e) => boxlinkInput(i, e)}
                                    />
                                </div>
                            </div>
                            <BiSolidMinusCircle
                                className="back"
                                color="f47878"
                                size={35}
                                onClick={() => onBoxRemove(i)}
                            />
                        </div>
                    );
                })}
            </ListContainer>
        </BoxContainer>
    );
};
