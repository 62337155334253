import styled from "styled-components";
import { ChangeEvent, ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import { refundTypeParse } from "./ReservUtil";
import axios from "axios";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { BiSolidRightArrow } from "react-icons/bi";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    text-align: left;
    padding: 10px 15px 10px 20px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 500;
    font-size: 16px;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 150px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ font: string }>`
    padding: 5px 10px;
    width: 100px;
    height: 30px;
    border: 0.5px solid #eeeeee;
    color: ${(props) => props.font};
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 400px;
    // background-color: #fff111;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .priceinput {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #161a3f;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    open: boolean;
    reservation_id: number;
    type: string;
    cumulative: number;
    close: (v: boolean) => void;
    back: () => void;
}

export const BemilyPCModal = (props: props): ReactElement => {
    const { open, reservation_id, type, cumulative, close, back } = props; // props로 함수 받기
    const [isShow, setShow] = useState(false);
    const [refund, setRefund] = useState(0);

    const refundPriceInput = (e: ChangeEvent<HTMLInputElement>) => {
        setRefund(e.target.valueAsNumber);
    };

    const refundBemily = async () => {
        setShow(true);
        const body = {
            reservation_id: Number(reservation_id),
            product_type: refundTypeParse(type),
            cancel_amount: refund,
        };

        try {
            const res = await axios.post(`/product/bemily/admin/paycancel`, body);
            // console.log("be refund: ", res.data, res.data.data.result_data.return_msg);
            setShow(false);
            if (res.data.code === "WFL000") {
                alert(res.data.data.result_data.return_msg);
                back();
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                close(false);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    결제 취소 요청
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={20}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>총 결제금액</h3>
                        </div>
                        <input
                            type="text"
                            value={priceParseComma(cumulative.toString())}
                            readOnly
                        />
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>취소금액</h3>
                        </div>
                        <input
                            className="priceinput"
                            type="number"
                            value={refund}
                            placeholder="취소 할 금액을 입력해주세요"
                            onChange={refundPriceInput}
                        />
                    </BoxContainer>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        font="#000000"
                        onClick={() => close(false)}
                    >
                        닫기
                    </BtnContainer>
                    <BtnContainer
                        color="#2d40ff"
                        font="#ffffff"
                        onClick={refundBemily}
                    >
                        취소요청
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
