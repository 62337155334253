import axios from "axios";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { BsDatabaseUp } from "react-icons/bs";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../../common/BaseContainer";
import { BasicModal } from "../../../common/BasicModal";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../../common/NavTopBar";
import { SelectMenu } from "../../../common/NavVar";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { dateParse } from "../../../product/view/CounsellingListView";
import { BemilyMemoModal } from "../common/BemilyMemoModal";
import { BemilyPCModal } from "../common/BemilyPayCancelModal";
import { ReadOnlyIBox } from "../common/ReadOnlyIBox";
import { hotdealBalance, hotdealPathParse, hotdealPayTypeParse, hotdealStateParse } from "../common/ReservUtil";

const Top = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .menu {
        display: flex;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 5px;

        .backicon {
            cursor: pointer;
        }
        .title {
            color: #2d40ff;
            font-size: 20px;
            font-weight: 500;
            justify-content: center;
        }
        .space {
            width: 10px;
        }
        .rowbtn {
            display: flex;
            gap: 5px;
            margin-right: 10px;

            .btn {
                padding: 12px 20px;
                background-color: #2d40ff;
                border: none;
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
    .stick {
        width: 95%;
        height: 2px;
        background-color: #eeeeee;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: start;
    justify-content: center;
    padding: 10px;

    .btnsector {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        padding: 10px 0;
        // background-color: #eeeeee;

        .addtransfer {
            display: flex;
            padding: 10px;
            border: none;
            border-radius: 7px;
            background-color: #23c162;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
        }
    }

    .sector {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        // background-color: #eeeeee;

        .text {
            display: flex;
            width: 100%;
            max-width: 100px;
            height: 42px;
            align-items: center;

            h3 {
                margin-left: 5px;
                font-size: 15px;
                font-weight: 500;
                color: #122437;
            }
        }

        .rowbox {
            display: flex;
            gap: 5px;
            padding: 0px 10px;
            // background-color: #2d40ff;

            .cstick {
                width: 2px;
                height: 270px;
                background-color: #eeeeee;
                margin-top: 5px;
            }
        }

        .stick {
            width: 70%;
            height: 1px;
            background-color: #eeeeee;
            margin: 20px 0px;
        }

        .listbox {
            margin-top: 5px;
            display: block;
            overflow: auto;
            width: 75%;
            height: 270px;
            // backgroun-color: #fff111;
        }
    }
`;

export function HotdealReservDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [isShow, setShow] = useState(false);
    const [isData, setData] = useState(false);
    const data = { ...location.state };
    const item = JSON.parse(data.item);
    const [reservItem, setReservItem] = useState<any>();
    const [pcModal, setPCmodal] = useState<boolean>(false);
    const [memoModal, setMemoModal] = useState<boolean>(false);
    setSelectMenu(3);
    setTitle("구매 판매 내역");
    setSubTitle("상품 구매 내역");
    setThirdTitle("구매 내역 상세");
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    const backHandle = () => {
        navigate(`/adminpage/productreserv`, { state: { to: "HOTDEAL" } });
    };

    useEffect(() => {
        getReservInfo();
    }, []);

    const closeback = () => {
        setPCmodal(false);
        backHandle();
    };

    const refundBtn = () => {
        let result = false;
        if (isData) {
            if (item.cumulative_pay_cnt > 0) {
                if (partnerlv > 0 && item.client_id === 518) {
                    result = true;
                }
            }
        }
        return result;
    };

    const getReservInfo = async () => {
        setShow(true);

        try {
            const res = await axios.get(`/balance`, {
                params: {
                    reservation_id: item.reservation_id,
                    phone: item.user_phone,
                },
            });
            setShow(false);
            console.log("hotdeal rd? ", res.data);
            if (res.data.code === 0) {
                setReservItem(res.data.data);
                setData(true);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <BaseContainer>
            {pcModal && (
                <BemilyPCModal
                    open={pcModal}
                    reservation_id={item.reservation_id}
                    type={"HOTDEAL"}
                    cumulative={item.cumulative_amount}
                    close={setPCmodal}
                    back={closeback}
                />
            )}
            {memoModal && (
                <BemilyMemoModal
                    open={memoModal}
                    bemily_id={item.partner_user_id}
                    close={setMemoModal}
                />
            )}
            <Top>
                <div className="menu">
                    <RiArrowGoBackLine
                        className="backicon"
                        size={30}
                        color={"#2d40ff"}
                        onClick={backHandle}
                    />
                    <h3 className="title">구매상품 상세페이지</h3>
                    {refundBtn() ? (
                        <div className="rowbtn">
                            <button
                                className="btn"
                                onClick={() => setMemoModal(true)}
                            >
                                메모 보내기
                            </button>
                            <button
                                className="btn"
                                onClick={() => setPCmodal(true)}
                            >
                                결제 취소 요청
                            </button>
                        </div>
                    ) : (
                        <div className="space" />
                    )}
                </div>
                <div className="stick" />
            </Top>
            <Body>
                <div className="sector">
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매번호"
                        value={item.reservation_id}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매경로"
                        value={hotdealPathParse(item)}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="상품 명"
                        value={item.product_name}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="진행상태"
                        value={hotdealStateParse(item)}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매요청일"
                        value={dateParse(item.create_date)}
                    />
                    <div className="stick" />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="구매자 명"
                        value={item.user_name}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="연락처"
                        value={item.user_phone}
                    />
                    <ReadOnlyIBox
                        type="txt"
                        mw={400}
                        title="이메일"
                        value={item.user_email}
                    />
                </div>
                {isData && (
                    <div className="sector">
                        <ReadOnlyIBox
                            type="txt"
                            mw={400}
                            title="결제 만료일"
                            value={reservItem.product.product_sales_day}
                        />
                        <ReadOnlyIBox
                            type="txt"
                            mw={350}
                            title="결제 구분"
                            value={hotdealPayTypeParse(reservItem)}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="총 결제금액"
                            value={priceParseComma(item.product_price.toString())}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="잔여 금액"
                            value={hotdealBalance(item)}
                        />
                        <ReadOnlyIBox
                            type="price"
                            mw={350}
                            title="결제된 금액"
                            value={priceParseComma(item.cumulative_amount.toString())}
                        />
                    </div>
                )}
            </Body>
        </BaseContainer>
    );
}
