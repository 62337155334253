import axios from "axios";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { CounselRegisterModal } from "../components/counselling/CounselRegisterModal";
import { CsReservInfo } from "../components/counselling/CounselReservInfo";
import { CsReservReg } from "../components/counselling/CounselReservReg";
import { CsUpdateModal } from "../components/counselling/CouselupdateModal";

const Top = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .menu {
        display: flex;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 5px;

        .backicon {
            cursor: pointer;
        }
        .title {
            color: #2d40ff;
            font-size: 20px;
            font-weight: 500;
            justify-content: center;
        }
        .space {
            width: 10px;
        }
        .moveNoti {
            width: 120px;
            padding: 5px 7px;
            border: none;
            border-radius: 7px;
            background-color: #2d40ff;
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            height: 40px;
        }
    }
    .stick {
        width: 95%;
        height: 2px;
        background-color: #eeeeee;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    .sector {
        width: 40%;
        padding: 10px;
    }
    .sector2 {
        width: 60%;
        padding: 10px;
    }

    .left {
        padding-left: 100px;
        // background-color: #fff111;
    }
    .right {
        padding-right: 40px;
        // background-color: #2d40ff;
    }

    .none {
        width: 40%;
        padding: 50px;
        align-items: center;
        justify-content: center;
        // background-color: #eeeeee;

        .addbtn {
            width: 150px;
            height: 40px;
            border: none;
            background-color: #2d40ff;
            border-radius: 5px;
            color: #ffffff;
            font-size: 15px;
            cursor: pointer;
        }
    }
`;

export function CounsellingDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [counsel, setCounsel] = useState<any>(null);
    const [isStart, setStart] = useState(false);
    const [regModal, setRegModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(0);
    const [regMode, setRegMode] = useState("");
    const [detailRef, setDetailRef] = useState("0");
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상담내역");
    setThirdTitle("상담상세");
    const item = { ...location.state } || null;

    // console.log("???!! ", item);

    useEffect(() => {
        if (item === null) {
            alert("올바르지않은 접속입니다. 재접속 해주세요");
            sessionStorage.clear();
            navigate(`/adminpage/login`);
        } else {
            if (item.counseling_state === "1") {
                setDetailRef(item.counseling_state);
                getDetail();
            }
        }
    }, []);

    const moveNotification = () => {
        navigate(`/adminpage/productnoti`, { state: { send: item.consumer_phone } });
    };

    const refBack = (v: boolean) => {
        if (v) {
            setDetailRef("1");
            getDetail();
        }
        setRegModal(false);
    };
    const backHandle = () => {
        navigate(`/adminpage/counsellinglist`, { state: { to: "refresh" } });
    };
    const refreshdetail = () => {
        getDetail();
        setUpdateModal(0);
    };
    const openModal = (mode: string) => {
        setRegMode(mode);
        setRegModal(true);
    };

    const getDetail = async () => {
        const accesstoken = sessionStorage.getItem("accesstoken");

        try {
            const res = await axios.get(`/v1/counsels/detail?reservation_id=${item.reservation_id}`, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("cdetail? ", res.data.data);
            if (res.data.code === 0) {
                setStart(true);
                setCounsel(res.data.data);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <BaseContainer>
            {regModal && (
                <CounselRegisterModal
                    open={regModal}
                    data={counsel}
                    id={item.reservation_id}
                    bemily={item.client_id === "518"}
                    mode={regMode}
                    close={refBack}
                />
            )}
            {updateModal !== 0 && (
                <CsUpdateModal
                    open={updateModal}
                    id={item.reservation_id}
                    data={counsel}
                    close={setUpdateModal}
                    refresh={refreshdetail}
                />
            )}
            <Top>
                <div className="menu">
                    <RiArrowGoBackLine
                        className="backicon"
                        size={30}
                        color={"#2d40ff"}
                        onClick={backHandle}
                    />
                    <h3 className="title">상담상세페이지</h3>
                    {detailRef === "1" ? (
                        <button
                            className="moveNoti"
                            onClick={moveNotification}
                        >
                            알림톡발송
                        </button>
                    ) : (
                        <div className="space" />
                    )}
                </div>
                <div className="stick" />
            </Top>
            <Body>
                <div className="sector left">
                    <CsReservInfo item={item} />
                </div>
                {detailRef === "1" ? (
                    isStart && (
                        <div className="sector2">
                            <CsReservReg
                                item={counsel}
                                openM={setUpdateModal}
                            />
                        </div>
                    )
                ) : (
                    <div className="none">
                        <button
                            className="addbtn"
                            onClick={() => openModal("reg")}
                        >
                            상담 등록
                        </button>
                    </div>
                )}
            </Body>
        </BaseContainer>
    );
}
