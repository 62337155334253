import { atom, useAtom, useSetAtom } from "jotai";
import styled from "styled-components";
import { BiSolidRightArrow } from "react-icons/bi";
import React, { ChangeEvent, ReactElement } from "react";
import { CustomSelectBox, levelinputAtom } from "./CustomSelectBox";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    width: 100%;
    max-width: 700px;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
`;

const StickBar = styled.div`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 1px;
    background-color: #f9f9fa;
    margin: 15px 0 15px 0;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 28px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 18px;
        outline: none;
    }
`;

export const idinputAtom = atom("");
export const nameinputAtom = atom("");
export const addrinputAtom = atom("");
export const callinputAtom = atom("");
export const emailinputAtom = atom("");
export const inchargeinputAtom = atom("");
export const phoneinputAtom = atom("");
export const dropdownAtom = atom(false);

export function RegiAtomClear() {
    const id = useSetAtom(idinputAtom);
    const level = useSetAtom(levelinputAtom);
    const name = useSetAtom(nameinputAtom);
    const addr = useSetAtom(addrinputAtom);
    const call = useSetAtom(callinputAtom);
    const email = useSetAtom(emailinputAtom);
    const incharge = useSetAtom(inchargeinputAtom);
    const phone = useSetAtom(phoneinputAtom);
    id("");
    level("B2B업체");
    name("");
    addr("");
    call("");
    email("");
    incharge("");
    phone("");
    // console.log("clear");
}

interface props {
    sort: boolean;
}

export const RegiInputBox = (props: props): ReactElement => {
    const [idinput, setId] = useAtom(idinputAtom);
    const [nameinput, setName] = useAtom(nameinputAtom);
    const [addrinput, setAddr] = useAtom(addrinputAtom);
    const [callinput, setCall] = useAtom(callinputAtom);
    const [emailinput, setEmail] = useAtom(emailinputAtom);
    const [inchargeinput, setIncharge] = useAtom(inchargeinputAtom);
    const [phoneinput, setPhone] = useAtom(phoneinputAtom);

    const idInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value);
    };
    const nameInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    const addrInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setAddr(e.target.value);
    };
    const callInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setCall(e.target.value);
    };
    const emailInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const inchargeInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setIncharge(e.target.value);
    };
    const phoneInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
    };

    return (
        <Container>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>계정 명</h3>
                </TextContainer>
                <InputBox>
                    {props.sort ? (
                        <input
                            type="text"
                            value={idinput}
                            placeholder="계정 명을 입력해주세요"
                            onChange={idInputText}
                        />
                    ) : (
                        <input
                            type="text"
                            value={idinput}
                            disabled={true}
                        />
                    )}
                </InputBox>
            </BoxContainer>
            {props.sort && (
                <BoxContainer set="start">
                    <TextContainer>
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>계정 용도</h3>
                    </TextContainer>
                    <CustomSelectBox />
                </BoxContainer>
            )}
            <StickBar />
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>회사 명</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={nameinput}
                        placeholder="회사 명을 입력해주세요"
                        onChange={nameInputText}
                    />
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>회사 주소</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={addrinput}
                        placeholder="회사 주소를 입력해주세요"
                        onChange={addrInputText}
                    />
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>회사 대표 번호</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={callinput}
                        placeholder="회사 대표 번호를 입력해주세요"
                        onChange={callInputText}
                    />
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>회사(담당자) 이메일</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={emailinput}
                        placeholder="회사(담당자) 이메일을 입력해주세요"
                        onChange={emailInputText}
                    />
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>담당자 이름</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={inchargeinput}
                        placeholder="담당자 이름을 입력해 주세요"
                        onChange={inchargeInputText}
                    />
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>담당자 연락처</h3>
                </TextContainer>
                <InputBox>
                    <input
                        type="text"
                        value={phoneinput}
                        placeholder="담당자 연락처를 입력해주세요"
                        onChange={phoneInputText}
                    />
                </InputBox>
            </BoxContainer>
        </Container>
    );
};
