import { useSetAtom } from "jotai";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { TabMenu } from "../components/common/TabMenu";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    // padding: 5px;
    align-items: start;
    // background-color: #fff111;
`;

export const imgUrl = "https://cdn-icons-png.flaticon.com/512/1555/1555492.png";

export function ProductList() {
    const location = useLocation();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const page = { ...location.state };
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("");

    return (
        <BaseContainer>
            <Container>
                <TabMenu toPage={page.to} />
            </Container>
        </BaseContainer>
    );
}
