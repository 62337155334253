import { format } from "date-fns";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";

export const RESERVSTATE = {
    CSWAITING: "상담대기",
    PAYWAITING: "결제대기",
    PAYPROGRESS: "결제진행",
    PAYCOMPLETE: "결제완료",
    PAYCANCEL: "결제취소",
};

export const stateParse = (item: any) => {
    if (Number(item.counseling_state) === 0) {
        return RESERVSTATE.CSWAITING;
    } else {
        if (item.payment_state === "HOLD") {
            if (Number(item.cumulative_amount) === 0) {
                return RESERVSTATE.PAYWAITING;
            } else {
                return RESERVSTATE.PAYPROGRESS;
            }
        } else if (item.payment_state === "DELETE") {
            return RESERVSTATE.PAYCANCEL;
        } else {
            return RESERVSTATE.PAYCOMPLETE;
        }
    }
};

export const statetxtParse = (item: any) => {
    if (item.counseling_state === 0) return "gray";
    else {
        if (item.payment_state === "HOLD") {
            if (item.cumulative_amount === 0) return "yellow";
            else return "blue";
        } else if (item.payment_state === "DELETE") return "red";
        else return "green";
    }
};

export const paymentTypeParse = (item: any) => {
    if (item.payment_type === "CREDIT") return "카드결제";
    else if (item.payment_type === "TRANSFER") return "계좌이체";
    else return "-";
};

export const productSortParse = (item: any) => {
    if (item.product_sort === "PACKAGE") return "기획상품";
    else if (item.product_sort === "AIRTEL") return "에어텔상품";
    else return "-";
};

export const HpDateParse = (date: any) => {
    // console.log("??? ", date);
    try {
        const result = date.split(" ");
        return format(new Date(result[0]), "yyyy년 MM월 dd일") + " " + result[1];
    } catch (error) {
        return "";
    }
};

export const hotdealPayTypeParse = (item: any) => {
    if (item.payment_type === 1) return "V.A.T 포함";
    else return "V.A.T 없음";
};

export const hotdealBalance = (item: any) => {
    return priceParseComma((item.product_price - item.cumulative_amount).toString());
};

export const hotdealStateColorParse = (item: any) => {
    if (item.payment_state === "HOLD") {
        return "gray";
    } else if (item.payment_state === "FINISH") {
        return "green";
    } else if (item.payment_state === "CALCULATE") {
        return "yellow";
    } else if (item.payment_state === "DELETE") {
        return "red";
    } else {
        if (item.payment_cnt === item.cumulative_pay_cnt && item.product_price === item.cumulative_amount) {
            return "green";
        } else {
            return "yellow";
        }
    }
};

export const hotdealStateParse = (item: any) => {
    if (item.payment_state === "HOLD") {
        return "결제대기";
    } else if (item.payment_state === "FINISH") {
        return "결제완료";
    } else if (item.payment_state === "CALCULATE") {
        return "결제진행중";
    } else if (item.payment_state === "DELETE") {
        return "결제취소";
    } else {
        if (item.payment_cnt === item.cumulative_pay_cnt && item.product_price === item.cumulative_amount) {
            return "결제완료";
        } else {
            return "결제진행중";
        }
    }
};

export const hotdealPathParse = (item: any) => {
    if (item.client_id === 0) {
        return "시냅틱웨이브";
    } else {
        return item.partner_name;
    }
};

export const refundTypeParse = (type: string) => {
    if (type === "PACKAGE" || type === "AIRTEL") {
        return "ATLPKG";
    } else {
        return "HOTDEAL";
    }
};
