import axios from "axios";

interface imglist {
    upload_url: string;
    result_url: string;
}

export const getS3imgContent = (cnt: number) => {
    try {
        const res = axios.get(
            `https://dtwjty5mqorenabk5t7ktrz5ky0wshzr.lambda-url.ap-northeast-2.on.aws?category=banner&count=${cnt}`
        );
        return res;
    } catch (e) {
        // 에러 처리
        alert("이미지 업로드에서 오류가 발생했습니다.\n잠시후 다시 시도해 주세요");
    }
};

export const setS3imgContent = (uurl: string, rurl: string, file: any) => {
    try {
        const res = axios.put(`${uurl}`, file);
    } catch (e) {
        // 에러 처리
        alert("이미지 업로드에서 오류가 발생했습니다.\n잠시후 다시 시도해 주세요");
    }
};

export const removeBloburl = (list: Array<any>) => {
    list.forEach((e) => {
        if (e.includes("blob")) {
            URL.revokeObjectURL(e);
        }
    });
};
