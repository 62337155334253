import { ChangeEvent, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { styled } from "styled-components";
import { arr12, arr6, calendar } from "./CalendarInterface";

const Container = styled.div`
    .columnBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 900px;
        background-color: #eeeeee;
    }

    .rowBox {
        display: flex;
        width: 100%;
        max-width: 1000px;
        // background-color: #eeeeee;
        justify-content: space-between;

        .day {
            display: flex;
            with: 180px;
            height: 42px;
            align-items: center;
            justify-content: center;
            // background-color: #fff111;

            .txt {
                width: 40px;

                h3 {
                    font-size: 13px;
                    font-weight: 500;
                    margin-right: 5px;
                    color: #2d40ff;
                }
            }
        }

        #v {
            overflow: visible;
        }
    }
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;
    margin-top: 40px;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 42px;
    align-items: end;

    input {
        width: 90px;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 5px 10px 5px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    cal: calendar;
    update: (calendar: calendar) => void;
}

export const CalUpdateInput = (props: props) => {
    const { cal, update } = props;

    const calendarParse = (mon: number, day: number) => {
        let data = "";
        switch (mon) {
            case 1:
                data = cal.jan_1.split('"' + day + '":"')[1];
                break;
            case 2:
                data = cal.feb_2.split('"' + day + '":"')[1];
                break;
            case 3:
                data = cal.mar_3.split('"' + day + '":"')[1];
                break;
            case 4:
                data = cal.apr_4.split('"' + day + '":"')[1];
                break;
            case 5:
                data = cal.may_5.split('"' + day + '":"')[1];
                break;
            case 6:
                data = cal.jun_6.split('"' + day + '":"')[1];
                break;
            case 7:
                data = cal.jul_7.split('"' + day + '":"')[1];
                break;
            case 8:
                data = cal.aug_8.split('"' + day + '":"')[1];
                break;
            case 9:
                data = cal.sep_9.split('"' + day + '":"')[1];
                break;
            case 10:
                data = cal.oct_10.split('"' + day + '":"')[1];
                break;
            case 11:
                data = cal.nov_11.split('"' + day + '":"')[1];
                break;
            case 12:
                data = cal.dec_12.split('"' + day + '":"')[1];
                break;
        }
        const result = data.split('"')[0];
        return result;
    };

    const setDay = (e: ChangeEvent<HTMLInputElement>, mon: number, day: number) => {
        switch (mon) {
            case 1:
                let d11 = cal.jan_1.split('"' + day + '":"');
                let d12 = d11[1].split('","' + (day + 1) + '"')[1];
                let result1 = "";
                if (day === 31) {
                    result1 = d11[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result1 = d11[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d12;
                }
                const cald1 = {
                    jan_1: result1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald1);
                break;
            case 2:
                let d21 = cal.feb_2.split('"' + day + '":"');
                let d22 = d21[1].split('","' + (day + 1) + '"')[1];
                let result2 = "";
                if (day === 28) {
                    result2 = d21[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result2 = d21[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d22;
                }
                const cald2 = {
                    jan_1: cal.jan_1,
                    feb_2: result2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald2);
                break;
            case 3:
                let d31 = cal.mar_3.split('"' + day + '":"');
                let d32 = d31[1].split('","' + (day + 1) + '"')[1];
                let result3 = "";
                if (day === 31) {
                    result3 = d31[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result3 = d31[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d32;
                }
                const cald3 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: result3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald3);
                break;
            case 4:
                let d41 = cal.apr_4.split('"' + day + '":"');
                let d42 = d41[1].split('","' + (day + 1) + '"')[1];
                let result4 = "";
                if (day === 31) {
                    result4 = d41[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result4 = d41[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d42;
                }
                const cald4 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: result4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald4);
                break;
            case 5:
                let d51 = cal.may_5.split('"' + day + '":"');
                let d52 = d51[1].split('","' + (day + 1) + '"')[1];
                let result5 = "";
                if (day === 31) {
                    result5 = d51[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result5 = d51[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d52;
                }
                const cald5 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: result5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald5);
                break;
            case 6:
                let d61 = cal.jun_6.split('"' + day + '":"');
                let d62 = d61[1].split('","' + (day + 1) + '"')[1];
                let result6 = "";
                if (day === 30) {
                    result6 = d61[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result6 = d61[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d62;
                }
                const cald6 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: result6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald6);
                break;
            case 7:
                let d71 = cal.jul_7.split('"' + day + '":"');
                let d72 = d71[1].split('","' + (day + 1) + '"')[1];
                let result7 = "";
                if (day === 31) {
                    result7 = d71[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result7 = d71[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d72;
                }
                const cald7 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: result7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald7);
                break;
            case 8:
                let d81 = cal.aug_8.split('"' + day + '":"');
                let d82 = d81[1].split('","' + (day + 1) + '"')[1];
                let result8 = "";
                if (day === 31) {
                    result8 = d81[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result8 = d81[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d82;
                }
                const cald8 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: result8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald8);
                break;
            case 9:
                let d91 = cal.sep_9.split('"' + day + '":"');
                let d92 = d91[1].split('","' + (day + 1) + '"')[1];
                let result9 = "";
                if (day === 30) {
                    result9 = d91[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result9 = d91[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d92;
                }
                const cald9 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: result9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald9);
                break;
            case 10:
                let d101 = cal.oct_10.split('"' + day + '":"');
                let d102 = d101[1].split('","' + (day + 1) + '"')[1];
                let result10 = "";
                if (day === 31) {
                    result10 = d101[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result10 = d101[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d102;
                }
                const cald10 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: result10,
                    nov_11: cal.nov_11,
                    dec_12: cal.dec_12,
                };
                update(cald10);
                break;
            case 11:
                let d111 = cal.nov_11.split('"' + day + '":"');
                let d112 = d111[1].split('","' + (day + 1) + '"')[1];
                let result11 = "";
                if (day === 30) {
                    result11 = d111[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result11 = d111[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d112;
                }
                const cald11 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: result11,
                    dec_12: cal.dec_12,
                };
                update(cald11);
                break;
            case 12:
                let d121 = cal.dec_12.split('"' + day + '":"');
                let d122 = d121[1].split('","' + (day + 1) + '"')[1];
                let result12 = "";
                if (day === 31) {
                    result12 = d121[0] + '"' + day + '":"' + e.target.value + '"}';
                } else {
                    result12 = d121[0] + '"' + day + '":"' + e.target.value + '","' + (day + 1) + '"' + d122;
                }
                const cald12 = {
                    jan_1: cal.jan_1,
                    feb_2: cal.feb_2,
                    mar_3: cal.mar_3,
                    apr_4: cal.apr_4,
                    may_5: cal.may_5,
                    jun_6: cal.jun_6,
                    jul_7: cal.jul_7,
                    aug_8: cal.aug_8,
                    sep_9: cal.sep_9,
                    oct_10: cal.oct_10,
                    nov_11: cal.nov_11,
                    dec_12: result12,
                };
                update(cald12);
                break;
        }
    };

    return (
        <Container>
            <p>{"'d' 는 deadline 의 약자로 사용 마감되었다는 표시로 사용 "}</p>
            <p>{"'n' 은 none 의 약자로 옵션없이 일반 표시로 사용"}</p>
            <p>{"'m' 은 minimum 의 약자로 최저가 표시로 사용"}</p>
            <p>{"가격은 최소 만원단위 입력 (ex. 10-n >> 10만원 옵션X) "}</p>
            <div className="stick" />
            {arr12.map((md: number, a: number) => {
                return (
                    <>
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>{a + 1}월달</h3>
                        </TextContainer>
                        <div className="columnbox">
                            {arr6.map((item: any, i: number) => {
                                return (
                                    <div className="rowBox">
                                        {arr6.map((item: any, j: number) => {
                                            let num = j + 1 + i * 6;
                                            if (num < md + 1) {
                                                return (
                                                    <div className="day">
                                                        <div className="txt">
                                                            <h3>{num + "일"}</h3>
                                                        </div>
                                                        <InputBox>
                                                            <input
                                                                key={num}
                                                                type="text"
                                                                value={calendarParse(a + 1, num)}
                                                                placeholder={"1월 " + num + "일"}
                                                                onChange={(e) => setDay(e, a + 1, num)}
                                                            />
                                                        </InputBox>
                                                    </div>
                                                );
                                            } else {
                                                return <div />;
                                            }
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            })}
        </Container>
    );
};
