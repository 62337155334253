import axios from "axios";
import { useAtom, useSetAtom } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import sha256 from "sha256";
import { createBrowserHistory } from "history";
import LoadingSpinner from "../../common/LoadingSpinner";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

const BoxContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        text-size-adjust: auto;
        margin-left: 10px;
        margin-right: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
    }
`;

const InputBox = styled.div<{ flag: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 28px;
        border: 1px solid ${(props) => (props.flag ? "red" : "#eeeeee")};
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 18px;
        outline: none;
    }
`;

const BtnContainer = styled.div`
    display: flex;
    width: 170px;
    height: 40px;
    margin-top: 50px;
    justify-content: space-between;
`;

const Btn = styled.button<{ type: boolean }>`
    display: flex;
    width: 80px;
    height: 40px;
    cursor: pointer;
    background-color: ${(props) => (props.type ? "#dcdeeb" : "#2d40ff")};
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: ${(props) => (props.type ? "#707288" : "#ffffff")};
    font-weight: 500;
`;

export function ChangePwd() {
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    const history = createBrowserHistory();
    const [origin, setOrigin] = useState("");
    const [newpwd, setNewPwd] = useState("");
    const [pwd, setPwd] = useState("");
    const [flag, setFlag] = useState(false);
    const [equal, setEqual] = useState(false);
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    setSelectMenu(5);
    setTitle("MY INFO");
    setSubTitle("비밀번호 변경");
    setThirdTitle("");

    const InputOriginPwd = (e: ChangeEvent<HTMLInputElement>) => {
        setOrigin(e.target.value);
    };
    const InputNewPwd = (e: ChangeEvent<HTMLInputElement>) => {
        setNewPwd(e.target.value);
    };
    const InputPwd = (e: ChangeEvent<HTMLInputElement>) => {
        setPwd(e.target.value);
    };

    const setPWD = async () => {
        if (flag) {
            alert("변경하실 비밀번호를 확인해주세요.");
            return;
        }

        if (equal) {
            alert("이전 비밀번호와 변경하실 비밀번호가 동일합니다.");
            return;
        }
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");

        let body = {
            origin_pw: sha256(origin),
            new_pw: sha256(newpwd),
        };

        try {
            const res = await axios.patch(`/v1/partners/password`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("patch pwd: ", res.data);
            // 로딩 종료
            setShow(false);
            if (res.data.code === 0) {
                // 변경 성공
                alert("비밀번호 변경이 완료 되었습니다");
                history.back();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        if (origin !== newpwd) {
            setEqual(false);
        } else {
            setEqual(true);
        }
    }, [newpwd, origin]);

    useEffect(() => {
        if (newpwd !== pwd) {
            setFlag(true);
        } else {
            setFlag(false);
        }
    }, [newpwd, pwd]);

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <Container>
                <BoxContainer>
                    <TextContainer>
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>기존 비밀번호</h3>
                    </TextContainer>
                    <InputBox flag={false}>
                        <input
                            type="password"
                            value={origin}
                            placeholder="현재 비밀번호를 입력해주세요"
                            onChange={InputOriginPwd}
                        />
                    </InputBox>
                </BoxContainer>
                <BoxContainer>
                    <TextContainer>
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>신규 비밀번호</h3>
                    </TextContainer>
                    <InputBox flag={flag}>
                        <input
                            type="password"
                            value={newpwd}
                            placeholder="변경하실 비밀번호를 입력해주세요"
                            onChange={InputNewPwd}
                        />
                    </InputBox>
                </BoxContainer>
                <BoxContainer>
                    <TextContainer>
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <h3>비밀번호 확인</h3>
                    </TextContainer>
                    <InputBox flag={flag}>
                        <input
                            type="password"
                            value={pwd}
                            placeholder="변경하실 비밀번호를 다시 입력해 주세요"
                            onChange={InputPwd}
                        />
                    </InputBox>
                </BoxContainer>
                <BtnContainer>
                    <Btn
                        type={true}
                        onClick={() => history.back()}
                    >
                        취소
                    </Btn>
                    <Btn
                        type={false}
                        onClick={setPWD}
                    >
                        등록
                    </Btn>
                </BtnContainer>
            </Container>
        </BaseContainer>
    );
}
