import { useSetAtom } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router-dom";
import { BiSolidRightArrow } from "react-icons/bi";
import { bottom2_txt, bottom_txt, calltime, DocuContentTxt, head_txt } from "../components/common/DocumentContentTxt";
import axios from "axios";
import LoadingSpinner from "../../common/LoadingSpinner";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    // background-color: #fff111;

    .viewBox {
        width: 25%;
        padding: 20px;
        // background-color: #fff111;
    }
    .blankBox {
        width: 5%;
        padding: 20px;
        // background-color: #22aaff;
    }
    .blockBox {
        display: block;
        overflow: auto;
        width: 40%;
        padding: 10px;
        // background-color: #fa22ff;
    }
    .column {
        display: flex;
        flex-direction: column;
    }
    .center {
        align-items: center;
        justify-content: center;
    }
    .marginT {
        margin-top: 10px;
    }
    .pbox {
        width: 100%;
        max-width: 300px;
        height: 600px;
        padding: 5px 10px;
        border: 1px solid #2c326b;
        background-color: #2c326b;
        align-items: center;
        border-radius: 50px 50px 50px 50px;
    }
    .dispbox {
        width: 90%;
        height: 450px;
        padding: 0px 10px;
        background-color: #fff111;
        border: 1px solid #eeeeee;
        margin-top: 20px;
        gap: 80px;
    }
    .dispbbox {
        display: flex;
        width: 90%;
        height: 50px;
        padding: 0px 10px;
        background-color: #fff111;
        border: 1px solid #eeeeee;
        align-items: center;
        justify-content: center;
        gap: 80px;
    }
    .content {
        width: 100%;
        height: 450px;
        border: none;
        background-color: #fff111;
        font-size: 11px;
        font-weight: 500;
        color: #2c326b;
    }

    .btncontainer {
        display: flex;
        flex-direction: column;
        width: 15%;
        padding: 60px 0;
        // background-color: #fff111;
    }
    .sendBtn {
        width: 100%;
        max-width: 130px;
        height: 45px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        background-color: #2d40ff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }
    .topblank {
        margin-top: 50px;
    }
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    // height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .text {
        display: flex;
        width: 100%;
        max-width: 120px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 500px;
        line-height: 5px;
        border: 2px solid #2c326b;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

const CounselInputBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 450px;
    align-items: start;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .text {
        display: flex;
        width: 100%;
        max-width: 120px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    .inputbox {
        height: 400px;
        width: 100%;
        max-width: 500px;
        border: 2px solid #2c326b;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        background-color: #ffffff;
    }

    textarea {
        // background-color: #fff111;
        height: 390px;
        resize: none;
        border: 0;
        font-size: 15px;
        outline: none;
        line-height: 15px;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
    }
`;

const RowScroll = styled.div`
    width: 100%;
    max-width: 500px;
    // margin-left: 100px;
    overflow: scroll;
    padding: 10px;
    background-color: #eeeeee;
    gap: 10px;
    border: 2px solid #2c326b;
    border-radius: 5px;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.4);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 6px;
    }
`;

const RowContainer = styled.div<{ c: number }>`
    display: flex;
    width: ${(props) => props.c * 135}px;
    gap: 10px;
`;

const TextBtn = styled.button`
    width: 130px;
    height: 40px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    background-color: ${(props) => props.color};
    border: none;
    border-radius: 8px;
    cursor: pointer;
`;

export function ProductNoti() {
    const history = createBrowserHistory();
    const location = useLocation();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));
    const phone = { ...location.state };
    const [isShow, setShow] = useState(false);
    const [content, setContent] = useState<string>("");
    const [notiphone, setNotiphone] = useState<string>(phone.send || "");
    const [clist, setClist] = useState(DocuContentTxt);
    setSelectMenu(2);
    setTitle("알림톡");
    setSubTitle("");
    setThirdTitle("");

    useEffect(() => {
        if (partnerlv < 1) {
            alert("해당 페이지 접근 권한이 없습니다.");
            history.back();
        }
    }, []);

    const contentInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    };
    const phoneInput = (e: ChangeEvent<HTMLInputElement>) => {
        setNotiphone(e.target.value);
    };

    const sendNotiMsg = async () => {
        if (notiphone === "") {
            alert("수신받을 연락처를 입력해 주세요");
            return;
        }
        if (content === "") {
            alert("발신할 메시지 내용을 입력해 주세요");
            return;
        }

        setShow(true);

        const body = {
            reservation_info: content + calltime,
            reservation_note: "",
        };

        try {
            const res = await axios.post(`https://noti-office.wafflestay.kr/aligo/kakao/NP/${notiphone}`, body);
            setShow(false);
            // console.log("counsel: ", res.data);
            if (res.data.code === 0) {
                alert(res.data.message);
                if (phone.send !== undefined) {
                    history.back();
                }
            }
        } catch (e: any) {
            // 에러 처리
            // console.log(e);
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <Container>
                <div className="blankBox" />
                <div className="viewBox">
                    <div className="column center marginT">
                        <div className="pbox column marginT">
                            <BsCircle
                                className="marginT"
                                color="#ffffff"
                                size={15}
                            />
                            <div className="dispbox">
                                <textarea
                                    className="content"
                                    value={head_txt + content + calltime + bottom_txt + bottom2_txt}
                                    readOnly
                                />
                            </div>
                            <div className="dispbbox">
                                <RxHamburgerMenu
                                    color="#ffffff"
                                    size={25}
                                />
                                <BsCircle
                                    color="#ffffff"
                                    size={25}
                                />
                                <AiOutlineArrowLeft
                                    color="#ffffff"
                                    size={25}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blockBox">
                    <BoxContainer
                        className="topblank"
                        set="stretch"
                    >
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>수신 받는 번호</h3>
                        </div>
                        <input
                            type="text"
                            value={notiphone}
                            placeholder="수신받을 연락처를 입력해 주세요"
                            onChange={phoneInput}
                            disabled={phone.send !== undefined}
                        />
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>MSG 템플릿</h3>
                        </div>
                        <RowScroll>
                            <RowContainer c={clist.length}>
                                {clist.map((item: any, i: number) => {
                                    return (
                                        <TextBtn
                                            key={i}
                                            color={item.color}
                                            onClick={() => setContent(item.text)}
                                        >
                                            {item.title}
                                        </TextBtn>
                                    );
                                })}
                            </RowContainer>
                        </RowScroll>
                    </BoxContainer>
                    <CounselInputBox set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>알림톡 내용</h3>
                        </div>
                        <div className="inputbox">
                            <textarea
                                placeholder="알림톡으로 발송할 내용을 입력해 주세요"
                                onChange={contentInput}
                                value={content}
                            />
                        </div>
                    </CounselInputBox>
                </div>
                <div className="btncontainer">
                    <button
                        className="sendBtn"
                        onClick={sendNotiMsg}
                    >
                        전송요청
                    </button>
                </div>
            </Container>
        </BaseContainer>
    );
}
