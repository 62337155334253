import { useEffect } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { BiSolidRightArrow } from "react-icons/bi";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // background-color: #eeeeee;

    .stick {
        display: flex;
        width: 75%;
        height: 1px;
        background-color: #eeeeee;
    }
`;

const PersonBox = styled.div`
    display: block;
    overflow: auto;
    width: 100%;
    max-width: 500px;
    height: 250px;
    padding-left: 30px;
    // background-color: #fff111;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }

    .text {
        display: flex;
        width: 150%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    .text2 {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .pinput {
        height: 20px;
        width: 100%;
        max-width: 220px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    item: any;
}

export const CsReservInfo = (props: props) => {
    const { item } = props;

    const personlist = item.with_person === 0 ? [] : JSON.parse(item.persons_info);

    return (
        <Container>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>구매번호</h3>
                </div>
                <input
                    type="text"
                    value={item.reservation_id}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>구매상품</h3>
                </div>
                <input
                    type="text"
                    value={item.product_name}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>구매자명</h3>
                </div>
                <input
                    type="text"
                    value={item.consumer_name}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>연락처</h3>
                </div>
                <input
                    type="text"
                    value={item.consumer_phone}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>이메일</h3>
                </div>
                <input
                    type="text"
                    value={item.consumer_email}
                    readOnly
                />
            </BoxContainer>
            <BoxContainer set="stretch">
                <div className="text">
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>동행자 수</h3>
                </div>
                <input
                    type="text"
                    value={item.with_persons}
                    readOnly
                />
            </BoxContainer>
            {item.with_persons > 0 && (
                <>
                    <PersonBox>
                        {personlist.map((data: any, i: number) => {
                            return (
                                <>
                                    <BoxContainer set="stretch">
                                        <div className="text2">
                                            <AiOutlineTeam
                                                color="#6f7cff"
                                                size={20}
                                            />
                                            <h3>동행자 {i + 1}</h3>
                                        </div>
                                        <input
                                            className="pinput"
                                            type="text"
                                            value={"이름: " + data.person}
                                            readOnly
                                        />
                                    </BoxContainer>
                                    <BoxContainer set="stretch">
                                        <div className="text2" />
                                        <input
                                            className="pinput"
                                            type="text"
                                            value={"생년월일: " + data.birth}
                                            readOnly
                                        />
                                    </BoxContainer>
                                </>
                            );
                        })}
                    </PersonBox>
                    <div className="stick" />
                </>
            )}
        </Container>
    );
};
