import { atom, useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { FaUserCircle } from "react-icons/fa";

const TopContainer = styled.div`
    display: flex;
    width: 100%;
    // max-width: 1720px;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
`;

const LogoutBtn = styled.button`
    display: flex;
    margin-right: 30px;
    width: 66px;
    height: 30px;
    border-radius: 5px;
    background: #8394a7;
    padding: 3px 0px 0px 0px;
    color: #fff;
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const MenuTitle = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 30px;
    letter-spacing: 1.5px;
    border: none;
    align-items: center;

    img {
        margin-left: 20px;
        color: #2d40ff;
    }

    .title {
        margin-left: 10px;
        font-size: 20px;
        color: #2d40ff;
        font-weight: 700;
    }

    .subtitle {
        margin-left: 10px;
        font-size: 15px;
        color: #8a8b9d;
        font-weight: 400;
    }

    .thirdtitle {
        margin-left: 10px;
        font-size: 14px;
        color: #8a8b9d;
        font-weight: 400;
    }
`;

const Bar = styled.div`
    display: flex;
    background-color: #f1f4f8;
    width: 2px;
    height: 19px;
    margin: 0px 15px 0px 15px;
`;

const EndMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    letter-spacing: 0.5px;
    border: none;
    align-items: center;

    .account {
        font-size: 13px;
        color: #122437;
        margin-left: 5px;
    }
`;

export const titleAtom = atom("");
export const subtitleAtom = atom("");
export const thirdtitleAtom = atom("");
export default function NavTopBar() {
    const navigate = useNavigate();
    const title = useAtomValue(titleAtom);
    const subtitle = useAtomValue(subtitleAtom);
    const thirdtitle = useAtomValue(thirdtitleAtom);
    const accountid = sessionStorage.getItem("partnerid");

    const logoutHandle = () => {
        sessionStorage.clear();
        navigate(`/adminpage/login`);
    };

    return (
        <header>
            <TopContainer>
                <MenuTitle>
                    <img
                        src="/img/top_ic_dashboard.svg"
                        sizes="30"
                        alt="dashboard"
                    />
                    <h3 className="title">{"" + title}</h3>
                    {subtitle !== "" && <h3 className="subtitle">{"> " + subtitle}</h3>}
                    {thirdtitle !== "" && <h3 className="thirdtitle">{"> " + thirdtitle}</h3>}
                </MenuTitle>
                <EndMenuContainer>
                    <FaUserCircle
                        size={30}
                        color={"#d1e0f5"}
                    />
                    <h3 className="account">{accountid}</h3>
                    <Bar />
                    <LogoutBtn onClick={logoutHandle}>로그아웃</LogoutBtn>
                </EndMenuContainer>
            </TopContainer>
        </header>
    );
}
