import { useState, useRef, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { BiSolidRightArrow } from "react-icons/bi";
import { imgUrl } from "../../view/ProductListView";
import { LuCross } from "react-icons/lu";
import DetailImgBox from "./Detail_ImgBox";
import { TYPE_PRODUCT_DETAIL } from "./TabMenu";
import { updateBox } from "./ProductDetail_Image";

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // background-color: #2d40ff;
`;

const DetailContainer = styled.div`
    display: flex;
    width: 70%;
    max-width: 600px
    height: 42px;
    align-items: center;
    justify-content: space-between;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

const AddImgBtn = styled.div`
    display: flex;
    width: 120px;
    height: 42px;
    cursor: pointer;
    align-items: center;
    // background-color: #eeeeee;
    padding: 0px 0px 0px 10px;
    margin-right: 50px;

    .addimgbox {
        font-size: 20px;
        color: #2d40ff;
        font-weight: 600;
        margin-left: 5px;
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    // height: 500px;
    padding-left: 15px;
    // background-color: #fff000;
`;

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: stretch;
`;

interface props {
    detailData: (list: Array<Box>) => void;
    type: string;
}

export interface Box {
    image_url: string;
    file: any;
    ratio: string;
    order_by: number;
}

const DetailImgUploader = (props: props) => {
    const { detailData, type } = props; // props로 함수 받기
    const imgUploadInput = useRef<HTMLInputElement | null>(null);
    const [thumbnail, setThumbnail] = useState<string>(imgUrl);
    const [file, setFile] = useState<FileList | null>();
    const [boxlist, setBox] = useState<Array<Box>>([]);
    const [boxcolumn, setBoxColumn] = useState<Array<number>>([]);
    const boxitem = {
        image_url: imgUrl,
        file: null,
        ratio: "",
        order_by: 0,
    };

    useEffect(() => {
        setBox([boxitem]);
        detailData([boxitem]);
        setBoxColumn([0]);
    }, []);

    const AddBox = () => {
        if (boxlist.length === (type === TYPE_PRODUCT_DETAIL ? 6 : 10)) {
            alert("상세이미지는 최대 " + (type === TYPE_PRODUCT_DETAIL ? 6 : 10) + "장 까지만 가능합니다.");
            return;
        }
        if (Math.ceil((boxlist.length + 1) / 2) > boxcolumn.length) {
            setBoxColumn([0, ...boxcolumn]);
        }
        setBox([...boxlist, boxitem]);
        detailData([...boxlist, boxitem]);
    };

    const onImageChange = (seq: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (event.target.files!.length > 1) {
                alert("이미지는 한장만 선택 할 수 있습니다.");
                return;
            }

            if (boxlist[seq].image_url !== "") {
                setFile(null);
            }

            // console.log(event.target.files);
            // setFile(event.target.files);
            const arr = boxlist.splice(seq, 1);
            if (arr[0].image_url.includes("blob")) {
                URL.revokeObjectURL(arr[0].image_url);
            }
            const boxitem = {
                image_url: URL.createObjectURL(event.target.files![0]),
                file: event.target.files![0],
                ratio: arr[0].ratio,
                order_by: arr[0].order_by,
            };
            boxlist.splice(seq, 0, boxitem);
            setBox([...boxlist]);
            detailData([...boxlist]);
        }
    };
    const onBoxRemove = (seq: number): void => {
        if (boxlist.length == 1) {
            alert("상세이미지는 최소 1장 이상이 필요합니다.");
            return;
        }
        boxlist.splice(seq, 1);
        if (Math.ceil(boxlist.length / 2) < boxcolumn.length) {
            boxcolumn.splice(0, 1);
            setBoxColumn([...boxcolumn]);
        }
        setBox([...boxlist]);
        detailData([...boxlist]);
    };
    const ratioInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = boxlist.splice(seq, 1);
        const boxitem = {
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: e.target.value,
            order_by: arr[0].order_by,
        };
        boxlist.splice(seq, 0, boxitem);
        setBox([...boxlist]);
        detailData([...boxlist]);
    };
    const orderbyInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = boxlist.splice(seq, 1);
        const boxitem = {
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: arr[0].ratio,
            order_by: e.target.valueAsNumber,
        };
        boxlist.splice(seq, 0, boxitem);
        setBox([...boxlist]);
        detailData([...boxlist]);
    };

    let lc = 0;

    return (
        <BoxContainer>
            <DetailContainer>
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={10}
                    />
                    <h3>{type === TYPE_PRODUCT_DETAIL ? "상세 이미지 (최대 6장)" : "상품 이미지 (최대 10장)"}</h3>
                </TextContainer>
                <AddImgBtn onClick={AddBox}>
                    <LuCross
                        color="2d40ff"
                        size={20}
                    />
                    <h3 className="addimgbox">Box 추가</h3>
                </AddImgBtn>
            </DetailContainer>
            <ListContainer>
                {boxlist.length > 0 &&
                    boxcolumn.map((b: any, j: number) => {
                        let c1 = lc + j;
                        let c2 = lc + 1 + j;
                        if (boxcolumn.length > j + 1) {
                            lc++;
                        } else {
                            lc = 0;
                        }
                        return (
                            <RowContainer key={j}>
                                <DetailImgBox
                                    type={type}
                                    fileURL={boxlist[c1].image_url}
                                    ratio={boxlist[c1].ratio}
                                    order={boxlist[c1].order_by}
                                    ratiohandle={ratioInput}
                                    orderhandle={orderbyInput}
                                    removeBox={onBoxRemove}
                                    uploadImg={onImageChange}
                                    seq={c1}
                                />
                                {boxlist.length > c2 && (
                                    <DetailImgBox
                                        type={type}
                                        fileURL={boxlist[c2].image_url}
                                        ratio={boxlist[c2].ratio}
                                        order={boxlist[c2].order_by}
                                        ratiohandle={ratioInput}
                                        orderhandle={orderbyInput}
                                        removeBox={onBoxRemove}
                                        uploadImg={onImageChange}
                                        seq={c2}
                                    />
                                )}
                            </RowContainer>
                        );
                    })}
            </ListContainer>
        </BoxContainer>
    );
};

export default DetailImgUploader;
