import { useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import LoadingSpinner from "../../common/LoadingSpinner";
import { subtitleAtom, thirdtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";
import { createBrowserHistory } from "history";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "./ProductListView";
import axios from "axios";
import DetailImgUploader from "../components/common/DetailimgAdd";
import { RegisterVisibleBox } from "../../common/RegisterVisibleBox";
import ThumbnailImgUploader from "../components/common/ThumbnailAdd";
import { TYPE_AIRTEL_IMG } from "../components/common/TabMenu";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../components/common/updateimgInterface";

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    height: 74vh;
    // background-color: #2d40ff;
`;

const Container = styled.div<{ width: string; val: string }>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.val};
    width: ${(props) => props.width};
    // background-color: #fff000;

    .title {
        margin-bottom: 10px;
    }

    .detail {
        margin-top: 30px;
    }
`;

const StickBar = styled.div`
    width: 2px;
    height: 60vh;
    margin-top: 70px;
    background-color: #eeeeee;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const Box2Container = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 500px;
    height: 150px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
`;

const ImgAddContainer = styled.div`
    display: flex;
    width: 300px;
    height: 150px;
    align-items: center;
    // justify-content: start;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

const BtnContainer = styled.div`
    display: flex;
    width: 65%;
    height: 40px;
    justify-content: end;
    // background-color: #000000;
`;

const Btn = styled.button<{ type: boolean }>`
    display: flex;
    width: 80px;
    height: 40px;
    cursor: pointer;
    background-color: ${(props) => (props.type ? "#dcdeeb" : "#2d40ff")};
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: ${(props) => (props.type ? "#707288" : "#ffffff")};
    font-weight: 500;
    margin-left: 10px;
`;

interface airtel_img_box {
    image_url: string;
    file: any;
    order_by: number;
}

interface calendar {
    jan_1: string;
    feb_2: string;
    mar_3: string;
    apr_4: string;
    may_5: string;
    jun_6: string;
    jul_7: string;
    aug_8: string;
    sep_9: string;
    oct_10: string;
    nov_11: string;
    dec_12: string;
}

interface airtel_info {
    title: string;
    main_img: string;
    subtitle: string;
    airline: string;
    airtel_date: string;
    origin_price: number;
    discount_price: number;
    visible: number;
    order_by: number;
    airtel_img_list: Array<airtel_img_box>;
    calendar: calendar;
}

let basic_cal: calendar = {
    jan_1: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    feb_2: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d"}',
    mar_3: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    apr_4: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    may_5: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    jun_6: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    jul_7: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    aug_8: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    sep_9: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    oct_10: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
    nov_11: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d"}',
    dec_12: '{"1":"0-d","2":"0-d","3":"0-d","4":"0-d","5":"0-d","6":"0-d","7":"0-d","8":"0-d","9":"0-d","10":"0-d","11":"0-d","12":"0-d","13":"0-d","14":"0-d","15":"0-d","16":"0-d","17":"0-d","18":"0-d","19":"0-d","20":"0-d","21":"0-d","22":"0-d","23":"0-d","24":"0-d","25":"0-d","26":"0-d","27":"0-d","28":"0-d","29":"0-d","30":"0-d","31":"0-d"}',
};

let basic_info: airtel_info = {
    title: "",
    main_img: imgUrl,
    subtitle: "",
    airline: "",
    airtel_date: "",
    origin_price: 0,
    discount_price: 0,
    visible: 1,
    order_by: 0,
    airtel_img_list: [],
    calendar: basic_cal,
};

export function AirtelRegister() {
    const navigate = useNavigate();
    const history = createBrowserHistory();
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    const setThirdTitle = useSetAtom(thirdtitleAtom);
    const [airtel, setAirtel] = useState<airtel_info>(basic_info);
    const [file, setFile] = useState<FileList | null>();
    const [isShow, setShow] = useState(false);
    const [mainImg, setMainimg] = useState("");
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const [s3flag, setS3flag] = useState(0);
    setSelectMenu(2);
    setTitle("상품관리");
    setSubTitle("상품목록");
    setThirdTitle("에어텔등록");

    if (Number(sessionStorage.getItem("partnerlv")) < 2) {
        alert("권한이 맞지 않습니다.");
        navigate(`/adminpage/productlist`, { state: { to: `airtel` } });
    }

    const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            if (airtel!.main_img !== "") {
                onImageRemove();
            }
            setFile(event.target.files);
            airtel!.main_img = URL.createObjectURL(event.target.files![0]);
            setAirtel(airtel);
        }
    };
    const onImageRemove = (): void => {
        if (airtel!.main_img.includes("blob")) {
            URL.revokeObjectURL(airtel!.main_img);
        }
        const data: airtel_info = {
            title: airtel!.title,
            main_img: imgUrl,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data); // 렌더링 이미지 초기화
        setFile(null);
    };
    const regAirtel = (list: Array<airtel_img_box>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const visibleSelect = (v: number) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: v,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const titleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: e.target.value,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const subtitleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: e.target.value,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const airlineInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: e.target.value,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const dateInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: e.target.value,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const priceInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: imgUrl,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: e.target.valueAsNumber,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const discountInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: e.target.valueAsNumber,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const orderInput = (e: ChangeEvent<HTMLInputElement>) => {
        const data: airtel_info = {
            title: airtel!.title,
            main_img: airtel!.main_img,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: e.target.valueAsNumber,
            airtel_img_list: airtel!.airtel_img_list,
            calendar: airtel!.calendar,
        };
        setAirtel(data);
    };
    const cancelBtnHandle = () => {
        let arr = [];
        arr.push(airtel.main_img);
        airtel.airtel_img_list.forEach((e) => {
            arr.push(e.image_url);
        });
        removeBloburl(arr);
        navigate(`/adminpage/productlist`, { state: { to: `airtel` } });
    };

    useEffect(() => {
        if (s3flag == 1) {
            setImgS3list();
        } else if (s3flag == 2) {
            regAirtelData();
        }
    }, [s3flag]);

    const setImgS3main = async () => {
        if (airtel!.title === "") {
            alert("패키지 상품 명을 확인해 주세요");
            return;
        }
        if (airtel!.origin_price < 1) {
            alert("패키지 상품 원가를 확인해 주세요");
            return;
        }
        if (airtel!.order_by === 0) {
            alert("패키지 상품 표현 순서를 확인해 주세요");
            return;
        }
        if (airtel!.main_img === imgUrl) {
            alert("패키지 상품 썸네일 이미지를 확인해 주세요");
            return;
        }
        if (airtel!.airtel_img_list.length > 0) {
            let imgflag = false;
            let orderflag = false;
            for (let i = 0; i < airtel!.airtel_img_list.length; i++) {
                if (airtel!.airtel_img_list[i].image_url === imgUrl) {
                    imgflag = true;
                    break;
                } else if (airtel!.airtel_img_list[i].order_by === 0) {
                    orderflag = true;
                    break;
                }
            }
            if (imgflag) {
                alert("상세 이미지가를 확인해 주세요");
                return;
            } else if (orderflag) {
                alert("상세 이미지 표현순서를 확인해 주세요");
                return;
            }
        }

        setShow(true);
        setS3flag(0);

        if (airtel.main_img.includes("blob")) {
            getS3imgContent(1)?.then(({ data }) => {
                const list = data.data.list;
                setS3imgContent(list[0].upload_url, list[0].result_url, file![0]);
                URL.revokeObjectURL(airtel.main_img);
                setMainimg(list[0].result_url);
            });
        } else {
            setMainimg(airtel.main_img);
        }
        setShow(false);
        setS3flag(1);
    };

    const setImgS3list = async () => {
        setShow(true);

        getS3imgContent(airtel.airtel_img_list.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < airtel.airtel_img_list.length; i++) {
                if (airtel.airtel_img_list[i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, airtel.airtel_img_list[i].file);
                    URL.revokeObjectURL(airtel.airtel_img_list[i].image_url);
                    const boxitem = {
                        image_url: list[i].result_url,
                        order_by: airtel.airtel_img_list[i].order_by,
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(2);
        });
    };

    const regAirtelData = async () => {
        // 로딩 시작
        setShow(true);

        const accesstoken = sessionStorage.getItem("accesstoken");

        const body: airtel_info = {
            title: airtel!.title,
            main_img: mainImg,
            subtitle: airtel!.subtitle,
            airline: airtel!.airline,
            airtel_date: airtel!.airtel_date,
            origin_price: airtel!.origin_price,
            discount_price: airtel!.discount_price,
            visible: airtel!.visible,
            order_by: airtel!.order_by,
            airtel_img_list: imgArr,
            calendar: airtel!.calendar,
        };

        try {
            const res = await axios.post(`/v1/airtels`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("modal: ", res.data);
            // 로딩 종료
            setShow(false);
            setMainimg("");
            setImgArr([]);
            if (res.data.code === 0) {
                alert("에어텔 배너 정보 등록이 완료되었습니다.");
                navigate(`/adminpage/productlist`, { state: { to: `airtel` } });
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                navigate(`/adminpage/login`);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <BaseContainer>
            {isShow && <LoadingSpinner />}
            <RowContainer>
                <Container
                    width="40%"
                    val="50px"
                >
                    <h3 className="title">에어텔 상품 등록</h3>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상품 명</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.title}
                                placeholder="상품 명을 입력해주세요"
                                onChange={titleInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>태그 문구</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.subtitle}
                                placeholder="태그 문구를 입력해주세요"
                                onChange={subtitleInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>항공사</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.airline}
                                placeholder="항공사들을 입력해주세요"
                                onChange={airlineInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>여행 일정</h3>
                        </TextContainer>
                        <InputBox width="250px">
                            <input
                                type="text"
                                value={airtel!.airtel_date}
                                placeholder="여행 일정을 입력해주세요"
                                onChange={dateInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>판매 원가</h3>
                        </TextContainer>
                        <InputBox width="150px">
                            <input
                                type="number"
                                value={airtel!.origin_price}
                                placeholder="금액을 입력해주세요 (숫자만)"
                                onChange={priceInput}
                            />
                        </InputBox>
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>할인가</h3>
                        </TextContainer>
                        <InputBox width="150px">
                            <input
                                type="number"
                                value={airtel!.discount_price}
                                placeholder="금액을 입력해주세요 (숫자만)"
                                onChange={discountInput}
                            />
                        </InputBox>
                        <h3 className="won">원</h3>
                    </BoxContainer>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>표현 순서</h3>
                        </TextContainer>
                        <InputBox width="100px">
                            <input
                                type="number"
                                value={airtel!.order_by}
                                placeholder=""
                                onChange={orderInput}
                            />
                        </InputBox>
                    </BoxContainer>
                    <Box2Container set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>썸네일 이미지</h3>
                        </TextContainer>
                        <ImgAddContainer>
                            <ThumbnailImgUploader
                                fileURL={airtel!.main_img}
                                flag={true}
                                uploadImg={onImageChange}
                                removeImg={onImageRemove}
                            />
                        </ImgAddContainer>
                    </Box2Container>
                    <BoxContainer set="stretch">
                        <TextContainer>
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>상태</h3>
                        </TextContainer>
                        <RegisterVisibleBox
                            visible={1}
                            setState={visibleSelect}
                        />
                    </BoxContainer>
                </Container>
                <StickBar />
                <Container
                    width="50%"
                    val="20px"
                >
                    <div className="detail" />
                    <DetailImgUploader
                        detailData={regAirtel}
                        type={TYPE_AIRTEL_IMG}
                    />
                </Container>
            </RowContainer>
            <BtnContainer>
                <Btn
                    type={true}
                    onClick={cancelBtnHandle}
                >
                    취소
                </Btn>
                <Btn
                    type={false}
                    onClick={setImgS3main}
                >
                    등록
                </Btn>
            </BtnContainer>
        </BaseContainer>
    );
}
