import { useSetAtom } from "jotai";
import styled from "styled-components";
import { BaseContainer } from "../../common/BaseContainer";
import { subtitleAtom, titleAtom } from "../../common/NavTopBar";
import { SelectMenu } from "../../common/NavVar";

const Container = styled.div``;

export function Dashboard() {
    const setSelectMenu = useSetAtom(SelectMenu);
    const setTitle = useSetAtom(titleAtom);
    const setSubTitle = useSetAtom(subtitleAtom);
    setSelectMenu(1);
    setTitle("대시보드");
    setSubTitle("");

    return <BaseContainer>대시보드</BaseContainer>;
}
