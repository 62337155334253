import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    padding: 20px 0 20px 20px;
    width: 100%;
    max-width: 1700px;
    background-color: #f4f4f9;
    // display: flex;
    // justify-content: space-between;

    @media screen and (max-width: 768px) {
        padding: 63px 16px 30px;
    }
`;

const Content = styled.div`
    .copyright {
        display: flex;

        span {
            padding-left: 4px;
            color: #5b5d75;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.52px;
            border: 0;
        }
    }

    @media screen and (max-width: 480px) {
        h3 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.72px;
        }
        span,
        .info {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.48px;
        }
    }
`;

export default function Footer() {
    return (
        <Container>
            <footer>
                <Content>
                    <div className="copyright">
                        <span>Wafflestay © Copyright 2023. All Rights Reserved.</span>
                    </div>
                </Content>
            </footer>
        </Container>
    );
}
