import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div``;

export function MainView() {
    const navigate = useNavigate();
    const accesstoken = sessionStorage.getItem("accesstoken") || "";

    useEffect(() => {
        if (accesstoken === "") {
            navigate(`/adminpage/login`);
        } else {
            navigate(`/adminpage/dashboard`);
        }
    });

    return <div />;
}
