import styled from "styled-components";
import { priceparse } from "../common/TabMenu";

const Container = styled.div`
    .title {
        width: 1050px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 24px;
            font-weight: 700;
            color: #161a3f;
        }
        .modify {
            height: 40px;
            font-size: 13px;
            font-weight: 500;
            color: #ffffff;
            background-color: #2d40ff;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            padding: 3px 10px 3px 10px;
        }
    }
    .subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #0066ff;
    }
    .titleBox {
        display: flex;
        width: 20%;
        height: 40px;
        border: 1px solid #eeeff3;
        background-color: #fcfcfc;
        font-size: 14px;
        font-weight: 500;
        color: #161a3f;
        align-items: center;
        justify-content: center;
    }
    .contentBox {
        display: flex;
        width: 80%;
        height: 40px;
        border: 1px solid #eeeff3;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        justify-content: start;
        padding-left: 30px;
    }
    .basic {
        color: #161a3f;
    }
    .red {
        color: #e20000;
    }
    .row {
        display: flex;
    }
`;

interface props {
    product: any;
    order_by: number;
    visible: number;
    openModal: () => void;
}

export const AirtelDetailItem = (props: props) => {
    const { product, order_by, visible, openModal } = props;
    const partner_lv = Number(sessionStorage.getItem("partnerlv"));

    return (
        <>
            <Container>
                <div className="title">
                    <h3>{product.title}</h3>
                    {partner_lv > 1 && (
                        <button
                            className="modify"
                            onClick={openModal}
                        >
                            에어텔 정보 수정
                        </button>
                    )}
                </div>
                <h3 className="subtitle">{product.subtitle}</h3>
                <div className="row">
                    <div className="titleBox">항공선택</div>
                    <div className="contentBox basic">{product.airline}</div>
                </div>
                <div className="row">
                    <div className="titleBox">여행일정</div>
                    <div className="contentBox basic">{product.airtel_date}</div>
                </div>
                <div className="row">
                    <div className="titleBox">상세지역</div>
                    <div className="contentBox basic">상세 정보 참조</div>
                </div>
                <div className="row">
                    <div className="titleBox">원가</div>
                    <div className="contentBox basic">{priceparse(Number(product.origin_price))}</div>
                </div>
                <div className="row">
                    <div className="titleBox">할인가</div>
                    <div className="contentBox red">{priceparse(Number(product.discount_price))}</div>
                </div>
                <div className="row">
                    <div className="titleBox">노출여부</div>
                    <div className="contentBox basic">{visible === 1 ? "노출" : "숨김"}</div>
                    <div className="titleBox">표현순서</div>
                    <div className="contentBox basic">{order_by}</div>
                </div>
            </Container>
        </>
    );
};
