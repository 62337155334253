import { atom, useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useState } from "react";

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
`;

const NumberBox = styled.div<{ color: string }>`
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: ${(props) => (props.color === "1" ? "#2d40ff" : "#ffffff")};
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => (props.color === "1" ? "#ffffff" : "#7e8299")};
        cursor: pointer;
    }
`;

const ArrowBox = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: #ecf1f6;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const atotalpageAtom = atom(1);
export const nowpageAtom = atom(1);
export const PagingBox = () => {
    const atotalpage = useAtomValue(atotalpageAtom);
    const [nowpage, setNowPage] = useAtom(nowpageAtom);
    const [page, setPage] = useState(0);
    const limit = 5;
    const offset = page;
    const pagenum = new Array(atotalpage).fill(1);

    const setPageMath = () => {
        if (nowpage > offset + limit) {
            setPage(offset + limit);
        } else if (nowpage <= offset) {
            setPage(offset - limit);
        }
    };

    const backArrow = () => {
        // 이전 페이징
        if (nowpage === 1) {
            return;
        }
        setNowPage(nowpage - 1);
    };
    const frontArrow = () => {
        // 다음 페이징
        if (nowpage === atotalpage) {
            return;
        }
        setNowPage(nowpage + 1);
    };
    const backDoubleArrow = () => {
        // 처음 페이지
        setNowPage(1);
        setPage(0);
    };
    const frontDoubleArrow = () => {
        // 마지막 페이지
        setNowPage(atotalpage);
    };

    const colorDiv = (n: number) => {
        let color = "0";
        if (n === nowpage) {
            color = "1";
        }
        return color;
    };

    return (
        <Container>
            <ArrowBox onClick={backDoubleArrow}>
                <MdKeyboardDoubleArrowLeft
                    color="#b5b5c3"
                    size={20}
                />
            </ArrowBox>
            <ArrowBox onClick={backArrow}>
                <IoIosArrowBack
                    color="#b5b5c3"
                    size={15}
                />
            </ArrowBox>
            {pagenum.slice(offset, offset + limit).map((item, index) => {
                setPageMath();
                return (
                    <NumberBox
                        key={index * item}
                        color={colorDiv(index + offset + 1)}
                    >
                        <h3
                            onClick={() => {
                                setNowPage(index + offset + 1);
                            }}
                        >
                            {index + offset + 1}
                        </h3>
                    </NumberBox>
                );
            })}
            <ArrowBox onClick={frontArrow}>
                <IoIosArrowForward
                    color="#b5b5c3"
                    size={15}
                />
            </ArrowBox>
            <ArrowBox onClick={frontDoubleArrow}>
                <MdKeyboardDoubleArrowRight
                    color="#b5b5c3"
                    size={20}
                />
            </ArrowBox>
        </Container>
    );
};
