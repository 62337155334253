import styled from "styled-components";
import { BiSolidRightArrow } from "react-icons/bi";
import { ChangeEvent } from "react";
import UpdateDetailImgBox from "../common/UpdateDetailImg";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    // background-color: #22ff11;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 17px;
        font-weight: 500;
        color: #122437;
    }
`;

export interface updateBox {
    seq: number;
    image_url: string;
    file: any;
    ratio: string;
    order_by: number;
}

interface data {
    item: any;
    flag: boolean;
    uploadImg: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    ratiohandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
    orderhandle: (seq: number, e: ChangeEvent<HTMLInputElement>) => void;
}

export const HotdealDetailImage = (data: data) => {
    const { item, flag, uploadImg, ratiohandle, orderhandle } = data;

    return (
        <Container>
            <TextContainer>
                <BiSolidRightArrow
                    color="#6f7cff"
                    size={13}
                />
                <h3>상품 상세이미지</h3>
            </TextContainer>
            {item.map((item: any, i: number) => {
                return (
                    <UpdateDetailImgBox
                        key={i + 1}
                        fileURL={item.image_url}
                        ratio={item.ratio}
                        order={item.order_by}
                        seq={i}
                        flag={flag}
                        uploadImg={uploadImg}
                        ratiohandle={ratiohandle}
                        orderhandle={orderhandle}
                    />
                );
            })}
        </Container>
    );
};
