import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../../common/PagingBox";
import { SheetContainer } from "../../../common/SheetContainer";
import { SheetSortation } from "../../../common/SheetSortation";
import { useEffect, useState } from "react";
import { MdOutlineAddTask } from "react-icons/md";
import { VisibleSelectBox } from "../../../common/VisibleSelectBox";
import { format } from "date-fns";
import NoneBackSpinner from "../../../common/NoneBackSpinner";
import { HotdealModal } from "./HotdealDetailModal";
import { imgUrl } from "../../view/ProductListView";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    align-items: center;
    // justify-content: start;
`;

const BtnContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: end;
    margin: 30px 40px 20px 0px;
`;

const AddPackageBtn = styled.button`
    display: flex;
    width: 120px;
    height: 40px;
    cursor: pointer;
    background-color: #23c162;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;

    h3 {
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
        font-weight: 500;
    }
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: black;
    }
`;

const SContainer = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        // background-color: #fcfcfc;
        font-weight: 700;
        color: #2d40ff;
    }
`;

const DetailProduct = styled.div`
    display: flex;
    width: 100%;
    max-width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #708fe2;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const DeleteProduct = styled.button`
    display: flex;
    width: 100%;
    max-width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #f47878;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const titleparsing = (sort: number, title: string) => {
    let state = 0;
    if (title.includes("[마감]")) {
        state = 1;
    } else if (title.includes("[진행중]")) {
        state = 2;
    } else {
        return sort === 1 ? title : "";
    }

    if (sort === 1) {
        return title.replace(state === 1 ? "[마감]" : "[진행중]", "");
    } else {
        return state === 1 ? "마감" : "진행중";
    }
};

const priceparsing = (item: any) => {
    let price = 0;
    if (item.payment_cnt > 1) {
        price = item.cnt1_price || 0;
    } else {
        price = item.balance_amount || 0;
    }
    return price / 10000 + "만원";
};

const dateparsing = (date: string) => {
    return format(new Date(date), "yyyy년 MM월 dd일");
};

export const HotDealList = () => {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const [isDetail, setDetail] = useState(false);
    const [product, setProduct] = useState<any>();
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    function moveRegister() {
        navigate(`/adminpage/hotdeal/register`, {
            state: {
                type: "reg",
                item: "{}",
            },
        });
    }
    function moveUpdate(item: any) {
        setDetail(false);
        navigate(`/adminpage/hotdeal/register`, {
            state: {
                type: "update",
                seq: item.event_product_seq.toString(),
                create_date: item.create_date.toString(),
                item: JSON.stringify({
                    max_person: item.max_person,
                    product_name: item.product_name,
                    product_banner_image: item.product_banner_image,
                    product_image_list: item.product_image_list,
                    product_policy_list: item.product_policy_list,
                    product_etc: item.product_etc,
                    product_order: item.product_order,
                    product_payment_yn: item.product_payment_yn,
                    is_exclude: item.is_exclude,
                    is_vat: item.is_vat,
                    product_d_day: item.product_d_day,
                    product_sales_day: item.product_sales_day,
                    middle_etc: item.middle_etc,
                    last_etc: item.last_etc,
                    payment_cnt: item.payment_cnt,
                    cnt1_price: item.cnt1_price,
                    cnt2_price: item.cnt2_price,
                    balance_amount: item.balance_amount,
                    product_price: item.product_price,
                }),
            },
        });
    }
    function closeDetailModal() {
        setDetail(false);
    }
    function openDetailModal(item: any) {
        setProduct(item);
        setDetail(true);
    }

    const getProduct = async () => {
        try {
            const res = await axios.get(`/event/product/admin?size=10&page=${nowpage}`);
            // console.log("hotdeal list: ", res.data);
            if (res.data.code === 0) {
                // 총 페이지 수 계산
                setaTotalPage(Math.ceil(res.data.data.total_cnt / 10));
                // 리스트 저장
                setList(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 에러 처리
            // console.log(e);
            alert(e.response.data.message);
        }
    };

    const setVisible = async (v: number, sort: string, id: number) => {};

    useEffect(() => {
        getProduct();
    }, [nowpage]);

    return (
        <Container>
            {isDetail && (
                <HotdealModal
                    open={isDetail}
                    close={closeDetailModal}
                    item={product}
                    move={moveUpdate}
                />
            )}
            <BtnContainer>
                {partnerlv > 1 && (
                    <AddPackageBtn onClick={moveRegister}>
                        <MdOutlineAddTask
                            color="#ffffff"
                            size={20}
                        />
                        <h3>상품 등록</h3>
                    </AddPackageBtn>
                )}
            </BtnContainer>
            <BoxContainer>
                <SheetContainer>
                    <SheetSortation
                        width={"70px"}
                        text={"구분"}
                    />
                    <SheetSortation
                        width={"90px"}
                        text={"상태"}
                    />
                    <SheetSortation
                        width={"500px"}
                        text={"상품 명"}
                    />
                    <SheetSortation
                        width={"150px"}
                        text={"상품가격"}
                    />
                    <SheetSortation
                        width={"80px"}
                        text={"총 결제 회차"}
                    />
                    <SheetSortation
                        width={"200px"}
                        text={"행사일"}
                    />
                    <SheetSortation
                        width={"200px"}
                        text={"판매일"}
                    />
                    <SheetSortation
                        width={"70px"}
                        text={"표현 순서"}
                    />
                    <SheetSortation
                        width={"90px"}
                        text={"상품 노출"}
                    />
                    <SheetSortation
                        width={"90px"}
                        text={"결제 버튼"}
                    />
                </SheetContainer>
                {list.length === 0 ? (
                    <NoneBackSpinner />
                ) : (
                    list.map((item: any, i: number) => {
                        return (
                            <SContainer
                                key={item.event_product_seq}
                                onClick={() => openDetailModal(item)}
                            >
                                <Content width="70px">{nowpage === 1 ? 1 + i : i + 1 + (nowpage - 1) * 10}</Content>
                                <Content width="90px">{titleparsing(2, item.product_name)}</Content>
                                <Content width="500px">{titleparsing(1, item.product_name)}</Content>
                                <Content width="150px">{priceparsing(item)}</Content>
                                <Content width="80px">{item.payment_cnt}</Content>
                                <Content width="200px">{dateparsing(item.product_d_day)}</Content>
                                <Content width="200px">{dateparsing(item.product_sales_day)}</Content>
                                <Content width="70px">{item.product_order}</Content>
                                <Content width="90px">
                                    <VisibleSelectBox
                                        visible={item.is_exclude === "N" ? 1 : 0}
                                        sort={"hotdeal_exclude"}
                                        id={item.event_product_seq}
                                        setState={setVisible}
                                        disable={true}
                                    />
                                </Content>
                                <Content width="90px">
                                    <VisibleSelectBox
                                        visible={item.product_payment_yn === "Y" ? 1 : 0}
                                        sort={"hotdeal_payment"}
                                        id={item.event_product_seq}
                                        setState={setVisible}
                                        disable={true}
                                    />
                                </Content>
                            </SContainer>
                        );
                    })
                )}
            </BoxContainer>
            {list.length > 0 && <PagingBox />}
        </Container>
    );
};
