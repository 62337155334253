import styled from "styled-components";
import { ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import { SheetContainer } from "../../../common/SheetContainer";
import { SheetSortation } from "../../../common/SheetSortation";
import { HpDateParse } from "../common/ReservUtil";
import { dateParse } from "../../../product/view/CounsellingListView";
import axios from "axios";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import LoadingSpinner from "../../../common/LoadingSpinner";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    text-align: left;
    padding: 10px 15px 10px 20px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 500;
    font-size: 16px;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 400px;
    padding: 20px;
    align-items: start;
    justify-content: start;
    border-bottom: 2px solid #eeeeee;

    .blockname {
        display: flex;
        color: #161a3f;
        font-size: 17px;
        font-weight: 500;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0px;

        .bar {
            margin-left: 10px;
            height: 1px;
            width: 100%;
            max-width: 700px;
            background-color: #161a3f;
        }

        .box {
            width: 100%;
            max-width: 150px;
            height: 30px;
        }

        .cbtn {
            width: 100%;
            max-width: 100px;
            margin-right: 50px;
            height: 30px;
            background-color: #2d40ff;
            color: #ffffff;
            border: none;
            border-radius: 7px;
            cursor: pointer;
        }
    }

    .rowbox {
        display: flex;
        margin: 0px 10px;
        width: 100%;
        max-width: 900px;
    }
`;

const Content = styled.td<{ width: string; type: boolean }>`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 35px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.type ? "#f7f8fe" : "#ffffff")};
    color: #161a3f;
    font-size: 13px;
    font-weight: ${(props) => (props.type ? "500" : "400")};
`;

interface props {
    open: boolean;
    item: any;
    close: () => void;
    refresh: () => void;
}

export const HpReservDetailModal = (props: props): ReactElement => {
    const { open, item, close, refresh } = props; // props로 함수 받기
    const [isShow, setShow] = useState(false);

    const reservCancel = async () => {
        setShow(true);
        try {
            const res = await axios.delete(`/hp/booking/cancel`, {
                params: {
                    booking_id: item.booking_id,
                    cancel_amount: item.total_amount,
                },
            });
            console.log("cancel? ", res.data);
            setShow(false);
            if (res.data.code === "WFL000") {
                alert(priceParseComma(res.data.data.cancel_amount.toString()) + "원이 정상적으로 취소되었습니다.");
                refresh();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 에러 처리
            // console.log(e);
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    예약 상세 정보
                    <ModalClosebutton onClick={close}>
                        <CgCloseR
                            size={20}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <div className="blockname">
                        예약 정보
                        <div className="bar" />
                        {item.booking_state === "RESUME" ? (
                            <button
                                className="cbtn"
                                onClick={reservCancel}
                            >
                                예약취소
                            </button>
                        ) : (
                            <div className="box" />
                        )}
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            예약자
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.booker_name}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            연락처
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.booker_phone}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            이메일
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.booker_email}
                        </Content>
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"150px"}
                            type={true}
                        >
                            호텔명
                        </Content>
                        <Content
                            width={"300px"}
                            type={false}
                        >
                            {item.hotel_name}
                        </Content>
                        <Content
                            width={"150px"}
                            type={true}
                        >
                            룸 명
                        </Content>
                        <Content
                            width={"300px"}
                            type={false}
                        >
                            {item.room_name}
                        </Content>
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            예약 인원
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.occupancy + " 명"}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            예약 일시
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {HpDateParse(item.insert_date)}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            취소기한
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {dateParse(item.refundable_date) + " 14:00 까지"}
                        </Content>
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            체크인
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {dateParse(item.checkin)}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            체크아웃
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {dateParse(item.checkout)}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            {item.booking_state === "HOLD" ? "결제예정금액" : "결제금액"}
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {priceParseComma(item.total_amount.toString()) + " 원"}
                        </Content>
                    </div>
                    <div className="blockname">
                        예약 상태
                        <div className="bar" />
                        <div className="box" />
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            예약 번호
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.booking_id}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            예약 상태
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.booking_state}
                        </Content>
                        <Content
                            width={"100px"}
                            type={true}
                        >
                            사용 쿠폰 코드
                        </Content>
                        <Content
                            width={"200px"}
                            type={false}
                        >
                            {item.coupon_code}
                        </Content>
                    </div>
                    <div className="rowbox">
                        <Content
                            width={"150px"}
                            type={true}
                        >
                            예약 재개 일시
                        </Content>
                        <Content
                            width={"300px"}
                            type={false}
                        >
                            {HpDateParse(item.resume_date)}
                        </Content>
                        <Content
                            width={"150px"}
                            type={true}
                        >
                            예약 취소 일시
                        </Content>
                        <Content
                            width={"300px"}
                            type={false}
                        >
                            {HpDateParse(item.delete_date)}
                        </Content>
                    </div>
                </ModalContent>
            </ModalContainer>
        </Container>
    );
};
