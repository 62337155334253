import styled from "styled-components";
import React, { ReactElement } from "react";
import { CgCloseR } from "react-icons/cg";
import { levelparse } from "../view/AccountView";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-top: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height: 60vh;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    padding: 30px 50px 30px 40px;
`;

const ContentP = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0px 20px 0px 15px;
    border-bottom: 1px solid #eeeeee;
`;

const ContentLeft = styled.p`
    line-height: 15px;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
`;

const ContentRight = styled.p`
    line-height: 15px;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
`;

interface props {
    open: boolean;
    close: (v: boolean) => void;
    detail: any;
}

export const AccountDetailModal = (props: props): ReactElement => {
    const { open, close, detail } = props; // props로 함수 받기

    // console.log("detail data: ", detail);

    return (
        <Container>
            <ModalContainer>
                <ModalHeader>
                    Account Detail Info
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <ContentP>
                        <ContentLeft>고유 번호 : </ContentLeft>
                        <ContentRight>{detail.client_id}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>계정 구분 : </ContentLeft>
                        <ContentRight>{levelparse(detail.partner_lv)}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>계정 ID : </ContentLeft>
                        <ContentRight>{detail.partner_id}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>파트너사 명 : </ContentLeft>
                        <ContentRight>{detail.partner_name}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>파트너사 주소 : </ContentLeft>
                        <ContentRight>{detail.partner_address}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>파트너사 연락처 : </ContentLeft>
                        <ContentRight>{detail.partner_contact}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>파트너사(담당자) E-MAIL : </ContentLeft>
                        <ContentRight>{detail.partner_email}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>담당자 이름 : </ContentLeft>
                        <ContentRight>{detail.incharge}</ContentRight>
                    </ContentP>
                    <ContentP>
                        <ContentLeft>담당자 연락처 : </ContentLeft>
                        <ContentRight>{detail.incharge_contact}</ContentRight>
                    </ContentP>
                </ModalContent>
            </ModalContainer>
        </Container>
    );
};
