import { atom, useAtom } from "jotai";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

const SelectBoxWrapper = styled.div`
    display: flex;
`;

const Select = styled.select`
    margin: 0;
    display: block;
    width: 100%;
    max-width: 100px;
    height: 40px;
    padding: 5px 8px 5px 8px;
    font-size: 13px;
    line-height: inherit;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    color: #161a3f;
    // background-color: transparent;
    background-color: #ffffff;
    cursor: pointer;
    &:focus {
        border-color: red;
    }
`;

interface props {
    state: string;
    setState: (result: string) => void;
    disable: boolean;
}

const options = [
    { no: 0, value: "RESUME" },
    { no: 1, value: "HOLD" },
    { no: 2, value: "CANCEL" },
    { no: 3, value: "ALL" },
];
export const HpSelectBox = (props: props): ReactElement => {
    const { state, setState, disable } = props; // props로 함수 받기
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        // event handler
        setState(e.target.value);
    };

    useEffect(() => {});

    return (
        <SelectBoxWrapper>
            <Select onChange={handleChange}>
                {options.map((option) => (
                    <option
                        key={option.no}
                        value={option.value}
                        selected={option.value === state}
                        disabled={disable}
                    >
                        {option.value}
                    </option>
                ))}
            </Select>
        </SelectBoxWrapper>
    );
};
