import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { atotalpageAtom, nowpageAtom } from "./PagingBox";
import { AccountTypeAtom } from "../account/components/AccountListSelectBox";
import { accountSearchAtom } from "../account/components/AccountSearchBox";
import { subtitleAtom, thirdtitleAtom } from "./NavTopBar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%
    max-width: 200px;
    height: 100vh;
    background-color: #2d40ff;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    padding: 35px 30px 100px 30px;

    img {
        cursor: pointer;
    }
`;

const MenuContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 45px;
    align-items: center;
    padding: 0px 10px 0px 15px;
    background-color: ${(props) => props.color};
    cursor: pointer;
    margin-top: 6px;

    img {
        color: #ffffff;
    }

    h3 {
        margin-left: 10px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 700;
    }
`;

const MenuArrowContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 45px;
    align-items: center;
    justify-content: stretch;
    padding: 0px 0px 0px 15px;
    margin-top: 6px;
    background-color: ${(props) => props.color};
    cursor: pointer;
`;

const MenuSubContainer = styled.div`
    align-items: center;
    display: flex;
    width: 150px;
    height: 45px;

    img {
        color: #ffffff;
    }

    h3 {
        margin-left: 10px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 700;
    }
`;

const SubMenuContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 40px;
    align-items: center;
    padding: 0px 10px 0px 50px;
    background-color: #2c326b;

    h3 {
        cursor: pointer;
        margin-left: 10px;
        font-size: 13px;
        color: #ffffff;
        font-weight: 700;
    }
`;

export const SelectMenu = atom(1);
const SelectProduct = atom(false);
const SelectReservation = atom(false);
export default function NavBar() {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const setNowPage = useSetAtom(nowpageAtom);
    const pSubTitle = useAtomValue(subtitleAtom);
    const pThirdTitle = useAtomValue(thirdtitleAtom);
    const [selectMenu, setSelectMenu] = useAtom(SelectMenu);
    const [selectp, setSelectP] = useAtom(SelectProduct);
    const [selectr, setSelectR] = useAtom(SelectReservation);
    const setAcctype = useSetAtom(AccountTypeAtom);
    const setAccVal = useSetAtom(accountSearchAtom);
    const level = sessionStorage.getItem("partnerlv");

    const selectProduct = () => {
        setSelectP(!selectp);
    };
    const selectReservation = () => {
        setSelectR(!selectr);
    };
    const clickMenu = () => {
        setaTotalPage(0);
        setNowPage(1);
    };

    // const productMove = () => {
    //     console.log("p? ", selectMenu, pSubTitle);
    //     if (selectMenu !== 2 || (pSubTitle === "상품목록" && pThirdTitle === "")) {
    //         setaTotalPage(0);
    //         navigate(`/adminpage/productlist`, { state: { to: "hotdeal" } });
    //     }
    //     setNowPage(1);
    // };
    // const reservMove = () => {
    //     if (selectMenu !== 3 || (pSubTitle === "상품 구매 내역" && pThirdTitle === "")) {
    //         setaTotalPage(0);
    //         navigate(`/adminpage/productreserv`, { state: { to: "PACKAGE" } });
    //     }
    //     setNowPage(1);
    // };
    const refreshMove = () => {
        navigate(`/adminpage/dashboard`);
        window.location.reload();
    };

    useEffect(() => {
        // console.log("selectmenu? ", selectMenu);
        if (selectMenu === 1 || selectMenu > 3) {
            setSelectP(false);
            setSelectR(false);
            setAcctype("전체");
            setAccVal("");
        } else if (selectMenu === 2) {
            setSelectR(false);
            setSelectP(true);
        } else if (selectMenu === 3) {
            setSelectP(false);
            setSelectR(true);
        }
    }, [selectMenu]);

    return (
        <header>
            <Container>
                <TitleContainer>
                    <img
                        width={130}
                        src="/img/logo.svg"
                        alt="logo"
                        onClick={refreshMove}
                    />
                </TitleContainer>
                <Link
                    style={{ textDecoration: "none", color: "initial" }}
                    to={`/adminpage/dashboard`}
                >
                    <MenuContainer color={selectMenu === 1 ? "#2c326b" : "#2d40ff"}>
                        <img
                            src="/img/menu_ic_dashboard.svg"
                            sizes="20"
                            alt="dashboard"
                        />
                        <h3>대시보드</h3>
                    </MenuContainer>
                </Link>
                {level !== "0" && (
                    <MenuArrowContainer
                        color={selectMenu === 2 || selectp ? "#2c326b" : "#2d40ff"}
                        onClick={selectProduct}
                    >
                        <MenuSubContainer>
                            <img
                                src="/img/menu_ic_product.svg"
                                sizes="20"
                                alt="product"
                            />
                            <h3>상품관리</h3>
                        </MenuSubContainer>
                        {selectp ? (
                            <IoIosArrowUp
                                size={20}
                                color="#ffffff"
                            />
                        ) : (
                            <IoIosArrowDown
                                size={20}
                                color="#ffffff"
                            />
                        )}
                    </MenuArrowContainer>
                )}
                {selectp && (
                    // <SubMenuContainer onClick={productMove}>
                    //     <GoDotFill
                    //         size={8}
                    //         color="#ffffff"
                    //     />
                    //     <h3>상품목록</h3>
                    // </SubMenuContainer>
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/productlist`}
                    >
                        <SubMenuContainer onClick={clickMenu}>
                            <GoDotFill
                                size={8}
                                color="#ffffff"
                            />
                            <h3>상품목록</h3>
                        </SubMenuContainer>
                    </Link>
                )}
                {selectp && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/counsellinglist`}
                    >
                        <SubMenuContainer onClick={clickMenu}>
                            <GoDotFill
                                size={8}
                                color="#ffffff"
                            />
                            <h3>상담내역</h3>
                        </SubMenuContainer>
                    </Link>
                )}
                {selectp && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/productnoti`}
                    >
                        <SubMenuContainer>
                            <GoDotFill
                                size={8}
                                color="#ffffff"
                            />
                            <h3>알림톡</h3>
                        </SubMenuContainer>
                    </Link>
                )}
                <MenuArrowContainer
                    color={selectMenu === 3 || selectr ? "#2c326b" : "#2d40ff"}
                    onClick={selectReservation}
                >
                    <MenuSubContainer>
                        <img
                            src="/img/menu_ic_reserv.svg"
                            sizes="20"
                            alt="reserv"
                        />
                        <h3>상품 판매 내역</h3>
                    </MenuSubContainer>
                    {selectr ? (
                        <IoIosArrowUp
                            size={20}
                            color="#ffffff"
                        />
                    ) : (
                        <IoIosArrowDown
                            size={20}
                            color="#ffffff"
                        />
                    )}
                </MenuArrowContainer>
                {selectr && (
                    // <SubMenuContainer onClick={reservMove}>
                    //     <GoDotFill
                    //         size={8}
                    //         color="#ffffff"
                    //     />
                    //     <h3>상품 구매 내역</h3>
                    // </SubMenuContainer>
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/productreserv`}
                    >
                        <SubMenuContainer onClick={clickMenu}>
                            <GoDotFill
                                size={8}
                                color="#ffffff"
                            />
                            <h3>상품 구매 내역</h3>
                        </SubMenuContainer>
                    </Link>
                )}
                {selectr && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/hotelreserv`}
                    >
                        <SubMenuContainer onClick={clickMenu}>
                            <GoDotFill
                                size={8}
                                color="#ffffff"
                            />
                            <h3>호텔 예약 내역</h3>
                        </SubMenuContainer>
                    </Link>
                )}
                {level !== "0" && (
                    <Link
                        style={{ textDecoration: "none", color: "initial" }}
                        to={`/adminpage/account`}
                    >
                        <MenuContainer
                            color={selectMenu === 4 ? "#2c326b" : "#2d40ff"}
                            onClick={clickMenu}
                        >
                            <img
                                src="/img/menu_ic_account.svg"
                                sizes="20"
                                alt="account"
                            />
                            <h3>계정관리</h3>
                        </MenuContainer>
                    </Link>
                )}
                <Link
                    style={{ textDecoration: "none", color: "initial" }}
                    to={`/adminpage/myinfo`}
                >
                    <MenuContainer color={selectMenu === 5 ? "#2c326b" : "#2d40ff"}>
                        <img
                            src="/img/menu_ic_myinfo.svg"
                            sizes="20"
                            alt="myinfo"
                        />
                        <h3>MY INFO</h3>
                    </MenuContainer>
                </Link>
            </Container>
        </header>
    );
}
