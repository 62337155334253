import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import styled from "styled-components";
import { HpSelectBox } from "./HpStateSelectBox";

const Container = styled.div`
    display: flex;
    width: 100%;
    // margin: 0px 0px 0px 0px;
    // background-color: #eeeeee;
    align-items: center;
    justify-content: center;

    .rowBox {
        display: flex;
        width: 100%;
        gap: 10px;
        align-items: center;
        justify-content: center;
        // background-color: #ff0022;
    }

    .calbox {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 170px;

        input {
            width: 100%;
            height: 20px;
            line-height: 18px;
            border: 1px solid #e7e7ee;
            border-radius: 4px;
            padding: 10px 20px 10px 40px;
            outline: none;
            font-size: 14px;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            top: 10px;
            left: 10px;
            // color: #5d646d;
        }
    }

    .search {
        height: 20px;
        width: 100%;
        max-width: 350px;
        line-height: 5px;
        border: 1px solid #e7e7ee;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        outline: none;
    }

    .searchBtn {
        width: 100%;
        max-width: 80px;
        height: 42px;
        border-radius: 7px;
        padding: 10px;
        line-height: 15px;
        background-color: #2d40ff;
        border: none;
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
    }
`;

const TextContainer = styled.div`
    display: flex;
    height: 42px;
    gap: 5px;
    align-items: center;

    h3 {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
`;

interface props {
    title: string;
    sState: string;
    setSstate: (state: string) => void;
    searching: (str: string, end: string, state: string, txt: string) => void;
}

export const HpRsvListSearchBox = (props: props) => {
    const { title, sState, setSstate, searching } = props;
    const [searchTxt, setSearchtxt] = useState<string>("");

    const searchTxtInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchtxt(e.target.value);
    };

    const stateInput = (val: string) => {
        setSstate(val);
    };

    const searchOnClick = () => {
        searching("", "", sState, searchTxt);
    };

    return (
        <Container>
            <div className="rowBox">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={15}
                    />
                    <h3>{title}</h3>
                </TextContainer>
                <HpSelectBox
                    state={sState}
                    setState={stateInput}
                    disable={false}
                />
                <input
                    className="search"
                    type="text"
                    value={searchTxt}
                    placeholder="예약번호 / 예약자 / 연락처 / 이메일 검색가능"
                    onChange={searchTxtInput}
                />
                <button
                    className="searchBtn"
                    onClick={searchOnClick}
                >
                    검색
                </button>
            </div>
        </Container>
    );
};
