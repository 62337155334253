import { atom, useAtom } from "jotai";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

const SelectBoxWrapper = styled.div`
    display: flex;
`;

const Select = styled.select`
    margin: 0;
    display: block;
    width: 100px;
    height: 35px;
    padding: 5px 8px 5px 8px;
    font-size: 13px;
    line-height: inherit;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    color: #000000;
    // background-color: transparent;
    background-color: #ffffff;
    cursor: pointer;
    &:focus {
        border-color: red;
    }
`;

interface props {
    visible: number;
    setState: (v: number) => void;
}

const options = [
    { no: 0, value: "노출", visible: 1 },
    { no: 1, value: "숨김", visible: 0 },
];
export const RegisterVisibleBox = (props: props): ReactElement => {
    const { visible, setState } = props; // props로 함수 받기
    const [selectState, setSelectState] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        // event handler
        setSelectState(!selectState);
        if (e.target.value == "노출") {
            setState(1);
        } else {
            setState(0);
        }
    };

    useEffect(() => {
        setSelectState(visible === 1);
    });

    return (
        <SelectBoxWrapper>
            <Select onChange={handleChange}>
                {options.map((option) => (
                    <option
                        key={option.no}
                        value={option.value}
                    >
                        {option.value}
                    </option>
                ))}
            </Select>
        </SelectBoxWrapper>
    );
};
