import styled from "styled-components";
import { BiSolidRightArrow } from "react-icons/bi";
import { ChangeEvent } from "react";
import { VisibleSelectBox } from "../../../common/VisibleSelectBox";
import ThumbnailImgUploader from "./ThumbnailAdd";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    // background-color: #22ff22;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 550px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 40px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 400px;
    height: 40px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 16px;
        outline: none;
    }
`;

const ImgAddContainer = styled.div`
    display: flex;
    width: 300px;
    height: 150px;
    align-items: center;
    margin-left: 20px;
    // justify-content: start;
`;

const VisibleBox = styled.div<{ visible: boolean }>`
    display: flex;
    width: 70px;
    height: 30px;
    padding: 2px 8px 2px 8px;
    align-items: center;
    justify-content: start;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    // background-color: #ffffff;

    h3 {
        color: ${(props) => (props.visible ? "#708fe2" : "#ee4c4c")};
        font-size: 14px;
        font-weight: 400;
    }
`;

interface props {
    flag: boolean;
    item: any;
    titleInput: (e: ChangeEvent<HTMLInputElement>) => void;
    priceInput: (e: ChangeEvent<HTMLInputElement>) => void;
    orderInput: (e: ChangeEvent<HTMLInputElement>) => void;
    visibleInput: (v: number, sort: string, id: number) => void;
    thumbnailInput: (e: ChangeEvent<HTMLInputElement>) => void;
    thumbnailRemove: () => void;
}

export const DetailInfo = (props: props) => {
    const { flag, item, titleInput, priceInput, orderInput, visibleInput, thumbnailInput, thumbnailRemove } = props;

    return (
        <Container>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={13}
                    />
                    <h3>상품 명</h3>
                </TextContainer>
                <InputBox>
                    {flag ? (
                        <input
                            type="text"
                            value={item.title}
                            placeholder="상품 명을 입력해주세요"
                            onChange={titleInput}
                        />
                    ) : (
                        <input
                            type="text"
                            value={item.title}
                            disabled={true}
                        />
                    )}
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={13}
                    />
                    <h3>상품 가격</h3>
                </TextContainer>
                <InputBox>
                    {flag ? (
                        <input
                            type="text"
                            value={item.price}
                            placeholder="상품 명을 입력해주세요"
                            onChange={priceInput}
                        />
                    ) : (
                        <input
                            type="text"
                            value={item.price}
                            disabled={true}
                        />
                    )}
                </InputBox>
            </BoxContainer>
            <BoxContainer set="space-between">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={13}
                    />
                    <h3>표현 순서</h3>
                </TextContainer>
                <InputBox>
                    {flag ? (
                        <input
                            type="number"
                            value={item.orderby}
                            placeholder="상품 표현순서를 입력해주세요"
                            onChange={orderInput}
                        />
                    ) : (
                        <input
                            type="number"
                            value={item.orderby}
                            disabled={true}
                        />
                    )}
                </InputBox>
            </BoxContainer>
            <BoxContainer set="start">
                <TextContainer>
                    <BiSolidRightArrow
                        color="#6f7cff"
                        size={13}
                    />
                    <h3>노출 여부</h3>
                </TextContainer>
                {flag ? (
                    <VisibleSelectBox
                        visible={item.visible}
                        sort={"package"}
                        id={item.seq}
                        setState={visibleInput}
                        disable={false}
                    />
                ) : (
                    <VisibleBox visible={item.visible > 0}>
                        <h3>노출</h3>
                    </VisibleBox>
                )}
            </BoxContainer>
            <TextContainer>
                <BiSolidRightArrow
                    color="#6f7cff"
                    size={13}
                />
                <h3>썸네일 이미지</h3>
            </TextContainer>
            <ImgAddContainer>
                <ThumbnailImgUploader
                    fileURL={item.thumbnail}
                    flag={flag}
                    uploadImg={thumbnailInput}
                    removeImg={thumbnailRemove}
                />
            </ImgAddContainer>
        </Container>
    );
};
