import axios from "axios";
import { format } from "date-fns";
import { LinkHTMLAttributes, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { LinkProps, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import { dateParse } from "../../../product/view/CounsellingListView";
import { HpDateParse, paymentTypeParse, productSortParse, stateParse } from "../common/ReservUtil";

const Container = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: end;
    // background-color: #fff111;
    margin: 5px 0px 10px 0px;

    .excel {
        display: flex;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 500;
        border: none;
        border-radius: 5px;
        background-color: #1e7145;
        color: #ffffff;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        gap: 5px;
        margin-right: 25px;
    }
`;

interface props {
    cnt: any;
    txt: string;
    state: string;
}

export const HpExcelDownLoad = (props: props) => {
    const { cnt, txt, state } = props;
    const csvRef = useRef<any>();
    const navigate = useNavigate();
    const [isShow, setShow] = useState(false);
    const [csvOn, setCsvOn] = useState(false);
    const [datalist, setData] = useState<any>([]);

    const getList = async () => {
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");
        try {
            const res = await axios.get(`/hp/bookings?`, {
                params: {
                    booking_state: state,
                    value: txt,
                    checkin: "",
                    checkout: "",
                    size: cnt,
                    page: 1,
                },
            });

            setShow(false);
            if (res.data.code === "WFL000") {
                // 리스트 저장
                csvDataParse(res.data.data.list);
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 에러 처리
            // console.log(e);
            setShow(false);
            alert(e.response.data.message);
        }
    };

    const csvDataParse = (list: any) => {
        let arr = [
            [
                "구분",
                "호텔",
                "호텔 룸",
                "예약번호",
                "생성일",
                "예약상태",
                "결제금액",
                "인원",
                "취소기한",
                "예약자",
                "연락처",
                "이메일",
                "사용쿠폰",
            ],
        ];
        for (let i = 0; i < list.length; i++) {
            const ar = [
                i.toString(),
                list[i].hotel_name,
                list[i].room_name,
                list[i].booking_id.toString(),
                HpDateParse(list[i].insert_date),
                list[i].booking_state,
                priceParseComma(list[i].total_amount.toString()),
                list[i].occupancy.toString(),
                dateParse(list[i].refundable_date),
                list[i].booker_name,
                list[i].booker_phone,
                list[i].booker_email,
                list[i].coupon_code,
            ];
            arr.push(ar);
        }
        setData(arr);
        setCsvOn(true);
    };

    useEffect(() => {
        if (csvOn) {
            setShow(false);
            csvRef.current.link.click();
        }
    }, [csvOn]);

    return (
        <>
            <Container>
                {isShow && <LoadingSpinner />}
                <button
                    className="excel"
                    onClick={getList}
                >
                    <img
                        className="icon"
                        color="#ffffff"
                        sizes="26"
                        src="/img/excel_icon.svg"
                        alt="excel"
                    />
                    엑셀다운
                </button>
                <CSVLink
                    data={datalist}
                    ref={csvRef}
                    filename={"예약내역_" + format(new Date(), "yyyy_MM_dd") + ".csv"}
                    target="_blank"
                />
            </Container>
        </>
    );
};
