import axios from "axios";
import { ChangeEvent, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import { styled } from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { calendar } from "./CalendarInterface";
import { CalUpdateInput } from "./CalUpdateInput";
const Container = styled.div`
    position: fixed;
    // background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 200px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-top: 200px;
    width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 550px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .stick {
        display: flex;
        width: 100%;
        height: 2px;
        margin: 10px 0;
        background-color: #eeeeee;
    }
    .columnBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 900px;
        background-color: #eeeeee;
    }

    .rowBox {
        display: flex;
        width: 100%;
        max-width: 1000px;
        // background-color: #eeeeee;
        justify-content: space-between;

        .day {
            display: flex;
            with: 180px;
            height: 42px;
            align-items: center;
            justify-content: center;
            // background-color: #fff111;

            .txt {
                width: 40px;

                h3 {
                    font-size: 13px;
                    font-weight: 500;
                    margin-right: 5px;
                    color: #2d40ff;
                }
            }
        }

        #v {
            overflow: visible;
        }
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ color: string; font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => props.font};
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 42px;
    align-items: end;

    input {
        width: 90px;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 5px 10px 5px;
        font-size: 15px;
        outline: none;
    }
`;

interface props {
    open: boolean;
    seq: number;
    banner_seq: number;
    calendar: any;
    close: () => void;
    update: () => void;
    backlogin: () => void;
}

export const AirtelCalendar = (props: props) => {
    const { open, seq, banner_seq, calendar, close, update, backlogin } = props;
    const [isShow, setShow] = useState(false);
    const [cal, setCal] = useState<calendar>(calendar);

    const setDay = (calendar: calendar) => {
        setCal(calendar);
    };

    const setCalendar = async () => {
        setShow(true);
        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            seq: seq,
            airtel_banner_seq: banner_seq,
            ...cal,
        };
        try {
            const res = await axios.put(`/v1/airtels/calendar`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            setShow(false);
            if (res.data.code === 0) {
                alert("에어텔 캘린더 수정이 완료되었습니다.");
                update();
                close();
            } else {
                alert(res.data.message);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                backlogin();
            } else {
                alert(e.response.data.message);
            }
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    에어텔 정보 수정
                    <ModalClosebutton onClick={() => close()}>
                        <CgCloseR
                            size={30}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <CalUpdateInput
                        cal={cal}
                        update={setDay}
                    />
                </ModalContent>
                <Footer>
                    <BtnContainer
                        font="#000000"
                        color="#eeeeee"
                        onClick={() => close()}
                    >
                        취소
                    </BtnContainer>
                    <BtnContainer
                        font="#ffffff"
                        color="#2d40ff"
                        onClick={setCalendar}
                    >
                        수정
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
