import styled from "styled-components";
import { ChangeEvent, ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import { refundTypeParse } from "./ReservUtil";
import axios from "axios";
import { priceParseComma } from "../../../product/components/counselling/CounselReservReg";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { BiSolidRightArrow } from "react-icons/bi";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    text-align: left;
    padding: 10px 15px 10px 20px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 500;
    font-size: 16px;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 450px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ font: string }>`
    padding: 5px 10px;
    width: 100px;
    height: 30px;
    border: 0.5px solid #eeeeee;
    color: ${(props) => props.font};
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 500px;
    // background-color: #fff111;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    input {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }

    .priceinput {
        height: 20px;
        width: 100%;
        max-width: 250px;
        line-height: 5px;
        border: 2px solid #161a3f;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

const TareaInputBox = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 500px;
    height: 350px;
    align-items: start;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;

    .text {
        display: flex;
        width: 100%;
        max-width: 100px;
        height: 42px;
        align-items: center;

        h3 {
            margin-left: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #122437;
        }
    }

    .inputbox {
        height: 320px;
        width: 100%;
        max-width: 400px;
        border: 2px solid #000000;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        background-color: #fcfcfc;
    }

    textarea {
        background-color: #fcfcfc;
        height: 310px;
        resize: none;
        border: 0;
        font-size: 15px;
        outline: none;
        line-height: 15px;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
    }
`;

interface props {
    open: boolean;
    bemily_id: number;
    close: (v: boolean) => void;
}

export const BemilyMemoModal = (props: props): ReactElement => {
    const { open, bemily_id, close } = props; // props로 함수 받기
    const [isShow, setShow] = useState(false);
    const [token, setToken] = useState("");
    const [tArea, setTarea] = useState("");

    const aTokenInput = (e: ChangeEvent<HTMLInputElement>) => {
        setToken(e.target.value);
    };
    const textAreaInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setTarea(e.target.value);
    };

    const getBemilyAccess = async () => {
        setShow(true);
        const body = {
            authorizeCode: token,
        };

        try {
            const res = await axios.post(`/openapi/auth`, body);
            // console.log("be auth: ", res.data);
            if (res.data.result) {
                refundBemily(res.data.resultData.accessToken);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            alert(e.response.data.message);
        }
    };

    const refundBemily = async (accessToken: string) => {
        setShow(true);
        const body = {
            receiver: [bemily_id],
            message: tArea,
        };
        // const body = {
        //     receiver: [123086],
        //     message: tArea,
        // };

        try {
            const res = await axios.post(`/openapi/message`, body, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            // console.log("be refund: ", res.data);
            setShow(false);
            if (res.data.result) {
                alert("메모 전송에 성공하셨습니다.");
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            alert(e.response.data.message);
        }
    };

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    비밀리 메모 보내기
                    <ModalClosebutton onClick={() => close(false)}>
                        <CgCloseR
                            size={20}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <BoxContainer set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>인증코드</h3>
                        </div>
                        <input
                            className="priceinput"
                            type="text"
                            value={token}
                            placeholder="AuthorizeToken 값을 입력해주세요"
                            onChange={aTokenInput}
                        />
                    </BoxContainer>
                    <TareaInputBox set="stretch">
                        <div className="text">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <h3>메모 내용</h3>
                        </div>
                        <div className="inputbox">
                            <textarea
                                placeholder="메모 내용을 입력해 주세요"
                                value={tArea}
                                onChange={textAreaInput}
                            />
                        </div>
                    </TareaInputBox>
                </ModalContent>
                <Footer>
                    <BtnContainer
                        color="#eeeeee"
                        font="#000000"
                        onClick={() => close(false)}
                    >
                        닫기
                    </BtnContainer>
                    <BtnContainer
                        color="#2d40ff"
                        font="#ffffff"
                        onClick={getBemilyAccess}
                    >
                        보내기
                    </BtnContainer>
                </Footer>
            </ModalContainer>
        </Container>
    );
};
