import { atom, useAtom } from "jotai";
import { useState } from "react";
import styled from "styled-components";

const SelectBox = styled.div`
    position: relative;
    width: 100px;
    padding: 8px;
    border-radius: 5px;
    background-color: #ffffff;
    align-self: center;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #eeeeee;
    cursor: pointer;
    &::befor {
        content: "⌵";
        // position: absolute;
        top: 1px;
        right: 8px;
        color: #49c181;
        font-size: 20px;
    }
`;

const Label = styled.label`
    font-size: 14px;
    margin=left: 4px;
    text-align: center;
`;

const SelectOptions = styled.ul<{ show: boolean }>`
    position: absolute;
    list-style: none;
    top: 18px;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 90px;
    max-height: ${(props) => (props.show ? "none" : "0")};
    padding: 0;
    border-radius: 5px;
    background-color: #222222;
    color: #f7f7fe;
`;

const Option = styled.li`
    font-size: 14px;
    padding: 6px 8px;
    transition: background-color 0.2s ease-in;
    &:hover {
        background-color: #595959;
    }
`;

export const levelinputAtom = atom("B2B파트너사");
export const CustomSelectBox = () => {
    const [levelinput, setLevel] = useAtom(levelinputAtom);
    const [isShowOptions, setShowOptions] = useState(false);

    return (
        <SelectBox onClick={() => setShowOptions((prev) => !prev)}>
            <Label>{levelinput}</Label>
            <SelectOptions show={isShowOptions}>
                <Option
                    onClick={() => {
                        setLevel("B2B파트너사");
                    }}
                >
                    B2B파트너사
                </Option>
                <Option
                    onClick={() => {
                        setLevel("마케팅팀");
                    }}
                >
                    마케팅팀
                </Option>
                <Option
                    onClick={() => {
                        setLevel("지원팀");
                    }}
                >
                    지원팀
                </Option>
            </SelectOptions>
        </SelectBox>
    );
};
