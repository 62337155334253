import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { atotalpageAtom, nowpageAtom, PagingBox } from "../../common/PagingBox";
import { SheetContainer } from "../../common/SheetContainer";
import { SheetSortation } from "../../common/SheetSortation";
import { useEffect, useState } from "react";
import { MdOutlineAddTask } from "react-icons/md";
import { VisibleSelectBox } from "../../common/VisibleSelectBox";
import { priceparse } from "./common/TabMenu";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    // justify-content: start;
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    align-items: center;
    // justify-content: start;
`;

const BtnContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: end;
    margin: 30px 40px 20px 0px;
`;

const AddPackageBtn = styled.button`
    display: flex;
    width: 120px;
    height: 40px;
    cursor: pointer;
    background-color: #23c162;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;

    h3 {
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
        font-weight: 500;
    }
`;

const Content = styled.td<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    height: 45px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: black;
    }
`;

const DetailProduct = styled.div`
    display: flex;
    width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #708fe2;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

const DeleteProduct = styled.button`
    display: flex;
    width: 100px;
    height: 30px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    background-color: #f47878;
    cursor: pointer;
    border-radius: 5px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
    }
`;

export const EventList = () => {
    const navigate = useNavigate();
    const setaTotalPage = useSetAtom(atotalpageAtom);
    const nowpage = useAtomValue(nowpageAtom);
    const [list, setList] = useState([]);
    const partnerlv = Number(sessionStorage.getItem("partnerlv"));

    return <Container>이벤트 상품</Container>;
};
