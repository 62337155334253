import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { RxUpdate } from "react-icons/rx";
import { styled } from "styled-components";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { updateBox } from "../common/ProductDetail_Image";
import UpdateDetailImgBox from "../common/UpdateDetailImg";
import { getS3imgContent, removeBloburl, setS3imgContent } from "../common/updateimgInterface";

const Container = styled.div`
    position: fixed;
    // background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 200px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-top: 200px;
    width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8fe;
    border-radius: 5px;
    text-align: left;
    padding: 15px 15px 15px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 700;
    font-size: 16px;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 500px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .rowBox {
        display: flex;
        width: 90%;
        // background-color: #eeeeee;
        justify-content: space-between;

        .extxt {
            font-size: 13px;
            font-weight: 400;
            margin-right: 80px;
            color: #f47878;
        }
    }
    .marginb {
        margin-bottom: 20px;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
`;

const BtnContainer = styled.button<{ color: string; font: string }>`
    padding: 10px;
    width: 100px;
    height: 40px;
    border: 0.5px solid #eeeeee;
    margin-left: 30px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => props.font};
`;

const UpdateBtn = styled.div`
    display: flex;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #2d40ff;

    .btntext {
        color: #ffffff;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
    }
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    height: 42px;
    align-items: center;

    h3 {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #122437;
    }
`;

const BoxContainer = styled.div<{ set: string }>`
    display: flex;
    width: 100%;
    max-width: 420px;
    height: 42px;
    align-items: center;
    justify-content: ${(props) => props.set};
    margin: 10px 0 10px 0;
    // background-color: #fff111;

    .won {
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const InputBox = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${(props) => props.width};
    height: 42px;
    align-items: end;

    input {
        width: 100%;
        line-height: 18px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        outline: none;
    }
`;

interface day_update {
    seq: number;
    airtel_banner_seq: number;
    title: string;
    airline: string;
    airtel_date: string;
    search_day: string;
    last_payment_day: string;
    start_text: string;
    end_text: string;
    origin_price: number;
    discount_price: number;
    visible: number;
    order_by: number;
}

interface props {
    open: boolean;
    basic_data: any;
    close: () => void;
    backlogin: () => void;
}

export const AirtelDayListDetail = (props: props) => {
    const { open, basic_data, close, backlogin } = props;
    const [isShow, setShow] = useState(false);
    const [airtel, setAirtel] = useState<day_update>(basic_data);
    const [file, setFile] = useState<FileList | null>();
    const [detail, setDetail] = useState<Array<updateBox>>([]);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [imgArr, setImgArr] = useState<Array<any>>([]);
    const [s3flag, setS3flag] = useState(false);
    const partner_lv = Number(sessionStorage.getItem("partnerlv"));

    const updateBtn = () => {
        if (updateFlag) {
            // btn : 저장하기
            // setDayDetail();
            setImgS3();
        } else {
            // btn : 정보수정
            // flag 변환 - false >> true
            setUpdateFlag(!updateFlag);
        }
    };

    const outHandle = (v: boolean) => {
        let arr: any[] = [];
        detail.forEach((e) => {
            arr.push(e.image_url);
        });
        removeBloburl(arr);
        if (v) {
            backlogin();
        } else {
            close();
        }
    };

    const onImageChange = (seq: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            console.log("imgfile? ", event.target.files);
            if (event.target.files!.length > 1) {
                alert("이미지는 한장만 선택 할 수 있습니다.");
                return;
            }

            if (detail[seq].image_url !== "") {
                setFile(null);
            }

            // console.log(event.target.files);
            // setFile(event.target.files);
            const arr = detail.splice(seq, 1);
            if (arr[0].image_url.includes("blob")) {
                URL.revokeObjectURL(arr[0].image_url);
            }
            const boxitem = {
                seq: arr[0].seq,
                image_url: URL.createObjectURL(event.target.files![0]),
                file: event.target.files![0],
                ratio: arr[0].ratio,
                order_by: arr[0].order_by,
            };
            detail.splice(seq, 0, boxitem);
            setDetail([...detail]);
        }
    };
    const ratioInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: e.target.value,
            order_by: arr[0].order_by,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };
    const orderbyInput = async (seq: number, e: ChangeEvent<HTMLInputElement>) => {
        const arr = detail.splice(seq, 1);
        const boxitem = {
            seq: arr[0].seq,
            image_url: arr[0].image_url,
            file: arr[0].file,
            ratio: arr[0].ratio,
            order_by: e.target.valueAsNumber,
        };
        detail.splice(seq, 0, boxitem);
        setDetail([...detail]);
    };

    const getDayDetail = async () => {
        try {
            const res = await axios.get(
                `/product/detail/imglist?client_id=99&product_sort=AIRTEL&product_id=${airtel.seq}`
            );
            // console.log("package detail: ", res.data);
            if (res.data.code === "WFL000") {
                // 리스트 저장
                let list = res.data.data;
                list.sort((a: any, b: any) => a.order_by - b.order_by);
                setDetail(list);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            alert(e.response.data.message);
        }
    };

    useEffect(() => {
        if (s3flag) {
            setDayDetail();
        }
    }, [s3flag]);

    const setImgS3 = async () => {
        setShow(true);
        setS3flag(false);

        getS3imgContent(detail.length)?.then(({ data }) => {
            let ia: Array<any> = [];
            const list = data.data.list;
            for (let i = 0; i < detail.length; i++) {
                if (detail[i].image_url.includes("blob")) {
                    setS3imgContent(list[i].upload_url, list[i].result_url, detail[i].file);
                    URL.revokeObjectURL(detail[i].image_url);
                    const boxitem = {
                        seq: detail[i].seq,
                        image_url: list[i].result_url,
                        ratio: detail[i].ratio,
                        order_by: detail[i].order_by,
                    };
                    ia.push(boxitem);
                }
            }
            setImgArr(ia);
            setShow(false);
            setS3flag(true);
        });
    };

    const setDayDetail = async () => {
        setShow(true);
        setS3flag(false);

        const accesstoken = sessionStorage.getItem("accesstoken");

        const body = {
            product_sort: "airtel",
            product_id: airtel.seq,
            image_list: imgArr,
        };

        // console.log("update body: ", body);

        try {
            const res = await axios.put(`/v1/products/images`, body, {
                headers: { Authorization: `Bearer ${accesstoken}` },
            });
            // console.log("detail update: ", res.data);
            // 로딩 종료
            setShow(false);
            if (res.data.code === 0) {
                alert("에어텔 일별 상품 상세이미지가 수정되었습니다.");
                // flag 변환 - true >> false
                setUpdateFlag(!updateFlag);
                // 저장용 리스트 초기화
                setImgArr([]);
            }
        } catch (e: any) {
            // 로딩 종료
            setShow(false);
            if (e.response.status === 401) {
                // accesstoken 만료
                alert("다시 로그인 해주세요");
                outHandle(true);
            } else {
                alert(e.response.data.message);
            }
        }
    };

    useEffect(() => {
        if (updateFlag === false) {
            getDayDetail();
        }
    }, [updateFlag]);

    return (
        <Container>
            {isShow && <LoadingSpinner />}
            <ModalContainer>
                <ModalHeader>
                    에어텔 일별 상품 상세이미지
                    {partner_lv > 1 && (
                        <UpdateBtn onClick={updateBtn}>
                            <RxUpdate
                                size={25}
                                color={"#ffffff"}
                            />
                            <h3 className="btntext">{updateFlag ? "저장하기" : "정보수정"}</h3>
                        </UpdateBtn>
                    )}
                </ModalHeader>
                <ModalContent>
                    <TextContainer>
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={13}
                        />
                        <h3>상품 상세이미지</h3>
                    </TextContainer>
                    {detail.map((item: any, i: number) => {
                        return (
                            <UpdateDetailImgBox
                                key={item.seq + i}
                                fileURL={item.image_url}
                                ratio={item.ratio}
                                order={item.order_by}
                                seq={i}
                                flag={updateFlag}
                                uploadImg={onImageChange}
                                ratiohandle={ratioInput}
                                orderhandle={orderbyInput}
                            />
                        );
                    })}
                </ModalContent>
                <Footer>
                    <BtnContainer
                        font="#000000"
                        color="#eeeeee"
                        onClick={() => outHandle(false)}
                    >
                        닫기
                    </BtnContainer>
                    {updateFlag && (
                        <BtnContainer
                            font="#ffffff"
                            color="#2d40ff"
                            onClick={updateBtn}
                        >
                            등록
                        </BtnContainer>
                    )}
                </Footer>
            </ModalContainer>
        </Container>
    );
};
