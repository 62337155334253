import styled from "styled-components";
import { ChangeEvent, ReactElement, useState } from "react";
import { CgCloseR } from "react-icons/cg";
import axios from "axios";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { BiSolidRightArrow } from "react-icons/bi";
import { VisibleSelectBox } from "../../../common/VisibleSelectBox";
import { HdDetailImage } from "./HotdealDetailImg";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { totalPrice } from "../../view/HotdealRegisterView";

const Container = styled.div`
    position: fixed;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 200px;
    margin-bottom: 80px;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
`;

const ModalHeader = styled.div`
    position: relative;
    background-color: #f7f8fe;
    text-align: left;
    padding: 10px 15px 10px 30px;
    border-bottom: 2px solid #eeeeee;
    font-weight: 500;
    font-size: 16px;

    span {
        position: absolute;
        border: none;
        top: 10px;
        right: 15px;
        width: 30px;
        text-align: center;
    }

    .upbtn {
        position: absolute
        width: 100%;
        max-width: 100px;
        background-color: #2d40ff;
        border: none;
        border-radius: 5px;
        color: #ffffff;
        padding: 5px 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        margin-left: 100px;
    }
`;

const ModalClosebutton = styled.span`
    cursor: pointer;
`;

const PolicyContainer = styled.div`
    margin-top: 50px;

    .rowBox {
        display: flex;
        height: 20px;
        align-items: center;
        justify-content: start;
        // background-color: #eeeeee;

        .name {
            width: 100%;
            max-width: 90px;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
        }
    }

    .prow {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: start;
        margin-left: 30px;
        // background-color: #eeeeee;

        .pn {
            // width: 100%;
            // max-width: 90px;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`;

const ModalContent = styled.div`
    display: block;
    overflow: auto;
    height: 600px;
    padding: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #eeeeee;

    .rowBox {
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;

        .name {
            width: 100%;
            max-width: 90px;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 500;
        }

        .content {
            height: 35px;
            outline: none;
            padding: 0px 10px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid #eeeeee;
            font-size: 15px;
        }

        .tacontent {
            height: 100px;
            outline: none;
            padding: 0px 10px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid #eeeeee;
            font-size: 15px;
        }

        .size350 {
            width: 100%;
            max-width: 350px;
        }

        .size90 {
            width: 100%;
            max-width: 90px;
            margin-right: 20px;
        }

        .margin50 {
            margin-right: 75px;
        }
    }

    .tarowBox {
        display: flex;
        height: 250px;
        align-items: start;
        justify-content: start;
        margin-bottom: 10px;

        .taname {
            display: flex;
            width: 100%;
            max-width: 110px;
            align-items: center;
            margin-right: 10px;
            padding: 5px 0px;
            font-size: 15px;
            font-weight: 500;
            // background-color: #eeeeee;

            .icon {
                margin-right: 10px;
            }
        }

        .tacontent {
            height: 220px;
            outline: none;
            padding: 5px 10px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid #eeeeee;
            font-size: 15px;
        }

        .size350 {
            width: 100%;
            max-width: 350px;
        }
    }
`;

interface props {
    open: boolean;
    item: any;
    close: () => void;
    move: (item: any) => void;
}

export const HotdealModal = (props: props): ReactElement => {
    const { open, item, close, move } = props; // props로 함수 받기

    const imglist = JSON.parse(item.product_image_list).sort((a: any, b: any) => a.n - b.n);
    const policylist = JSON.parse(item.product_policy_list);
    // console.log("hotdeal ", policylist);

    return (
        <Container>
            <ModalContainer>
                <ModalHeader>
                    핫딜상품 상세정보
                    <button
                        className="upbtn"
                        onClick={() => move(item)}
                    >
                        수정하기
                    </button>
                    <ModalClosebutton onClick={close}>
                        <CgCloseR
                            size={20}
                            color="#000000"
                        />
                    </ModalClosebutton>
                </ModalHeader>
                <ModalContent>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">상품명 :</p>
                        <input
                            className="content size350"
                            value={item.product_name}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">생성일 :</p>
                        <input
                            className="content size350"
                            value={item.create_date}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">판매기간 :</p>
                        <input
                            className="content size350"
                            value={item.product_sales_day + " 까지"}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">행사일 :</p>
                        <input
                            className="content size350"
                            value={item.product_d_day}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">구매가능수량 :</p>
                        <input
                            className="content size90"
                            value={item.max_person}
                            readOnly
                        />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">표현순서 :</p>
                        <input
                            className="content size90"
                            value={item.product_order}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">상품노출 :</p>
                        <VisibleSelectBox
                            visible={item.is_exclude === "N" ? 1 : 0}
                            sort={"hotdeal_exclude"}
                            id={item.event_product_seq}
                            setState={close}
                            disable={true}
                        />
                        <div className="margin50" />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">결제버튼 :</p>
                        <VisibleSelectBox
                            visible={item.product_payment_yn === "Y" ? 1 : 0}
                            sort={"hotdeal_payment"}
                            id={item.event_product_seq}
                            setState={close}
                            disable={true}
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">결제 횟수 :</p>
                        <input
                            className="content size90"
                            value={item.payment_cnt}
                            readOnly
                        />
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">vat포함여부 :</p>
                        <input
                            className="content size90"
                            value={item.is_vat === "2" ? "해당없음" : "포함"}
                            readOnly
                        />
                    </div>
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">총 가격 :</p>
                        <input
                            className="content size350"
                            value={item.product_price.toString()}
                            readOnly
                        />
                    </div>
                    {item.payment_cnt > 1 && (
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">예약금 :</p>
                            <input
                                className="content size350"
                                value={item.cnt1_price}
                                readOnly
                            />
                        </div>
                    )}
                    {item.payment_cnt > 2 && (
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">중도금 :</p>
                            <input
                                className="content size350"
                                value={item.cnt2_price}
                                readOnly
                            />
                        </div>
                    )}
                    <div className="rowBox">
                        <BiSolidRightArrow
                            color="#6f7cff"
                            size={10}
                        />
                        <p className="name">{item.payment_cnt === 1 ? "결제금" : "잔금 :"}</p>
                        <input
                            className="content size350"
                            value={item.balance_amount}
                            readOnly
                        />
                    </div>
                    {item.payment_cnt > 1 && (
                        <div className="tarowBox">
                            <p className="taname">
                                <BiSolidRightArrow
                                    className="icon"
                                    color="#6f7cff"
                                    size={10}
                                />
                                예약금MSG :
                            </p>
                            <textarea
                                className="tacontent size350"
                                value={item.product_etc}
                                readOnly
                            />
                        </div>
                    )}
                    {item.payment_cnt > 2 && (
                        <div className="tarowBox">
                            <p className="taname">
                                <BiSolidRightArrow
                                    className="icon"
                                    color="#6f7cff"
                                    size={10}
                                />
                                중도금MSG :
                            </p>
                            <textarea
                                className="tacontent size350"
                                value={item.middle_etc}
                                readOnly
                            />
                        </div>
                    )}
                    <div className="tarowBox">
                        <p className="taname">
                            <BiSolidRightArrow
                                className="icon"
                                color="#6f7cff"
                                size={10}
                            />
                            완료MSG :
                        </p>
                        <textarea
                            className="tacontent size350"
                            value={item.last_etc}
                            readOnly
                        />
                    </div>
                    <div className="tarowBox">
                        <p className="taname">
                            <BiSolidRightArrow
                                className="icon"
                                color="#6f7cff"
                                size={10}
                            />
                            배너 이미지 :
                        </p>
                        <img
                            className="size350"
                            src={item.product_banner_image}
                        />
                    </div>
                    <PolicyContainer>
                        <div className="rowBox">
                            <BiSolidRightArrow
                                color="#6f7cff"
                                size={10}
                            />
                            <p className="name">정책 리스트</p>
                        </div>
                        <div>
                            {policylist.map((policy: any, i: number) => {
                                return (
                                    <div className="prow">
                                        <BsFillCheckCircleFill
                                            color="#6f7cff"
                                            size={10}
                                        />
                                        <p className="pn">{policy.txt + " : "}</p>
                                        <p>{policy.link}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </PolicyContainer>
                    <HdDetailImage list={imglist} />
                </ModalContent>
            </ModalContainer>
        </Container>
    );
};
